import { AddButtonContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import clientServiceTableConstants from "constants/tableConstants/clientServiceTableConstants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import PropTypes from "prop-types";
import { ClientServiceTabContentStyled } from "./ClientServiceTabContent.styled";
import Table from "components/Table/Table";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { setClientServiceWithClient } from "features/clientService/clientServiceSlice";
import { replaceInRoute } from "util/routeHelpers";
import {
  setClientServiceDeleteModal,
  unsetShownModal,
} from "features/modal/modalSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { selectClient } from "features/clients/clientSlice";
import {
  useDeleteClientServiceMutation,
  useGetClientServicesQuery,
} from "features/clients/clientsApiSlice";

export const ClientServiceTabContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deleteClientServiceApi] = useDeleteClientServiceMutation();
  const client = useSelector(selectClient);
  useEffect(() => {
    dispatch(
      setClientServiceWithClient({ client: client, clientService: null })
    );
  }, []);

  const handleAddEdit = (itemEdit) => {
    const pathParams = {
      clientId: client?.id,
      clientServiceId: itemEdit ? itemEdit?.id : "new",
    };

    return replaceInRoute(PAGES.CLIENT_SERVICE.route, pathParams);
  };

  const mapRowDataFunction = (rowData) => {
    return rowData.data?.clientServices?.map?.((clientService) => ({
      ...clientService,
    }));
  };

  const deleteClientService = async (id) => {
    dispatch(unsetShownModal());
    const response = await deleteClientServiceApi({
      clientId: client?.id,
      clientServiceId: id,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("clientService.successfulyDeleted"),
        toastNotificationType.SUCCESS
      );
    }
  };

  const openModal = (selectedItem) => {
    dispatch(
      setClientServiceDeleteModal({
        onDelete: () => deleteClientService(selectedItem),
        selectedItem: selectedItem,
      })
    );
  };
  return (
    <ClientServiceTabContentStyled>
      <AddButtonContainer>
        <AddNewItemButton
          goTo={handleAddEdit()}
          value={t("clientService.addButonForServices")}
        />
      </AddButtonContainer>
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientServiceTableConstants}
        usePaginatedQuery={useGetClientServicesQuery}
        t={t}
        rowBtnClick={handleAddEdit}
        isClient={true}
        clientId={client?.id}
        deleteBtnClick={openModal}
        numberOfCharactersPerCell={30}
      />
    </ClientServiceTabContentStyled>
  );
};

ClientServiceTabContent.propTypes = {
  clientId: PropTypes.any,
  client: PropTypes.any,
};
