import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetClientImageQuery } from "features/clients/clientsApiSlice";
import { Image } from "./ImageGallery.styled";
import { useDispatch } from "react-redux";
import { setFullImageModal, unsetShownModal } from "features/modal/modalSlice";
import WithLoadingSpinner from "components/WithLoadingSpinner/WithLoadingSpinner";

const ImageComponent = ({ imageId }) => {
  const dispatch = useDispatch();
  const { data: fetchedImage } = useGetClientImageQuery(imageId, {
    skip: !imageId,
  });
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let objectUrl = null;

    if (fetchedImage instanceof Blob || fetchedImage instanceof File) {
      objectUrl = URL.createObjectURL(fetchedImage);
      setImageUrl(objectUrl);
    } else if (typeof fetchedImage === "string") {
      setImageUrl(fetchedImage);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [fetchedImage]);

  useEffect(() => {
    window.addEventListener("beforeunload", dispatch(unsetShownModal()));

    return () => {
      window.removeEventListener("beforeunload", dispatch(unsetShownModal()));
    };
  }, []);

  return (
    <WithLoadingSpinner isLoading={imageUrl == null}>
      <Image
        src={imageUrl}
        onClick={() => dispatch(setFullImageModal(imageUrl))}
      />
    </WithLoadingSpinner>
  );
};

ImageComponent.propTypes = {
  imageId: PropTypes.string.isRequired,
};

export default ImageComponent;
