import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as LogInImage } from "assets/images/svg/mobile-login.svg";
import { ReactComponent as AvatarImage } from "assets/images/svg/avatar-default.svg";

export const HeaderProfilePopoverContentContainer = styled(Box)`
  width: 151px;
  height: ${(props) => props?.isLanguageShown ? "248px" : "184px"};
  cursor: default;
  background-color: ${(props) => props?.theme?.colors?.white};
  border-radius: 16px;
  padding: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: auto;
      height: fit-content;
      padding: 14px 10px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: auto;
      height: fit-content;
      padding: 14px 10px;
    }
  }
`;
export const HeaderProfilePopoverContentHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 60px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.secondaryDark};
`;
export const ProfileInitials = styled(NavLink)`
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 44px;
  text-decoration: none;
  background-color: ${(props) => props?.theme?.colors?.primaryLighter};
`;
export const ProfileDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 8px 0;
`;
export const ProfileName = styled(NavLink)`
  font-family: Inter;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const ProfileMail = styled(Typography)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props?.theme?.colors?.textColor};
`;
export const HeaderProfileMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 12px;
    }
  }
`;
export const HeaderProfileMenuItem = styled(NavLink)`
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props?.theme?.colors?.textColor};
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 12px;
    }
  }
`;

export const DividerLine = styled.div`
  border-top: 2px solid ${(props) => props?.theme?.colors?.textUncompletedStep};
  width: 100%;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 80%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 80%;
    }
  }
`;

const StyledImage = styled.img`
  width: 24px;
  height: 24px;
  fill: ${(props) => props?.theme?.colors?.black};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 16px;
      height: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 16px;
      height: 16px;
    }
  }
`;

export const LogInImageStyled = styled(StyledImage).attrs({ as: LogInImage })``;
export const AvatarImageStyled = styled(StyledImage).attrs({
  as: AvatarImage,
})``;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.placeholderText};
    border-radius: 5px;
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 10px;
`;
