import styled, { css } from "styled-components";
import { IndividualTab } from "../Sidebar.styled";
import Typography from "@mui/material/Typography";
import { ReactComponent as ClientsLogo } from "../../../assets/images/svg/klijenti.svg";
import { ReactComponent as UsersLogo } from "../../../assets/images/svg/korisnici.svg";
import { ReactComponent as ServicesLogo } from "../../../assets/images/svg/delatnosti.svg";
import { ReactComponent as ControlTableLogo } from "../../../assets/images/svg/kontrolna-tabla.svg";
import { ReactComponent as HelpLogo } from "../../../assets/images/svg/help-icon.svg";
import { ReactComponent as ContactLogo } from "../../../assets/images/svg/contact-us-icon.svg";
import { ReactComponent as AboutUsLogo } from "../../../assets/images/svg/about-us-icon.svg";
import { ReactComponent as MessageLogo } from "../../../assets/images/svg/messages-icon.svg";
import { ReactComponent as AppointmentsLogo } from "../../../assets/images/svg/appointments-icon.svg";

import { ReactComponent as UsersImageSelected } from "../../../assets/images/svg/korisnici-selektovano.svg";
import { ReactComponent as ClientsImageSelected } from "../../../assets/images/svg/klijenti-selektovano.svg";
import { ReactComponent as ServicesImageSelected } from "../../../assets/images/svg/delatnosti-selektovane.svg";
import { ReactComponent as ControlTableImageSelected } from "../../../assets/images/svg/kontrolna-taba-selektovane.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as HelpLogoSelected } from "../../../assets/images/svg/help-icon-selected.svg";
import { ReactComponent as ContactLogoSelected } from "../../../assets/images/svg/contact-us-icon-selected.svg";
import { ReactComponent as AboutUsLogoSelected } from "../../../assets/images/svg/about-us-icon-selected.svg";
import { ReactComponent as MessageLogoSelected } from "../../../assets/images/svg/messages-icon-selected.svg";
import { ReactComponent as AppointmentsLogoSelected } from "../../../assets/images/svg/appointments-icon-selected.svg";

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  align-self: center;
  ${(props) =>
    props?.$displayHidden &&
    css`
      max-width: "0";
    `};
  transition: max-width 2s 0.05s ease-in-out;
  color: ${({ selected, theme }) =>
    selected ? theme?.colors?.black : theme?.colors?.white};
`;

export const NavLinkContainer = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: ${({ $collapsed }) => ($collapsed ? "flex-end" : "center")};
`;

export const TabWrapper = styled(IndividualTab)`
  background-color: ${({ $selected, theme }) =>
    $selected
      ? `${theme.colors.iconColor}`
      : `${theme.colors.userButtonColor}`};
  min-height: 50px;
  width: ${({ $collapsed }) => ($collapsed ? "75%" : "100%")};
  gap: 15px;
  padding: 16px 8px;
  justify-content: flex-start;
  border-radius: 8px 0px 0px 8px;

  white-space: nowrap;
  & ${StyledTypography} {
    color: ${({ selected, theme }) =>
      selected ? theme?.colors?.black : theme?.colors?.white};
    overflow: hidden;
  }
  ${(props) =>
    props?.$collapsed &&
    css`
      gap: 0px;
      display: flex;
    `}
  & svg {
    min-width: 24px;
  }

  ${(props) =>
    props?.$collapsed
      ? css`
          & ${StyledTypography} {
            width: 0;
          }
        `
      : css`
          & ${StyledTypography} {
            width: 200px;
          }
        `};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
      height: 56px;
      min-width: 224px;
      padding: 16px 8px;

      ${(props) =>
        props?.collapsed
          ? css`
              & ${StyledTypography} {
                width: 0;
              }
            `
          : css`
              & ${StyledTypography} {
                width: auto;
              }
            `};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 12px;
      height: 56px;
      min-width: 242px;
      padding: 16px 8px;

      ${(props) =>
        props?.collapsed
          ? css`
              & ${StyledTypography} {
                width: 0;
              }
            `
          : css`
              & ${StyledTypography} {
                width: auto;
              }
            `};
    }
  }
`;

const createIcon = (LogoComponent) => styled(LogoComponent)`
  width: 30px;
  height: 30px;
  position: relative;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 24px;
      height: 24px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 24px;
      height: 24px;
    }
  }
`;

export const UsersIcon = createIcon(UsersLogo);
export const ServicesIcon = createIcon(ServicesLogo);
export const ClientsIcon = createIcon(ClientsLogo);
export const ControlTableIcon = createIcon(ControlTableLogo);
export const HelpIcon = createIcon(HelpLogo);
export const ContactIcon = createIcon(ContactLogo);
export const AboutUsIcon = createIcon(AboutUsLogo);
export const MessageIcon = createIcon(MessageLogo);
export const AppointmentsIcon = createIcon(AppointmentsLogo);

export const UsersIconSelected = createIcon(UsersImageSelected);
export const ServicesIconSelected = createIcon(ServicesImageSelected);
export const ControlTableIconSelected = createIcon(ControlTableImageSelected);
export const ClientsIconSelected = createIcon(ClientsImageSelected);
export const HelpIconSelected = createIcon(HelpLogoSelected);
export const ContactIconSelected = createIcon(ContactLogoSelected);
export const AboutUsIconSelected = createIcon(AboutUsLogoSelected);
export const MessageIconSelected = createIcon(MessageLogoSelected);
export const AppointmentsIconSelected = createIcon(AppointmentsLogoSelected);
