import styled from "styled-components";
import { ReactComponent as ClientImg } from "assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "assets/images/svg/user-validation-message.svg";
import { hexToRGB } from "util/colorHelper";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { ReactComponent as xIcon } from "assets/images/svg/x-icon.svg";

export const Modal = styled.div`
  width: 460px;
  height: fit-content;
  box-sizing: border-box;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      max-width: 345px;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: fit-content;
  padding: 25px 30px 50px 30px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 16px 12px 18px 12px;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: white;
  justify-content: center;
  align-items: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: fit-content;
  padding: 25px 30px 25px 30px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 24px;
      height: 73px;
    }
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  margin-bottom: 15px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const ActionButton = styled(Button)`
  height: 47px;
  min-width: 118px;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
  font-size: 20px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 33px;
      min-width: 102px;
    }
  }
`;

export const CancelActionButton = styled(Button)`
  height: 47px;
  min-width: 147px;
  background-color: ${(props) => props.theme?.colors?.white};
  border-color: ${(props) => props.theme?.colors?.black};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
  }
  color: ${(props) => props.theme?.colors?.black};
  font-size: 20px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 33px;
      min-width: 121px;
    }
  }
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px;
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })``;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CloseIcon = styled(xIcon)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`;

export const ImageErrorContainer = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  position: absolute;
  color: ${(props) => props?.theme?.colors?.errorRed};
  top: 105%;
  left: 5%;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      top: 100%;
    }
  }
`;
