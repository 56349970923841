import styled from "styled-components";

export const CarouselImage = styled.img`
  cursor: pointer;
  width: auto;
  max-width: 100%;
  max-height: 260px;
  object-fit: contain;
  user-select: none;
  border-radius: 12px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      max-height: 134px;
    }
  }
`;

export const CarouselImageContainer = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 260px;
`;
