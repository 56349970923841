import { setAddAppointmentModal } from "features/modal/modalSlice";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MyCalendar from "./CalendarComponent/CalendarComponent";
import { useGetPaginatedAppointmentsForClientQuery } from "features/appointmentSlice/appointmentApiSlice";
import { useIsLoading } from "hooks/useIsLoading";
import {
  AddAppointmentButton,
  ButtonsContainer,
} from "./ClientCalendarContent.styled";
import FilterContainer from "components/Filter/FilterContainer";
import {
  getValueToRemoveStatuses,
  getValueToRemoveTypes,
  removeFilter,
} from "util/filterHelper";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { randomIdGenerator } from "util/randomGenerator";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import { appointmentStatuses } from "constants/filters/statusConstants";
import CustomMultipleSelectField from "components/InputFields/CustomFields/CustomMultipleSelectField";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import { LabelContainer } from "components/Modals/ClientServiceModals/Modal.styled";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import {
  APPOINTMENT_STATUS,
  APPOINTMENT_TYPE,
} from "constants/appointmentConstants";
import { appointmentTypes } from "constants/filters/appointmentTypeConstants";

const ClientCalendarContent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickAddAppointment = () => {
    dispatch(setAddAppointmentModal(props?.client));
  };

  const [filters, setFilters] = useState({
    statusActive: {
      id: appointmentStatuses[APPOINTMENT_STATUS.RESERVED]?.id,
      name: t(appointmentStatuses[APPOINTMENT_STATUS.RESERVED]?.name),
      valueToRemove: getValueToRemoveStatuses(APPOINTMENT_STATUS.RESERVED),
    },
    statusDone: {
      id: appointmentStatuses[APPOINTMENT_STATUS.DONE]?.id,
      name: t(appointmentStatuses[APPOINTMENT_STATUS.DONE]?.name),
      valueToRemove: getValueToRemoveStatuses(APPOINTMENT_STATUS.DONE),
    },
    statusCanceled: {},
    statusResourcesAllocation: {},
    statusRequest: {},
    serviceName: {},
    userFirstName: {},
    userLastName: {},
    typeRegular: {
      id: appointmentTypes[APPOINTMENT_TYPE.REGULAR]?.id,
      name: t(appointmentTypes[APPOINTMENT_TYPE.REGULAR]?.name),
      valueToRemove: getValueToRemoveTypes(APPOINTMENT_TYPE.REGULAR),
    },
    typeResourceAllocation: {},
  });

  const [appliedFilters, setAppliedFilters] = useState(filters);

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  const { data: appointments, isLoading } =
    useGetPaginatedAppointmentsForClientQuery({
      id: props?.client?.id,
      pageSize: 5000,
      filters: {
        statuses: [
          appliedFilters.statusPending?.id,
          appliedFilters.statusDeclined?.id,
          appliedFilters.statusActive?.id,
          appliedFilters.statusCanceled?.id,
          appliedFilters.statusDone?.id,
        ].filter((status) => status !== null && status !== undefined),
        types: [
          appliedFilters.typeRegular?.id != null
            ? Number(appliedFilters.typeRegular?.id) - 10
            : null,
          appliedFilters.typeResourceAllocation?.id != null
            ? Number(appliedFilters.typeResourceAllocation?.id) - 10
            : null,
        ].filter((type) => type !== null && type !== undefined),
        serviceName: appliedFilters?.serviceName,
        userFirstName: appliedFilters?.userFirstName,
        userLastName: appliedFilters?.userLastName,
      },
    });

  useIsLoading(isLoading);

  const handleStatusChange = (selectedIds) => {
    const updatedStatuses = selectedIds
      .map((id) => {
        const status = appointmentStatuses.find((status) => status.id === id);
        return status
          ? {
              id: status.id,
              name: t(status.name),
              valueToRemove: getValueToRemoveStatuses(status.id),
            }
          : null;
      })
      .filter(Boolean);

    setFilters((prevState) => {
      const updatedFilters = { ...prevState };
      delete updatedFilters.statusPending;
      delete updatedFilters.statusDeclined;
      delete updatedFilters.statusActive;
      delete updatedFilters.statusCanceled;
      delete updatedFilters.statusDone;

      updatedStatuses.forEach((status) => {
        if (status.id === APPOINTMENT_STATUS.PENDING) {
          updatedFilters.statusPending = status;
        } else if (status.id === APPOINTMENT_STATUS.DECLINED) {
          updatedFilters.statusDeclined = status;
        } else if (status.id === APPOINTMENT_STATUS.RESERVED) {
          updatedFilters.statusActive = status;
        } else if (status.id === APPOINTMENT_STATUS.CANCELLED) {
          updatedFilters.statusCanceled = status;
        } else if (status.id === APPOINTMENT_STATUS.DONE) {
          updatedFilters.statusDone = status;
        }
      });
      return updatedFilters;
    });

    saveFilters();
  };

  const handleTypeChange = (selectedIds) => {
    const updatedStatuses = selectedIds
      .map((id) => {
        const type = appointmentTypes.find((status) => status.id === id);
        return type
          ? {
              id: type.id,
              name: t(type.name),
              valueToRemove: getValueToRemoveTypes(type.id),
            }
          : null;
      })
      .filter(Boolean);

    setFilters((prevState) => {
      const updatedFilters = { ...prevState };
      delete updatedFilters.typeRegular;
      delete updatedFilters.typeResourceAllocation;

      updatedStatuses.forEach((type) => {
        if (type.id === APPOINTMENT_TYPE.REGULAR) {
          updatedFilters.typeRegular = type;
        } else if (type.id === APPOINTMENT_TYPE.RESOURCE_ALLOCATION) {
          updatedFilters.typeResourceAllocation = type;
        }
      });
      return updatedFilters;
    });

    saveFilters();
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"common.labelClientServiceName"}
          placeholder={"appointments.filters.inputClientServiceName"}
          onChange={(serviceName) =>
            setFilters((prevState) => ({
              ...prevState,
              serviceName: {
                id: serviceName,
                name: serviceName,
                valueToRemove: filterValueToRemove.SERVICE_NAME,
              },
            }))
          }
          value={filters?.serviceName?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <LabelContainer>
          <CustomFieldLabel label={t("common.status")} />
          <CustomMultipleSelectField
            fieldNames="statusNames"
            fieldIds="statusIds"
            name="statusIds"
            items={appointmentStatuses
              .filter(
                (status) =>
                  status.id !== APPOINTMENT_STATUS.CANCEL_RESOURCE_ALLOCATION
              )
              .sort((a, b) => {
                if (a.id === APPOINTMENT_STATUS.PENDING) return -1;
                if (b.id === APPOINTMENT_STATUS.PENDING) return 1;
                return 0;
              })
              .map((status) => ({
                id: status.id,
                name: t(status.name),
                primaryId: status.id,
                isActive: true,
              }))}
            formik={{
              values: {
                statusNames: [
                  filters.statusActive?.name || "",
                  filters.statusDone?.name || "",
                  filters.statusCanceled?.name || "",
                  filters.statusResourcesAllocation?.name || "",
                  filters.statusRequest?.name || "",
                ].filter((name) => name !== ""),
                statusIds: [
                  filters.statusActive?.id || null,
                  filters.statusDone?.id || null,
                  filters.statusCanceled?.id || null,
                  filters.statusResourcesAllocation?.id || null,
                  filters.statusRequest?.id || null,
                ].filter((id) => id !== null),
              },
              setFieldValue: (field, value) => {
                if (field === "statusIds") {
                  handleStatusChange(value);
                }
              },
            }}
          />
        </LabelContainer>
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <LabelContainer>
          <CustomFieldLabel label={t("common.type")} />
          <CustomMultipleSelectField
            fieldNames="typeNames"
            fieldIds="typeIds"
            name="typeIds"
            items={appointmentTypes
              .filter((type) => type.id !== APPOINTMENT_TYPE.WISHLIST)
              .map((type) => ({
                id: type.id,
                name: t(type.name),
                primaryId: type.id,
                isActive: true,
              }))}
            formik={{
              values: {
                typeNames: [
                  filters?.typeRegular?.name || "",
                  filters?.typeResourceAllocation?.name || "",
                ].filter((name) => name !== ""),
                typeIds: [
                  filters?.typeRegular?.id || null,
                  filters?.typeResourceAllocation?.id || null,
                ].filter((id) => id !== null),
              },
              setFieldValue: (field, value) => {
                if (field === "typeIds") {
                  handleTypeChange(value);
                }
              },
            }}
          />
        </LabelContainer>
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"common.labelClientFirstName"}
          placeholder={"common.firstNameInput"}
          onChange={(userFirstName) =>
            setFilters((prevState) => ({
              ...prevState,
              userFirstName: {
                id: userFirstName,
                name: userFirstName,
                valueToRemove: filterValueToRemove.USER_FIRST_NAME,
              },
            }))
          }
          value={filters?.userFirstName?.id}
        />
        <TextFilter
          label={"common.labelClientLastName"}
          placeholder={"common.lastNameInput"}
          onChange={(userLastName) =>
            setFilters((prevState) => ({
              ...prevState,
              userLastName: {
                id: userLastName,
                name: userLastName,
                valueToRemove: filterValueToRemove.USER_LAST_NAME,
              },
            }))
          }
          value={filters?.userLastName?.id}
        />
      </InputFieldContainer>,
    ],
    [filters, t]
  );

  useEffect(() => {
    const updatedFilters = { ...filters };
    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key]?.name) {
        updatedFilters[key].name = t(
          appointmentStatuses[updatedFilters[key].id]?.name
        );
      }
    });

    setFilters(updatedFilters);
    setAppliedFilters(updatedFilters);
  }, [t]);  

  return (
    <>
      <ButtonsContainer>
        <FilterContainer
          filterComponents={filterComponents}
          appliedFilters={appliedFilters}
          filters={filters}
          isFilterButtonShown={true}
          removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
          saveFilters={saveFilters}
          isCalendarComponent={true}
        />
        <AddAppointmentButton onClick={handleClickAddAppointment}>
          {t("common.resourceReservation")}
        </AddAppointmentButton>
      </ButtonsContainer>
      <MyCalendar
        appointments={appointments?.data?.appointments}
      />
    </>
  );
};

ClientCalendarContent.propTypes = {
  client: PropTypes.any,
};

export default ClientCalendarContent;
