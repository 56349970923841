import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover } from "@mui/material";
import { PopoverContainer, PopoverTrigger } from "./Popover.styled";
import { primaryBreakpoints } from "themes/primaryTheme/primaryBreakpoints";

const PopoverComponent = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const togglePopover = (event) => {
    setIsOpened((prevState) => !prevState);
    setAnchorEl(event?.currentTarget);
  };

  const closePopover = () => {
    setIsOpened(false);
  };
  return (
    <PopoverContainer>
      <PopoverTrigger
        onClick={togglePopover}
        style={props?.triggerContainerStyles}
      >
        {props?.trigger}
      </PopoverTrigger>
      <Popover
        sx={() => ({
          ...props?.contentContainerStyles,
          marginTop: "17px",
          "& .MuiPopover-paper": {
            borderRadius: "16px",
          },
          [primaryBreakpoints.down("sm")]: {
            marginTop: "0px",
            "& .MuiPopover-paper": {
              borderRadius: "8px",
            },
          },
          [primaryBreakpoints.rotate()]: {
            marginTop: "0px",
            "& .MuiPopover-paper": {
              borderRadius: "8px",
            },
          },
        })}
        open={isOpened}
        anchorEl={anchorEl}
        onClose={togglePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          horizontal: "right",
        }}
        onClick={props?.onClick || closePopover}
        {...props?.popoverProps}
      >
        {props?.content}
      </Popover>
    </PopoverContainer>
  );
};

PopoverComponent.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
  popoverProps: PropTypes.object,
  trigger: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  triggerContainerStyles: PropTypes.object,
  contentContainerStyles: PropTypes.object,
  onClick: PropTypes.func,
};

PopoverComponent.defaultProps = {
  onClose: () => {},
};

export default PopoverComponent;
