import { Avatar, Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../../assets/images/svg/arrow-down.svg";
import PopoverComponent from "components/Popover/Popover";
import { ReactComponent as OfflineImg } from "../../../../assets/images/svg/no-wifi.svg";
import React from 'react';

export const HeaderProfileContainer = styled(Box)`
  border: 1px solid #e5e6e9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 64px;
  width: ${(props) => (props?.isLoggedIn ? "215px" : "")};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 32px;
      width: 32px;
      padding-left: 3px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 32px;
      width: 32px;
      padding-left: 3px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex-grow: 1;
  padding-right: 25px;
`;

export const StyledPopover = styled(PopoverComponent)`
  & .MuiPopover-paper {
    border-radius: 8px;
    overflow: hidden;
    padding: 16px;
  }
`;

export const FullNameTypography = styled(Typography)`
  font-size: 14;
  font-weight: 700;
  white-space: nowrap;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const RoleTypography = styled(Typography)`
  font-size: 14;
  font-weight: 500;
  color: ${(props) => props?.theme?.colors?.text3};
`;

export const ArrwoDownStyled = styled(ArrowDown)`
  margin-right: 10px;
  margin-left: auto;
  cursor: pointer;
`;

export const AvatarStyled = styled(Avatar)`
  background-color: ${(props) => props?.theme?.colors?.serviceChipColor};
  color: ${(props) => props?.theme?.colors?.serviceChipTextColor};
  
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 24px;
      height: 24px;
      border-radius: 50;
      font-size: 11px;
      line-height: 21px;
      text-align: center;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 24px;
      height: 24px;
      border-radius: 50;
      font-size: 11px;
      line-height: 21px;
      text-align: center;
    }
  }
`;

const OfflineImageWithRef = React.forwardRef((props, ref) => {
  return <OfflineImg ref={ref} {...props} />;
});

OfflineImageWithRef.displayName = "OfflineImage";

export const OfflineImage = styled(OfflineImageWithRef)`
  width: 20px;
  height: 20px;
  margin-right: ${(props) => props?.isMobile ? "0px" : "10px"};
  cursor: pointer;
`;
