import styled from "styled-components";
import { Chip, FormHelperText, Select } from "@mui/material";
import { ReactComponent as DownArrow } from "../../../assets/images/svg/down-arrow.svg";
import { ReactComponent as ChipDeleteIcon } from "assets/images/svg/chip-delete-icon.svg";

export const CustomSelectFieldStyled = styled(Select)`
  ${({ isClient, value, theme }) =>
    isClient
      ? `color: ${theme?.colors?.black};`
      : value
      ? `color: ${theme?.colors?.black};`
      : `color: ${theme?.colors?.placeholderText};`}

  border-radius: 12px;
  ${(props) =>
    props?.disabled
      ? `background-color: ${props?.theme?.colors?.selectDisabledBackGroundColor}`
      : ""};

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }

  &.MuiOutlinedInput-root {
    height: fit-content;
    min-height: ${(props) => (props?.height ? props?.height : "40px")};

    ${(props) => props.theme.breakpoints.rotate()} {
      min-height: 33px;
      height: 33px;
    }

    & fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.text3};
      border-width: 1px;
      min-height: ${(props) => (props?.height ? props?.height : "")};
    }

    &:hover fieldset {
      border-color: ${({ isClient, theme, disabled }) =>
        disabled
          ? theme?.colors?.selectDisabledBorderColor
          : isClient
          ? theme?.colors?.clientRegistration.Circle
          : theme?.colors?.secondaryColor};
    }

    &.Mui-focused fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient
          ? theme?.colors?.secondaryColor
          : theme?.colors?.secondaryColor};
      border-width: 2px;
    }
  }
  & .MuiInputBase-input {
    padding: 8.5px 32px 5px 14px;
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 5px;
  margin-top: 3px;
`;

export const ChipDelete = styled(ChipDeleteIcon)``;

export const ChipStyled = styled(Chip)`
  &.MuiChip-colorDefault {
    background-color: ${({ theme }) => theme?.colors?.serviceChipColor};
    color: ${({ theme }) => theme?.colors?.serviceChipTextColor};
  }

  font-size: 14px;
`;

export const CustomFormHelperText = styled(FormHelperText)`
  color: ${(props) => props?.theme?.colors?.errorRed};
  margin-left: 14px;
  font-size: 0.75rem;
  font-weight: 400;
  margin-block-start: 0em;
  ${(props) => props.theme.breakpoints.down("md")} {
    top: 92%;
  }
`;

export const DownArrowStyled = styled(DownArrow)`
  margin-top: 3px;
  margin-right: 20px;
`;

export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      maxWidth: 100,
    },
  },
};

export default CustomSelectFieldStyled;
