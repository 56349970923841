import React, { useEffect, useState } from "react";
import { ClientEditTabStyled } from "./ClienEditTab.styled";
import { ClientContent } from "./ClientContentTab/ClientContent";
import { TopTabContainer } from "./Tab/TopTabContainer/TopTabContainer";
import { useGetClientByIdQuery } from "features/clients/clientsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { isDataSaved } from "features/clients/clientSlice";
import { useTranslation } from "react-i18next";

import { setClient } from "features/clients/clientSlice";
import clientsTabConstants from "constants/ClientTabConstants/clientsTabConstants";
import MainLayout from "layouts/MainLayout";
import { unstable_useBlocker, useParams } from "react-router-dom";
import usePrompt from "hooks/usePromptHook";
import { setUnsavedAlertModal } from "features/modal/modalSlice";
import PageHeader from "components/PageHeader/PageHeader";
import { ClientsIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { useIsLoading } from "hooks/useIsLoading";

export const ClientEditTab = () => {
  const { clientId } = useParams();

  const { data: clientDetails, isLoading: isDetailsLoading } =
    useGetClientByIdQuery(clientId, {});
  const defaultTab = clientsTabConstants.find((tab) => tab.default)?.key;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const handleTabClick = (id) => {
    setActiveTab(id);
  };
  useIsLoading(isDetailsLoading);

  const dispatch = useDispatch();
  const dataSaved = useSelector(isDataSaved);
  const { t } = useTranslation();

  usePrompt(dataSaved, t("superAdmin.table.clients.unsavedData"));

  unstable_useBlocker((tx) => {
    if (dataSaved) {
      dispatch(setUnsavedAlertModal({ path: tx }));
      tx?.retry();
    }
  });

  useEffect(() => {
    if (!isDetailsLoading) {
      dispatch(setClient({ ...clientDetails?.data?.client }));
    }
  }, [clientDetails]);

  return (
    <MainLayout>
      <PageHeader leftIcon={<ClientsIconSelected />} />
      <ClientEditTabStyled>
        <TopTabContainer
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          clientId={clientId}
          clientName={clientDetails?.data?.client?.fullName}
        />
        {isDetailsLoading ? (
          <div>loading client details...</div> // global spinner
        ) : (
          
            <ClientContent
              client={clientDetails?.data?.client}
              activeTab={activeTab}
            />
        )}
      </ClientEditTabStyled>
    </MainLayout>
  );
};

export default ClientEditTab;
