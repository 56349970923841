import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const appointmentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointmentById: builder.query({
      query: (appointmentId) => ({
        url: apiEndpoints.appointments.getAppointmentById(appointmentId),
      }),
    }),
    getAvailableTimeSlots: builder.query({
      query: (data) => ({
        url: apiEndpoints.appointments.getAvailableTimeSlots(data),
      }),
    }),
    scheduleAppointment: builder.mutation({
      query: ({ userId, data }) => ({
        url: apiEndpoints.appointments.addAppointment(userId),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointments"],
    }),
    makeRecurringAppointment: builder.mutation({
      query: ({ userId, data }) => ({
        url: apiEndpoints.appointments.makeRecurringAppointment(userId),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointments"],
    }),
    getPaginatedAppointmentsForClient: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10, filters = {} }) => ({
        url: apiEndpoints.appointments.getPaginatedForClient(
          id,
          pageNumber,
          pageSize,
          filters
        ),
      }),
      providesTags: ["Appointments"],
    }),
    scheduleAppointmentAsClientAdmin: builder.mutation({
      query: ({ clientId, data }) => ({
        url: apiEndpoints.appointments.addAppointmentAsClientAdmin(clientId),
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointments"],
    }),
    getPaginatedAppointmentsForUser: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10, filters = {} }) => {
        return {
          url: apiEndpoints.appointments.getPaginatedForUser(
            id,
            pageNumber,
            pageSize,
            filters
          ),
        };
      },
      providesTags: ["Appointments"],
    }),
    getPaginatedAppointmentsRequestsForUser: builder.query({
      query: ({ id, pageNumber = 1, pageSize = 10, filters = {} }) => {
        return {
          url: apiEndpoints.appointments.getPaginatedAppointmentRequestsForUser(
            id,
            pageNumber,
            pageSize,
            filters
          ),
        };
      },
      providesTags: ["Appointments"],
    }),
    cancelAppointment: builder.mutation({
      query: ({ appointmentId, userId }) => ({
        url: apiEndpoints.appointments.cancelAppointment(appointmentId, userId),
        method: "PUT",
      }),
      invalidatesTags: ["Appointments"],
    }),
    cancelAppointmentByClient: builder.mutation({
      query: ({ appointmentId, clientId }) => ({
        url: apiEndpoints.appointments.cancelAppointmentByClient(
          appointmentId,
          clientId
        ),
        method: "PUT",
      }),
      invalidatesTags: ["Appointments"],
    }),
    cancelRecurringAppointments: builder.mutation({
      query: ({ recurringAppointmentId, userId }) => ({
        url: apiEndpoints.appointments.cancelRecurringAppointments(
          recurringAppointmentId,
          userId
        ),
        method: "PUT",
      }),
      invalidatesTags: ["Appointments"],
    }),
    makeWishForAppointment: builder.mutation({
      query: ({ userId, data }) => ({
        url: apiEndpoints.appointments.makeWishForAppointment(userId),
        method: "POST",
        body: data,
      }),
    }),
    getUserWishList: builder.query({
      query: ({ userId }) => ({
        url: apiEndpoints.users.getUserWishList(userId),
        method: "GET",
      }),
      invalidatesTags: ["Appointments"],
    }),
    getAppointmentsForClientEmployee: builder.query({
      query: ({ userId, pageNumber = 1, pageSize = 1, filters = {} }) => ({
        url: apiEndpoints.appointments.getClientEmployeeAppointments(
          userId,
          pageNumber,
          pageSize,
          filters
        ),
        method: "GET",
      }),
      invalidatesTags: ["Appointments"],
    }),
    declineAppointmentRequest: builder.mutation({
      query: (appointmentId) => ({
        url: apiEndpoints.appointments.declineAppointmentRequest(appointmentId),
        method: "PATCH",
      }),
    }),
    acceptAppointmentRequest: builder.mutation({
      query: ({ appointmentId, authorizeUser = false }) => ({
        url: apiEndpoints.appointments.acceptAppointmentRequest(
          appointmentId,
          authorizeUser
        ),
        method: "PATCH",
      }),
      invalidatesTags: ["AuthorizedUsers"],
    }),
  }),
});

export const {
  useMakeRecurringAppointmentMutation,
  useGetPaginatedAppointmentsRequestsForUserQuery,
  useMakeWishForAppointmentMutation,
  useGetAppointmentsForClientEmployeeQuery,
  useGetUserWishListQuery,
  useGetAvailableTimeSlotsQuery,
  useScheduleAppointmentMutation,
  useGetPaginatedAppointmentsForClientQuery,
  useScheduleAppointmentAsClientAdminMutation,
  useGetPaginatedAppointmentsForUserQuery,
  useCancelAppointmentMutation,
  useCancelAppointmentByClientMutation,
  useCancelRecurringAppointmentsMutation,
  useDeclineAppointmentRequestMutation,
  useAcceptAppointmentRequestMutation,
  useGetAppointmentByIdQuery,
} = appointmentApi;
