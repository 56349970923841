export const Recurring = {
  NOREPEAT: 0,
  WEEKLY: 1,
  BIWEEKLY: 2,
  EVERY3WEEKS: 3,
  EVERY4WEEKS: 4,
};

export const recurringTypes = [
  {
    id: Recurring.NOREPEAT,
    name: "scheduleAppointment.recurringNoRepeat",
  },
  {
    id: Recurring.WEEKLY,
    name: "scheduleAppointment.recurringRepeatWeekly",
  },
  {
    id: Recurring.BIWEEKLY,
    name: "scheduleAppointment.recurringRepeatBiWeekly",
  },
  {
    id: Recurring.EVERY3WEEKS,
    name: "scheduleAppointment.recurringRepeatEvery3Weeks",
  },
  {
    id: Recurring.EVERY4WEEKS,
    name: "scheduleAppointment.recurringRepeatEvery4Weeks",
  },
];
