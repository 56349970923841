import React, { useMemo, useState } from "react";
import Table from "../../components/Table/Table";
import { useGetPaginatedClientsQuery } from "features/clients/clientsApiSlice";
import clientsTableConstants from "constants/tableConstants/clientsTableConstants";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Status from "components/Status/Status";
import { InputFieldContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import SelectFilter from "components/Filter/SelectFilter/SelectFilter";
import { useGetAllCitiesQuery } from "features/lookup/lookupSlice";
import { useGetAllServiceCategoriesQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import { clientStatuses } from "constants/filters/statusConstants";
import { randomIdGenerator } from "util/randomGenerator";
import MainLayout from "layouts/MainLayout";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import PageHeader from "components/PageHeader/PageHeader";
import { ClientsIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import { removeFilter } from "util/filterHelper";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import useDevice from "hooks/useDevice";

const SuperAdminPageClients = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDevice();

  const mapRowDataFunction = (rowData) =>
    rowData.data?.clients?.map?.((client) => {
      return {
        ...client,
        client: client.fullName,
        location: client.city,
        status: (
          <Status
            isActive={client.isActive}
            activeTextKey="superAdmin.table.clients.statusActive"
            inactiveTextKey="superAdmin.table.clients.statusInactive"
          />
        ),
      };
    });

  const clientDetailsBtnClick = (client) => {
    const pathParams = { clientId: client.id };
    const resolvedRoute = replaceInRoute(
      PAGES.CLIENTS_DETAILS_INFO.route,
      pathParams
    );
    return resolvedRoute;
  };

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  const mapCitiesFunction = (data) =>
    data.data.cityNames.map((name) => {
      return {
        id: name,
        name,
      };
    });

  const mapServiceCategoriesFunction = (data) =>
    data.data.serviceCategories.map((sc) => {
      return {
        id: sc.id,
        name: sc.name,
      };
    });

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()}>
        <TextFilter
          label={"common.labelClientName"}
          placeholder={"superAdmin.table.clients.filters.clientNameInput"}
          onChange={(name) =>
            setFilters((prevState) => ({
              ...prevState,
              name: { id: name, name, valueToRemove: filterValueToRemove.NAME },
            }))
          }
          value={filters?.name?.id}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <SelectFilter
          label={"common.labelLocation"}
          placeholder={"superAdmin.table.clients.filters.citySelect"}
          defaultValue={"superAdmin.table.clients.filters.allCities"}
          onChange={(city) =>
            setFilters((prevState) => ({
              ...prevState,
              city: { ...city, valueToRemove: filterValueToRemove.CITY },
            }))
          }
          value={filters?.city}
          mapDataFunction={mapCitiesFunction}
          useQuery={useGetAllCitiesQuery}
        />
        <SelectFilter
          label={"common.status"}
          placeholder={"superAdmin.table.clients.filters.statusSelect"}
          defaultValue={"superAdmin.table.clients.filters.allStatuses"}
          onChange={(status) =>
            setFilters((prevState) => ({
              ...prevState,
              status: { ...status, valueToRemove: filterValueToRemove.STATUS },
            }))
          }
          value={filters?.status}
          items={clientStatuses}
        />
      </InputFieldContainer>,
      <InputFieldContainer key={randomIdGenerator()}>
        <SelectFilter
          label={"common.labelPrimaryService"}
          placeholder={"superAdmin.table.clients.filters.primaryServiceSelect"}
          defaultValue={"superAdmin.table.clients.filters.allServices"}
          onChange={(primaryService) =>
            setFilters((prevState) => ({
              ...prevState,

              primaryService: {
                ...primaryService,
                valueToRemove: filterValueToRemove.PRIMARY_SERVICE,
              },
            }))
          }
          value={filters?.primaryService}
          mapDataFunction={mapServiceCategoriesFunction}
          useQuery={useGetAllServiceCategoriesQuery}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );

  const AddFilters = () => {};

  return (
    <MainLayout>
      <PageHeader
        leftIcon={<ClientsIconSelected />}
        isFilterButtonShown={true}
        filterComponents={filterComponents}
        appliedFilters={appliedFilters}
        filters={filters}
        removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
        saveFilters={saveFilters}
        onClick={AddFilters}
      />
      <Table
        isSuperAdmin
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientsTableConstants}
        usePaginatedQuery={useGetPaginatedClientsQuery}
        rowBtnClick={clientDetailsBtnClick}
        appliedFilters={appliedFilters}
        numberOfCharactersPerCell={isDesktop ? 30 : Infinity}
        t={t}
      />
    </MainLayout>
  );
};

SuperAdminPageClients.propTypes = {
  theme: PropTypes.any,
};

export default SuperAdminPageClients;
