import React, { useState, useEffect } from "react";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  editDescription,
  resetFlag,
  toggleFlag,
} from "features/clients/clientSlice";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { descriptionInitialValue } from "initialValues/clientDescriptionInitialValues";
import {
  AccordionStyled,
  TypographyStyled,
} from "components/ScheduleEditor/ScheduleEditor.styled";
import RichTextEditor from "../../RIchTextEditor/RichTextEditor";
import { WarningMessage } from "../../RIchTextEditor/RichTextEditor.styled";

const stripHtmlTags = (input) => {
  return input.replace(/<[^>]*>/g, "");
};

const MAX_CHARACTERS = 1001;

export const DescriptionAccordion = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const formik = useFormik({
    initialValues: descriptionInitialValue(props?.description),
    validate: (values) => {
      const errors = {};
      const plainText = stripHtmlTags(values.description || "");

      if (plainText.length >= MAX_CHARACTERS) {
        errors.description = t("common.charsLimit", {
          count: MAX_CHARACTERS,
        });
      }

      return errors;
    },
    onSubmit: (values) => {
      dispatch(editDescription(values.description));
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }
    const plainText = stripHtmlTags(formik.values.description || "");

    if (formik.dirty && plainText.length < MAX_CHARACTERS) {
      dispatch(toggleFlag());
    } else {
      dispatch(resetFlag());
    }
  }, [formik.values.description, dispatch]);

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
  };

  const handleEditorChange = async (value) => {
    formik.setFieldValue("description", value);
    dispatch(editDescription(value));
  };

  return (
    <AccordionStyled expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TypographyStyled>{t("common.description")}</TypographyStyled>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={formik.handleSubmit}>
          <RichTextEditor
            value={formik.values.description}
            onChange={handleEditorChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.description && (
            <WarningMessage>{formik.errors.description} </WarningMessage>
          )}
        </form>
      </AccordionDetails>
    </AccordionStyled>
  );
};

DescriptionAccordion.propTypes = {
  description: PropTypes.string,
};
