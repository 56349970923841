import React from "react";
import PropTypes from "prop-types";
import {
  LoginButton,
  LogInImageStyled,
  RegisterButton,
  RegisterLoginOptionsContainer,
} from "./RegisterLoginOptions.styled";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";

const RegisterLoginOptions = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  return (
    <>
      {!isMobile ? (
        <RegisterLoginOptionsContainer>
          <NavLink to={PAGES.MIDDLEPAGE.route}>
            <RegisterButton value={t("register.registerTitle")} />
          </NavLink>
          <NavLink to={PAGES.LOGIN.route}>
            <LoginButton value={t("login.logInTitle")} />
          </NavLink>
        </RegisterLoginOptionsContainer>
      ) : (
        <NavLink to={PAGES.LOGIN.route}>
          <LogInImageStyled />
        </NavLink>
      )}
    </>
  );
};

RegisterLoginOptions.propTypes = {
  isLoggedIn: PropTypes.bool,
  children: PropTypes.node,
};

export default RegisterLoginOptions;
