import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as PencileIcon } from "assets/images/svg/pencile-icon.svg";
import { NavLink } from "react-router-dom";
import useDevice from "hooks/useDevice";
import { EditPenImgStyled } from "./TableBodyContainer.styled";

const EditRenderer = (props) => {
  const { isDesktop } = useDevice();

  return (
    <>
      {isDesktop ? (
        <NavLink onClick={props?.onClickEdit}>
          <ActionButton startIcon={<PencileIcon />} value={"common.edit"} />
        </NavLink>
      ) : (
        <EditPenImgStyled onClick={props.onClickEdit} />
      )}
    </>
  );
};
EditRenderer.propTypes = {
  onClickEdit: PropTypes.func,
};

export default EditRenderer;
