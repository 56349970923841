import { resourceTypes } from "constants/resourceType";
import { useTranslation } from "react-i18next";

const ResourceTypeRenderer = (props) => {
  const { t } = useTranslation();

  const resource = resourceTypes.find((type) => type.id === props?.params);
  return resource ? t(resource.name) : t("common.unknownType");
};

ResourceTypeRenderer.propTypes = {};

export default ResourceTypeRenderer;
