import { Box, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const RightAuthContainer = styled.div`
  max-width: 1200px;
  width: 666px;
  height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 46px 38px 10px 38px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 8px 12px;
      height: 90%;
      width: 100%;
      gap: 10px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: calc(100% - 72px);
      max-height: 599px;
      max-width: 343px;
      padding: 0px;
      width: 100%;
    }
  }
`;

export const LanguageContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 600px;
  height: fit-content;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: fit-content;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: fit-content;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
    }
  }
`;

export const UpperContainerLogIn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 0px;
    }
  }
`;

export const LogoStyledNavLink = styled(NavLink)`
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 8px 12px;
      transition: padding 0.3s ease-in-out;
      height: 45px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 24px;
      width: 90.68px;
    }
  }
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }
`;

export const InputFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  gap: ${(props) => (props?.gap ? "3px" : "16px")};
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 275px;
          }
        `
      : css`
          & > div {
            width: 566px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 100%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      > div {
        width: 100%;
        gap: 0px;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      > div {
        width: 100%;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 10px;
      flex-direction: row;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 10px;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 100px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding-top: 0px;
      gap: 5px;
    }
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding-top: 24px;
      gap: 8px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 5px;
      padding-top: ${(props) => (props?.gap ? "100px" : "20px")};
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props?.flexDirection};
  text-align: ${(props) => props?.textAlign};
  font-size: ${(props) => props?.fontSize || "16px"};
  font-weight: ${(props) => props?.fontWeight};
  justify-content: ${(props) => props?.justifyContent};
  color: ${(props) => props?.color};
  align-items: center;
  gap: 5px;
`;

export const RegTitleContainer = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const SentCodeMessageContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 14px;
    line-height: 21px;
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const SentCodeBtnContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const NextBackBtnsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props?.twoButtons ? "space-between" : "right"};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 33px;
      ${(props) => props.theme.breakpoints.rotate()} {
        height: 25px;
      }
    }
  }
`;

export const BottomTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding-top: 0px;
    }
  }
`;

export const FirstText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Urbanist" !important;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const ForgotPWLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  padding-right: 35px;
  font-family: "Urbanist" !important;
  color: ${(props) => props?.theme?.colors?.text3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 5px 25px 15px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 5px 25px 15px 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }
`;

export const LogRegLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.color};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding-top: 0px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: ${(props) => props?.theme?.colors?.leftAuthContentBackground};
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: ${(props) => props?.theme?.colors?.leftAuthContentBackground};
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  padding-bottom: 30px;
  font-family: "Baloo 2" !important;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding-bottom: 0px;
      font-size: 24px;
      line-height: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-bottom: 0px;
      font-size: 24px;
      line-height: 24px;
    }
  }
`;

export const LogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const OrDividerGoogleBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
      @media (orientation: landscape) {
      }
    }
  }
`;

export const JustifiedRightItems = styled.div`
  display: flex;
  justify-content: right;
`;
