import { apiSlice } from "features/api/apiSlice";
import apiEndpoints from "features/apiEndpoints";

export const clientsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedClients: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, filters = {} }) => {
        const url = apiEndpoints.clients.getPaginated(
          pageNumber,
          pageSize,
          filters
        );
        return { url };
      },
      providesTags: ["Client"],
    }),
    getClientById: builder.query({
      query: (id) => {
        const url = apiEndpoints.clients.getById(id);
        return { url };
      },
      providesTags: ["Client"],
    }),
    updateClient: builder.mutation({
      query: ({ id, clientData }) => ({
        url: apiEndpoints.clients.updateClient(id),
        method: "PUT",
        body: clientData,
      }),
      invalidatesTags: ["Client"],
    }),
    addClientLogo: builder.mutation({
      query: (formData) => ({
        url: apiEndpoints.clients.addClientLogo,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteClientImage: builder.mutation({
      query: (id) => ({
        url: apiEndpoints.clients.deleteClientImage(id),
        method: "DELETE",
      }),
      invalidatesTags: ["Images"],
    }),
    getClientImage: builder.query({
      query: (id) => ({
        url: apiEndpoints.clients.getClientImage.replace("{id}", id),
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
    }),
    getClientGallery: builder.query({
      query: (id) => ({
        url: apiEndpoints.clients.getClientGallery.replace("{id}", id),
      }),
      providesTags: ["Images"],
    }),
    addClientImage: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.clients.addClientImage,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Images"],
    }),
    activateClient: builder.mutation({
      query: (id) => ({
        url: apiEndpoints.clients.activateClient.replace("{id}", id),
        method: "PATCH",
      }),
      invalidatesTags: ["Client"],
    }),
    deactivateClient: builder.mutation({
      query: (id) => ({
        url: apiEndpoints.clients.deactivateClient.replace("{id}", id),
        method: "PATCH",
      }),
      invalidatesTags: ["Client"],
    }),
    getClientLogo: builder.query({
      query: ({ id }) => ({
        url: apiEndpoints.images.getClientLogo(id),
        method: "GET",
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: (response, meta) => {
        const imageUrl = URL.createObjectURL(
          new Blob([response], { type: meta.response.headers["Content-Type"] })
        );

        return { imageUrl };
      },
      providesTags: ["Client"],
    }),

    getAllClientResources: builder.query({
      query: (clientId) => ({
        url: apiEndpoints.clientResources.getAll(clientId),
      }),
      providesTags: ["Client"],
    }),
    getPaginatedClientResources: builder.query({
      query: ({ clientId, pageNumber = 1, pageSize = 10 }) => ({
        url: apiEndpoints.clientResources.getPaginated(
          clientId,
          pageNumber,
          pageSize
        ),
      }),
      providesTags: ["Client"],
    }),
    updateClientResource: builder.mutation({
      query: ({ clientId, resourceId, formData }) => ({
        url: apiEndpoints.clientResources.updateResource(clientId, resourceId),
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Client"],
    }),
    addClientResource: builder.mutation({
      query: ({ clientId, formData }) => ({
        url: apiEndpoints.clientResources.addResource(clientId),
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Client"],
    }),
    deleteClientResource: builder.mutation({
      query: ({ clientId, resourceId, data }) => ({
        url: apiEndpoints.clientResources.deleteResource(clientId, resourceId),
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Client"],
    }),

    getClientService: builder.query({
      query: ({ clientId, clientServiceId }) => ({
        url: apiEndpoints.clientService.clientService
          .replace("{clientId}", clientId)
          .replace("{clientServiceId}", clientServiceId),
      }),
      providesTags: ["ClientServices"],
    }),

    getClientServices: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, id, filters = {} }) => ({
        url: apiEndpoints.clientService.clientServices(
          id,
          pageNumber,
          pageSize,
          filters
        ),
      }),
      providesTags: ["ClientServices"],
    }),

    getActiveClientServices: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, id, filters = {} }) => {
        return {
          url: apiEndpoints.clientService.activeClientServices(
            id,
            pageNumber,
            pageSize,
            filters
          ),
        };
      },
      providesTags: ["ClientServices"],
    }),

    addClientService: builder.mutation({
      query: ({ clientId, formData }) => ({
        url: apiEndpoints.clientService.addClientService.replace(
          "{clientId}",
          clientId
        ),
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ClientServices"],
    }),
    updateClientService: builder.mutation({
      query: ({ clientId, clientServiceId, formData }) => ({
        url: apiEndpoints.clientService.updateClientService
          .replace("{clientId}", clientId)
          .replace("{clientServiceId}", clientServiceId),
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["ClientServices"],
    }),
    deleteClientService: builder.mutation({
      query: ({ clientId, clientServiceId }) => ({
        url: apiEndpoints.clientService.deleteClientService
          .replace("{clientId}", clientId)
          .replace("{clientServiceId}", clientServiceId),
        method: "DELETE",
      }),
      invalidatesTags: ["ClientServices"],
    }),
    getAllClientLicenses: builder.query({
      query: () => ({
        url: apiEndpoints.clientLicenses.getAll,
      }),
      providesTags: ["Client"],
    }),
    getPaginatedClientLicenses: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, id }) => ({
        url: apiEndpoints.clientLicenses.getPaginated
          .replace("{id}", id)
          .replace("{pageNumber}", pageNumber)
          .replace("{pageSize}", pageSize),
      }),
      providesTags: ["Client"],
    }),
    UpdateLicense: builder.mutation({
      query: ({ id, data }) => ({
        url: apiEndpoints.clientLicenses.updateLicence.replace("{id}", id),
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Client"],
    }),
    AddLicense: builder.mutation({
      query: (data) => ({
        url: apiEndpoints.clientLicenses.addLicence,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Client"],
    }),
    DeleteLicense: builder.mutation({
      query: (id) => ({
        url: apiEndpoints.clientLicenses.deleteLicense.replace("{id}", id),
        method: "DELETE",
      }),
      invalidatesTags: ["Client"],
    }),
    getImage: builder.query({
      query: (id) => ({
        url: apiEndpoints.images.getImage(id),
        responseHandler: async (response) => {
          const blob = await response.blob();
          return URL.createObjectURL(blob);
        },
      }),
      invalidatesTags: ["Images"],
    }),
    subscribeUserToClient: builder.mutation({
      query: ({ userId, clientId }) => ({
        url: apiEndpoints.users.subscribeToClient(userId, clientId),
        method: "POST",
      }),
      invalidatesTags: ["Client"],
    }),
    unsubscribeUserToClient: builder.mutation({
      query: ({ userId, clientId }) => ({
        url: apiEndpoints.users.unsubscribeToClient(userId, clientId),
        method: "PUT",
      }),
      invalidatesTags: ["Client"],
    }),
    getFavouriteClients: builder.query({
      query: ({ userId }) => ({
        url: apiEndpoints.users.getFavouriteClients.replace("{userId}", userId),
      }),
      providesTags: ["Client"],
    }),
    getAuthorizedUsers: builder.query({
      query: ({ clientId, filters = {}, pageNumber, pageSize }) => ({
        url: apiEndpoints.clients.getAuthorizedUsers(
          clientId,
          pageNumber,
          pageSize,
          filters
        ),
      }),
      providesTags: ["AuthorizedUsers"],
    }),
    deauthorizeUser: builder.mutation({
      query: ({ clientId, userId }) => ({
        url: apiEndpoints.clients.deauthorizeUser(clientId, userId),
        method: "PATCH",
      }),
      invalidatesTags: ["AuthorizedUsers"],
    }),
  }),
});

export const {
  useGetAuthorizedUsersQuery,
  useDeauthorizeUserMutation,
  useGetImageQuery,
  useGetAllClientLicensesQuery,
  useGetPaginatedClientLicensesQuery,
  useAddLicenseMutation,
  useUpdateLicenseMutation,
  useDeleteLicenseMutation,
  useGetClientServiceQuery,
  useGetClientServicesQuery,
  useGetActiveClientServicesQuery,
  useAddClientServiceMutation,
  useUpdateClientServiceMutation,
  useDeleteClientServiceMutation,
  useDeleteClientResourceMutation,
  useGetAllClientResourcesQuery,
  useGetPaginatedClientResourcesQuery,
  useAddClientResourceMutation,
  useUpdateClientResourceMutation,
  useGetClientLogoQuery,
  useGetPaginatedClientsQuery,
  useGetClientByIdQuery,
  useUpdateClientMutation,
  useAddClientLogoMutation,
  useDeleteClientImageMutation,
  useGetClientImageQuery,
  useGetClientGalleryQuery,
  useAddClientImageMutation,
  useActivateClientMutation,
  useDeactivateClientMutation,
  useSubscribeUserToClientMutation,
  useUnsubscribeUserToClientMutation,
  useGetFavouriteClientsQuery,
} = clientsApiSlice;
