import styled, { css } from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const Modal = styled.div`
  width: 460px;
  max-width: 95%;
  height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
`;

export const StatusInputContainer = styled.div`
  display: flex;
  gap: 5px;
  align-self: flex-start;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  background-color: white;
  padding: 20px;

  overflow: auto;
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
`;

export const LowerContainer = styled.div`
  background-color: ${(props) => props.theme?.colors?.white};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};

  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  display: flex;
  justify-content: flex-end;

  padding: 24px;
  gap: 12px;
`;

export const ActionButton = styled(Button)`
  min-height: 35px;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};

  padding: 6px 32px;
  font-size: 14px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
`;

export const CancelActionButton = styled(Button)`
  min-height: 33px;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => props.theme?.colors?.black};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};

  padding: 6px 32px;
  font-size: 14px;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 8px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
    }
  }
`;

export const InputFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 50%;
          }
        `
      : css`
          & > div {
            width: 100%;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      > div {
        width: 100%;
        gap: 0px;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      > div {
        width: 100%;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
`;
