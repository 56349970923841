import dayjs from "dayjs";

export const makeWishForAppointmentValues = {
  startDateUtc: null,
  endDateUtc: null,
  startTime: dayjs().startOf("day"),
  endTime: dayjs().endOf("day"),
  resourceIds: [],
  attributeIds: [],
  clientServiceId: "",
};
