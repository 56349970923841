import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  Modal,
  Title,
  ActionButton,
  CancelActionButton,
  UpperContainer,
  LowerContainer,
} from "./Modal.styled";
import {
  InputFieldContainer,
  InputFieldsContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { TYPE, VARIANT } from "constants/buttonConstants";
import CustomInputField from "components/InputFields/CustomFields/CustomInputField";
import { ClientServiceConstants } from "constants/clientServiceConstants";
import { useDispatch } from "react-redux";
import {
  addAttribute,
  updateAttribute,
} from "features/clientService/clientServiceSlice";
import { ClientServiceAttributeValidation } from "validations/clientService/clientServiceAttributeValidation";
import { unsetShownModal } from "features/modal/modalSlice";
import { submit } from "features/clients/clientSlice";

const ClientServiceAttributeModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const attributeData = {
      name: formik.values.name,
      description: formik.values.description,
      durationInMinutes: formik.values.durationInMinutes,
      price: formik.values.price,
    };

    if (editFlag) {
      const updatedAttribute = {
        ...props?.selectedItem,
        ...attributeData,
      };
      dispatch(updateAttribute(updatedAttribute));
    } else {
      dispatch(addAttribute(attributeData));
    }
    dispatch(submit());
    dispatch(unsetShownModal());
  };

  const editFlag = useMemo(
    () => props?.selectedItem != null,
    [props?.selectedItem]
  );
  const formik = useFormik({
    initialValues: {
      name: props?.selectedItem?.name,
      description: props?.selectedItem?.description,
      durationInMinutes: props?.selectedItem?.durationInMinutes,
      price: props?.selectedItem?.price,
    },
    validationSchema: ClientServiceAttributeValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Modal>
      <UpperContainer>
        <Title>
          {editFlag
            ? t("clientService.editAttribute")
            : t("clientService.addAttribute")}
        </Title>
        <InputFieldsContainer>
          <InputFieldContainer>
            <CustomInputField
              placeholder={"clientService.placeholderAttributeName"}
              name={ClientServiceConstants.name}
              label={"clientService.attributeName"}
              value={formik?.values?.name}
              formik={formik}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <CustomInputField
              placeholder={"clientService.placeholderAttributeDescription"}
              name={ClientServiceConstants.description}
              label={"clientService.attributeDescription"}
              formik={formik}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <CustomInputField
              placeholder={"clientService.placeholderAttributeAdditionalTime"}
              name={ClientServiceConstants.durationInMinutes}
              label={"clientService.attributeAdditionalTime"}
              formik={formik}
              type={TYPE.NUMBER}
            />
          </InputFieldContainer>
          <InputFieldContainer>
            <CustomInputField
              placeholder={"clientService.placeholderAttributeAdditionalPrice"}
              name={ClientServiceConstants.price}
              label={"clientService.attributeAdditionalPrice"}
              formik={formik}
              type={TYPE.NUMBER}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
      </UpperContainer>
      <LowerContainer>
        <CancelActionButton
          variant={VARIANT.OUTLINED}
          value={t("common.cancel")}
          onClick={props.onCancel}
        />
        <ActionButton
          onClick={formik.handleSubmit}
          value={editFlag ? t("common.save") : t("common.add")}
        />
      </LowerContainer>
    </Modal>
  );
};

ClientServiceAttributeModal.propTypes = {
  selectedItem: PropTypes.object,
  onCancel: PropTypes.func,
};

export default ClientServiceAttributeModal;
