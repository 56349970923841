import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { unsetOfflineModal } from "features/modal/modalSlice";
import {
  CloseButton,
  Description,
  OfflineContainer,
  OfflineImage,
  OfflineImageContainer,
  Title,
} from "./OfflineModal.styled";

const OfflineModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <OfflineContainer>
      <OfflineImageContainer>
        <OfflineImage />
      </OfflineImageContainer>
      <Title>{t("offline.title")}</Title>
      <Description>
        <Trans i18nKey={"offline.description"} />
      </Description>
      <CloseButton
        value={t("common.ok")}
        onClick={() => dispatch(unsetOfflineModal())}
      />
    </OfflineContainer>
  );
};

export default OfflineModal;
