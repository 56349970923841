import React from "react";
import PropTypes from "prop-types";
import { StyledTypography, TopTabStyled } from "./TopTab.styled";

export const TopTab = (props) => {
  return (
    <TopTabStyled
      to={props?.goTo}
      selected={props?.isActive}
      onClick={props?.onClick}
    >
      <StyledTypography $isSelected={props?.isActive}>
        {props?.title}
      </StyledTypography>
    </TopTabStyled>
  );
};
TopTab.propTypes = {
  goTo: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};
