import { Calendar } from "react-big-calendar";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const CalendarComponentStyled = styled(Calendar)`
  gap: 16px;
  padding: ${(props) => (props?.isUser ? "0px" : "0px 16px")};
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px 9px;
  }
  .rbc-show-more {
    ${(props) => props.theme.breakpoints.down("sm")} {
      font-size: 10px;
    }
    ${(props) => props.theme.breakpoints.rotate()} {
      font-size: 12px;
    }
  }
  .rbc-month-view,
  .rbc-time-view,
  .rbc-agenda-view {
    border-radius: 16px;
    box-shadow: 5px 3px 3px 3px
      ${(props) =>
        hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.3)};
  }
  .rbc-month-header {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    min-height: 22px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-color: 1px solid transparent;
    ${(props) => props.theme.breakpoints.down("sm")} {
      height: 22px;
    }
    .rbc-header {
      display: flex;
      justify-content: center;
      align-items: center;

      ${(props) => props.theme.breakpoints.down("sm")} {
        height: 22px;
      }
    }
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-time-header {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    box-shadow: 16px 0px 0px 0px
      ${(props) => props?.theme?.colors?.accordionClosed};
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-right: chartreuse;
    min-height: 22px;

    .rbc-header {
      white-space: initial;
      font-weight: bold;
      border: none;

      ${(props) => props.theme.breakpoints.down("sm")} {
        max-width: 35px;
      }
    }
  }
  .rbc-day-bg {
    flex: none;
  }
  .rbc-time-view .rbc-row {
    min-height: inherit;
    align-items: stretch;
    justify-content: space-around;
    width: 100%;
    flex: 1 1 0px;

    &.rbc-time-header-cell > .rbc-header {
      min-height: 22px;
      display: flex;
      justify-content: center;

      span[role="columnheader"] {
        font-weight: bold;
      }
    }

    &.rbc-time-header-cell > .rbc-header.rbc-today {
      .rbc-button-link {
        background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
        color: ${(props) => props?.theme?.colors?.white};
        border-radius: 12px;
        padding: 2px;
      }
    }
  }
  .rbc-time-content,
  .rbc-day-bg + .rbc-day-bg {
    border-left: none;
  }
  .rbc-time-header > .rbc-time-header-content {
    border-left: none;
  }
  .rbc-btn-group {
    display: flex;
    flex-direction: row;
    gap: 8px;

    > button {
      border-radius: 8px;
      gap: 4px;
      height: 24px;
      display: flex;
      align-items: center;
      border: 1px solid ${(props) => props?.theme?.colors?.timeSlotBg};
    }
  }
  .rbc-calendar {
    border-top-right-radius: 16px;
  }
  .rbc-day-bg:last-of-type {
    border-bottom-right-radius: 16px;
  }
  .rbc-agenda-table > thead {
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .rbc-agenda-table > thead > tr > th {
    text-align: center !important;
  }
  .rbc-agenda-time-cell,
  .rbc-agenda-date-cell,
  .rbc-agenda-event-cell {
    background-color: ${(props) => props?.theme?.colors?.white};
    color: ${(props) => props?.theme?.colors?.black};
    border: 1px solid
      ${(props) => hexToRGB(props?.theme?.colors?.timeSlotBg, 0.3)};
  }
  .rbc-agenda-content {
    overflow-y: auto;
    .rbc-agenda-table {
      overflow: hidden;
      .rbc-header {
        min-height: 22px;
      }
    }
  }
  .rbc-time-view {
    overflow: auto;
  }
  .rbc-time-view::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  }
  .rbc-time-view::-webkit-scrollbar-track {
    border-radius: 16px;
  }
  .rbc-agenda-empty {
    margin-left: 20px;
  }
  .rbc-time-slot,
  .rbc-label {
    min-width: 82px;
    width: 135;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;

    ${(props) => props.theme.breakpoints.down("md")} {
      min-width: auto;
      font-size: 14px;
      line-height: 14px;
    }

    ${(props) => props.theme.breakpoints.down("sm")} {
      min-height: 45px;
      font-size: 10px;
      line-height: 10px;
    }

    ${(props) => props.theme.breakpoints.rotate()} {
      min-height: 45px;
      font-size: 10px;
      line-height: 10px;
    }

    ${(props) => props.theme.breakpoints.down("xs")} {
      min-width: 30px;
    }
  }
  .rbc-label {
    ${(props) => props.theme.breakpoints.down("sm")} {
      min-height: initial;
      min-width: 40px !important;
      width: 40px !important;
    }

    ${(props) => props.theme.breakpoints.rotate()} {
      min-height: initial;
      min-width: 40px !important;
      width: 40px !important;
    }

    min-width: 82px !important;
    width: 82px !important;
    white-space: normal;
    overflow: visible;
    word-wrap: break-word;
    text-align: center;
  }

  .rbc-agenda-view {
    .rbc-agenda-table {
      table-layout: fixed;
      width: 100%;
    }
    .rbc-agenda-time-cell,
    .rbc-agenda-date-cell {
      width: 214px;

      ${(props) => props.theme.breakpoints.down("md")} {
        width: 140px;
      }
    }
    .rbc-agenda-date-cell {
      ${(props) => props.theme.breakpoints.down("sm")} {
        width: 74px;
      }
    }
    .rbc-agenda-time-cell {
      ${(props) => props.theme.breakpoints.down("sm")} {
        width: 126px;
      }
    }
    .rbc-agenda-event-cell {
      width: auto;
    }
  }
  .rbc-week .rbc-day-bg.rbc-today {
    background-color: ${(props) =>
      props?.theme?.colors?.highlightedDayBackground};
    color: ${(props) => props?.theme?.colors?.highlightedDayText};
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rbc-time-content {
    overflow-y: auto;
  }
  .rbc-time-content::-webkit-scrollbar {
    width: 12px;
    background-color: ${(props) => props?.theme?.colors?.accordionClosed};
  }
  .rbc-time-content::-webkit-scrollbar-thumb {
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
    border-radius: 4px;
  }
  .rbc-time-content::-webkit-scrollbar-track {
    border-radius: 4px;
  }
`;
