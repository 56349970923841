import { Box, Button } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const ClientCalendarContentStyled = styled(Box)`
  width: 100%;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  background-color: ${(props) => props?.theme?.colors?.white};
  padding-top: 10px;
  padding-right: 10px;
`;

export const AddAppointmentButton = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.darkBlue};
  transition: background-color 0.3s ease-in-out;
  border-radius: 12px;
  padding: 0px 15px;

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
  }

  height: 36px;
  max-width: 150px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.white};
  text-transform: none;
  white-space: normal;
  text-align: center;
  word-wrap: break-word;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 16px;

  ${(props) => props.theme.breakpoints.down("sm")}  {
    && {
      padding: 15px 9px;
    }
  }
`;
