import { AddButtonContainer } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import clientResourcesTableConstants from "constants/tableConstants/clientResourcesTableConstants";
import React from "react";
import { useTranslation } from "react-i18next";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import PropTypes from "prop-types";
import { ClientResourceContentStyled } from "./ClientResourceContent.styled";
import Table from "components/Table/Table";
import Status from "components/Status/Status";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddEditResourceModal,
  setDeleteResourceModal,
} from "features/modal/modalSlice";
import { selectClient } from "features/clients/clientSlice";
import { useGetPaginatedClientResourcesQuery } from "features/clients/clientsApiSlice";
import useDevice from "hooks/useDevice";

export const ClientResourceContent = () => {
  const client = useSelector(selectClient);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop } = useDevice();

  const mapRowDataFunction = (rowData) => {
    return rowData.data?.clientResources?.map?.((clientResource) => ({
      ...clientResource,
      name: clientResource.name,
      status: (
        <Status
          isActive={clientResource.isActive}
          activeTextKey="superAdmin.table.resources.statusActive"
          inactiveTextKey="superAdmin.table.resources.statusInactive"
        />
      ),
      email: clientResource.email ?? "-",
    }));
  };

  return (
    <ClientResourceContentStyled>
      <AddButtonContainer>
        <AddNewItemButton
          value={
            isDesktop
            ? t("superAdmin.table.clientResources.addResource")
            : t(
                "common.add"
              )}
          onClick={() =>
            dispatch(setAddEditResourceModal({ clientId: client.id }))
          }
        />
      </AddButtonContainer>
      <Table
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={clientResourcesTableConstants}
        usePaginatedQuery={useGetPaginatedClientResourcesQuery}
        t={t}
        modal={true}
        rowBtnClick={(resource) =>
          dispatch(setAddEditResourceModal({ clientId: client?.id, resource }))
        }
        isClient={true}
        clientId={client?.id}
        deleteBtnClick={(id) =>
          dispatch(
            setDeleteResourceModal({ clientId: client?.id, resourceId: id })
          )
        }
        numberOfCharactersPerCell={30}
      />
    </ClientResourceContentStyled>
  );
};

ClientResourceContent.propTypes = {
  clientId: PropTypes.any,
};
