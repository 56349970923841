import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetServiceCategoryImageQuery } from "features/serviceCategory/serviceCategoryApiSlice";
import {
  CategoryCardContainer,
  ImageIcon,
  TextContainer,
  StyledCardText,
  LineContainer,
  ImageContainer,
} from "./CategoryCard.styled";
import fallbackIcon from "assets/images/svg/default-service-image.svg";
import WithLoadingSpinner from "components/WithLoadingSpinner/WithLoadingSpinner";

const CategoryCard = (props) => {
  const { data: fetchedImage, isLoading } = useGetServiceCategoryImageQuery(
    props.imageId
  );

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let objectUrl = null;
    if (!isLoading) {
      if (fetchedImage instanceof Blob || fetchedImage instanceof File) {
        objectUrl = URL.createObjectURL(fetchedImage);
        setImageUrl(objectUrl);
      } else if (typeof fetchedImage === "string") {
        setImageUrl(fetchedImage);
      } else {
        setImageUrl(fallbackIcon);
      }
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [fetchedImage, isLoading]);

  return (
    <CategoryCardContainer
      to={props?.toGo}
      onClick={props?.onClick}
      index={props.index}
    >
      <ImageContainer>
        <WithLoadingSpinner isLoading={imageUrl == null} halfSize>
          <ImageIcon src={imageUrl} alt={props.name} />
        </WithLoadingSpinner>
      </ImageContainer>
      <TextContainer>
        <StyledCardText index={props.index}>{props.name}</StyledCardText>
        <LineContainer index={props.index} />
      </TextContainer>
    </CategoryCardContainer>
  );
};

CategoryCard.propTypes = {
  toGo: PropTypes.any,
  imageId: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  name: PropTypes.string,
};

export default CategoryCard;
