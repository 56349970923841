import styled from "styled-components";
import { FormHelperText, Select } from "@mui/material";

export const CustomSelectFieldStyled = styled(Select)`
  padding-left: ${({ isClient }) => (isClient ? "0px" : "20px")};
  ${({ isClient, value, theme }) =>
    isClient
      ? `color: ${theme?.colors?.black};`
      : value
      ? `color: ${theme?.colors?.black};`
      : `color: ${theme?.colors?.placeholderText};`}

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }

  &.MuiOutlinedInput-root {
    min-height: ${(props) => (props?.height ? props?.height : "40px")};
    & fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient ? theme?.colors?.text3 : theme?.colors?.text3};
      border-width: 1px;
    }

    &:hover fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient
          ? theme?.colors?.clientRegistration.Circle
          : theme?.colors?.secondaryColor};
    }

    &.Mui-focused fieldset {
      border-color: ${({ isClient, theme }) =>
        isClient
          ? theme?.colors?.secondaryColor
          : theme?.colors?.secondaryColor};
      border-width: 2px;
    }
  }
  & .MuiInputBase-input {
    padding: 8.5px 0px;
  }
  width: ${(props) => (props?.width ? props?.width : "100%")};
`;

export const CustomFormHelperText = styled(FormHelperText)`
  color: ${(props) => props?.theme?.colors?.errorRed};
  font-size: 12px;
  margin-left: 14px;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 4px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      top: 75%;
    }
  }
`;
