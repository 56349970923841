import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

export const TopTabStyled = styled(NavLink)`
  display: flex;
  min-width: 160px;
  min-height: 60px;
  border-radius: 16px 16px 0px 0px;
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;

  background-color: ${({ selected, theme }) =>
    selected
      ? theme.colors.updateTableButton
      : hexToRGB(theme.colors.updateTableButton, 0.08)};
  cursor: pointer;
  text-decoration: none;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      min-width: 128px;
      min-height: 49px;
      padding: 10px 26px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      min-width: 96px;
      min-height: 37px;
      padding: 8px 24px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ $isSelected }) => ($isSelected ? "white" : "black")};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
