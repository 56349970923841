import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";
//import { resourceTypes } from "constants/resourceType";
import ResourceTypeRenderer from "components/Table/ResourceTypeRenderer";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.resources.name",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "superAdmin.table.resources.type",
    field: "type",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <ResourceTypeRenderer
        params={params.row.type}
      />
    )
  },
  {
    columnId: 2,
    headerName: "common.status",
    field: "status",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 3,
    headerName: "common.labelEmail",
    field: "email",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 4,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        modal={params?.modal}
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
