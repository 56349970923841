import { Typography } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const MainContainer = styled.div`
  display: flex;
  gap: 24px;
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
    }
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 72%;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100%;
    }
  }
`;

export const RightContainer = styled.div`
  display: flex;
  gap: 40px;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  height: fit-content;
  width: 100%;
`;

export const ParametersContainer = styled.div`
  display: flex;
  gap: 40px;
  padding: 16px;
  padding-left: 32px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
  min-height: 275px;
`;

export const AvailableSlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 16px 24px;
  border-radius: 24px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  width: 100%;
`;

export const ChoosenTimeSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 26%;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100%;
    }
  }
`;

export const RecurringAppointmentsContainer = styled(RightContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ReccurringTitle = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 28.8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
    }
  }
`;
