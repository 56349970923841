import filterValueToRemove from "constants/filters/filterValueToRemove";

export const removeFilter = (valueToRemove, setFilters) => {
  setFilters((oldFilters) => {
    const newFilters = Object.fromEntries(
      Object.entries(oldFilters).filter(
        ([, item]) => item.valueToRemove !== valueToRemove
      )
    );
    return newFilters;
  });
};

export const getValueToRemoveStatuses = (id) => {
  switch (id) {
    case 0:
      return filterValueToRemove.STATUS_PENDING;
    case 1:
      return filterValueToRemove.STATUS_DECLINED;
    case 2:
      return filterValueToRemove.STATUS_ACTIVE;
    case 3:
      return filterValueToRemove.STATUS_CANCELED;
    case 4:
      return filterValueToRemove.STATUS_DONE;
    default:
      return null;
  }
};

export const getValueToRemoveTypes = (id) => {
  switch (id) {
    case 10:
      return filterValueToRemove.TYPE_REGULAR;
    case 11:
      return filterValueToRemove.TYPE_RESOURCE_ALLOCATION;
    case 12:
      return filterValueToRemove.TYPE_WHISLIST;
    default:
      return null;
  }
};
