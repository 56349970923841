export const primaryBreakpoints = {
  down: (size) => {
    const sizes = {
      xs: 375,
      sm: 600,
      ms: 820,
      md: 1024,
      lg: 1280,
      xl: 1920,
    };

    return `@media 
            (max-width: ${sizes[size]}px),
            (max-width: ${sizes[size]}px) and (orientation: portrait)`;
  },

  rotate: () => {
    return `@media (max-width: 980px) and (max-height: 600px) and (orientation: landscape)`;
  },
};
