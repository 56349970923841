import requestStatus from "constants/requestStatus/requestStatus";
import { setOfflineModal } from "features/modal/modalSlice";

export const internetConnectionMiddleware = (store) => (next) => (action) => {
  try {
    const isOnline = navigator.onLine;

    if (action.meta?.requestStatus === requestStatus.PENDING && !isOnline) {
      store.dispatch(setOfflineModal());
      return;
    }

    if (
      action.meta?.requestStatus === requestStatus.FULFILLED ||
      action.meta?.requestStatus === requestStatus.REJECTED
    ) {
      return next(action);
    }
  } catch (error) {
    console.error("Middleware error:", error);
  }

  return next(action);
};
