import React, { useMemo, useState } from "react";
import Table from "../../components/Table/Table";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setRemoveAuthorizedUserModal } from "features/modal/modalSlice";
import MainLayout from "layouts/MainLayout";
import PageHeader from "components/PageHeader/PageHeader";
import { ServicesIconSelected } from "components/Sidebar/SidebarTabs/SidebarNavigationItem.styled";
import authorizedUsersTableConstants from "constants/tableConstants/authorizedUsersTableConstants";
import { useGetAuthorizedUsersQuery } from "features/clients/clientsApiSlice";
import { InputFieldContainer } from "components/Filter/FilterContainer.styled";
import { randomIdGenerator } from "util/randomGenerator";
import TextFilter from "components/Filter/TextFilter/TextFilter";
import filterValueToRemove from "constants/filters/filterValueToRemove";
import { removeFilter } from "util/filterHelper";
import { useParams } from "react-router-dom";

const AuthorizedUsersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapRowDataFunction = (rowData) => {
    let newData = rowData.data?.users?.map?.((user) => {
      return {
        ...user,
      };
    });
    return newData;
  };
  const { clientId } = useParams();

  const [filters, setFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const saveFilters = () => {
    setAppliedFilters(filters);
  };

  const filterComponents = useMemo(
    () => [
      <InputFieldContainer key={randomIdGenerator()} twoInputs>
        <TextFilter
          label={"common.labelFirstName"}
          placeholder={"superAdmin.table.users.filters.userNameInput"}
          onChange={(firstName) =>
            setFilters((prevState) => {
              return {
                ...prevState,
                firstName: {
                  id: firstName,
                  name: firstName,
                  valueToRemove: filterValueToRemove.NAME,
                },
              };
            })
          }
          value={filters?.firstName?.id}
        />
        <TextFilter
          label={"common.labelLastName"}
          placeholder={"superAdmin.table.users.filters.userLastNameInput"}
          onChange={(lastName) =>
            setFilters((prevState) => {
              return {
                ...prevState,
                lastName: {
                  id: lastName,
                  name: lastName,
                  valueToRemove: filterValueToRemove.USER_LAST_NAME,
                },
              };
            })
          }
          value={filters?.lastName?.id}
        />
      </InputFieldContainer>,
    ],
    [filters]
  );
  return (
    <MainLayout>
      <PageHeader
        leftIcon={<ServicesIconSelected />}
        isAddButtonShown={false}
        isFilterButtonShown={true}
        saveFilters={saveFilters}
        filterComponents={filterComponents}
        filters={filters}
        appliedFilters={appliedFilters}
        removeFilter={(idToRemove) => removeFilter(idToRemove, setFilters)}
      />
      <Table
        appliedFilters={appliedFilters}
        clientId={clientId}
        mapRowDataFunction={mapRowDataFunction}
        tableHeaders={authorizedUsersTableConstants}
        usePaginatedQuery={useGetAuthorizedUsersQuery}
        t={t}
        tableAlignmentLeft={true}
        rowBtnClick={(user) =>
          dispatch(
            setRemoveAuthorizedUserModal({ user: user, clientId: clientId })
          )
        }
        numberOfCharactersPerCell={20}
      />
    </MainLayout>
  );
};

AuthorizedUsersPage.propTypes = {
  theme: PropTypes.any,
};

export default AuthorizedUsersPage;
