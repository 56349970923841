import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isInRoute } from "util/routeHelpers";
import {
  NavLinkContainer,
  StyledTypography,
  TabWrapper,
} from "./SidebarNavigationItem.styled";
import { useSelector } from "react-redux";
import { selectIsSidebarCollapsed } from "features/sidebar/sidebarSlice";

export const SidebarNavigationItem = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isCollapsed = useSelector(selectIsSidebarCollapsed);
  const isInThisRoute = isInRoute(props?.path, location?.pathname);
  return (
    <NavLinkContainer
      to={props?.path}
      style={{ textDecoration: "none" }}
      $collapsed={props?.collapsed}
    >
      <TabWrapper selected={isInThisRoute} $collapsed={props?.collapsed}>
        {isInThisRoute ? props?.iconSelected : props?.icon}
        <StyledTypography $displayHidden={isCollapsed}>
          {t(props?.title)}
        </StyledTypography>
      </TabWrapper>
    </NavLinkContainer>
  );
};

SidebarNavigationItem.propTypes = {
  selected: PropTypes.bool,
  collapsed: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  iconSelected: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  title: PropTypes.string,
};

export default SidebarNavigationItem;
