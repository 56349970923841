import { SaveButtonWrapper } from "components/ClientEditTab/ClienEditTab.styled";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const ClientServiceContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  padding: 16px;
  border-radius: 24px;
  min-width: fit-content;
  background-color: ${(props) => props?.theme?.colors?.backgroundColor};
  box-shadow: 2px 2px 8px ${(props) => props?.theme?.colors?.shadowColor};
  gap: 32px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
      gap: 8px;

      padding: 0;
      border-radius: initial;
      min-width: unset;
      background-color: inherit;
      box-shadow: initial;
    }
  }
`;

export const LeftSide = styled.div`
  border-radius: 24px;
  width: 25%;
  min-width: 327px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: unset;
      min-width: unset;
    }
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      width: unset;
      min-width: 300px;
    }
  }
`;

export const RightSideContainer = styled.div`
  border-radius: 24px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const SaveButton = styled(SaveButtonWrapper)`
  margin-right: unset;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px !important;

  > div {
    width: 100%;
    max-width: none;
    padding: 0px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 16px;
  gap: 25px;
  box-shadow: 2px 2px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.boxShadow, 0.3)};
  height: auto;
  background-color: ${(props) => props?.theme?.colors?.white};
  max-height: 100%;
  overflow-y: auto;
`;
