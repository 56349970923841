import { clientFormikParams } from "constants/entityFormikParams/clientFormikParams";
import {
  getAppointmentEnabled,
  getAppointmentStartTime,
  getCancellationTimeframe,
  getDescription,
  getWorkingDaysHours,
} from "features/clients/clientSlice";
import { useSelector } from "react-redux";

export const clientParams = (client) => {
  const changedDescription = useSelector(getDescription);
  const workingDaysHours = useSelector(getWorkingDaysHours);
  const appointmentEnabled = useSelector(getAppointmentEnabled);
  const appointmentStartTime = useSelector(getAppointmentStartTime);
  const cancellationTimeframe = useSelector(getCancellationTimeframe);
  return {
    [clientFormikParams.registrationName]: client?.registrationName ?? "",
    [clientFormikParams.description]: changedDescription ?? "",
    [clientFormikParams.country]: client?.country ?? "",
    [clientFormikParams.city]: client?.city ?? "",
    [clientFormikParams.address]: client?.address ?? "",
    [clientFormikParams.webAddress]: client?.webAddress ?? "",
    [clientFormikParams.countryPhoneCode]:
      { id: client?.country, name: client?.phoneNumbers[0]?.phoneNumber.slice(0, 4) } ??
      null,
    [clientFormikParams.phoneNumber]:
      client?.phoneNumbers[0]?.phoneNumber.slice(4) ?? "",
    [clientFormikParams.primaryServiceCategoryName]:
      client?.primaryServiceCategory?.name ?? "",
    [clientFormikParams.primaryServiceCategoryId]:
      client?.primaryServiceCategory?.id ?? "",
    [clientFormikParams.publicAppointmentEnabled]: appointmentEnabled ?? "",
    [clientFormikParams.appointmentStartTimeInMinutes]:
      appointmentStartTime ?? "",
    [clientFormikParams.workingDaysHours]: workingDaysHours ?? [],
    [clientFormikParams.timeBeforeCancellationAllowedInHours]:
      cancellationTimeframe,
  };
};

export const FormikParams = {
  registrationName: "",
  description: "",
  country: "",
  city: "",
  address: "",
  countryPhoneCode: "",
  phoneNumber: "",
  primaryServiceCategoryName: "",
  primaryServiceCategoryId: "",
  publicAppointmentEnabled: "",
  workingDaysHours: [],
  appointmentStartTimeInMinutes: "",
};
