import styled from "styled-components";

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 30px;
  height: 24px;
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.statusActiveBackground
      : theme.colors.statusInactiveBackground};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.statusActiveText : theme.colors.statusInactiveText};
`;

export const StatusDot = styled.span`
  height: 10px;
  width: 10px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.statusActiveText : theme.colors.statusInactiveText};
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 8px;
      height: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 8px;
      height: 8px;
    }
  }
`;
