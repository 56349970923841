import styled from "styled-components";
import { TimePicker } from "@mui/x-date-pickers";
import { Label } from "@mui/icons-material";

export const CustomTimePicker = styled(TimePicker)`
  & .MuiOutlinedInput-root {
    border-radius: 12px;
    z-index: 1;
    border-color: ${(props) => props?.theme?.colors?.black};
    height: 40px;
    width: ${(props) => (props?.wishList ? "100%" : "412px")};
  }
  &.css-wb57ya-MuiFormControl-root-MuiTextField-root {
    max-height: 40px;
  }
`;

export const LabelStyled = styled(Label)`
  display: flex;
  margin-right: 10px;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props?.wishList ? "0px" : "16px")};
`;
