import React from "react";
import PropTypes from "prop-types";
import {
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledTypography,
  LastCell,
  StyledTableRowWorkingHours,
  LastCellRowWorkingHours,
} from "./TableBodyContainer.styled";
import { TEXTVARIANT } from "constants/textConstants";
import { Tooltip } from "@mui/material";
import tooltipConstants from "constants/tooltipConstants";
import { NoRowsBody } from "components/ClientServiceContent/ClientServiceTable/TableBodyContainer.styled";
import useDevice from "hooks/useDevice";

const TableBodyContainer = (props) => {
  const { isDesktop } = useDevice();

  const RowComponent =
    props.tableAlignmentLeft && isDesktop
      ? StyledTableRowWorkingHours
      : StyledTableRow;
  const LastCellComponent =
    props.tableAlignmentLeft && isDesktop ? LastCellRowWorkingHours : LastCell;

  const numberOfHeaders = props.tableHeaders.length;
  const isSingleColumn = props.tableHeaders.length === 1;

  const determineAlignment = (col, formattedData) => {
    if (col?.alignment) {
      return col.alignment;
    }
    if (typeof formattedData === "number" && !col?.field === "type") {
      return "center";
    }
    return "flex-start";
  };

  const getNestedValue = (obj, path) => {
    return path?.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <StyledTableBody
      $setMinHeight={props?.setMinHeight}
      $halfSizeHeight={props?.halfSizeHeight}
    >
      {props?.rows?.map((row, index) => {
        const isNewNotification = index < props.newNotificationsCount;
        return (
          <RowComponent
            isClientInfoTableWorkingHours={props?.isClientInfoTableWorkingHours}
            key={row?.id}
            status={row?.status}
            isNewNotification={isNewNotification}
          >
            {props?.tableHeaders?.map((col, colIndex) => {
              let data = getNestedValue(row, col.field);
              const formattedData = col?.valueFormatter?.(data, row) ?? data;
              const isLastColumn = colIndex === props.tableHeaders.length - 1;
              const alignment = determineAlignment(col, formattedData);
              const isSecondCell = props.tableAlignmentLeft && colIndex === 1;
              const cellRendered = col?.cellRenderer ? (
                col.cellRenderer({
                  modal: props?.modal,
                  name: formattedData,
                  onClickEdit: () => props.rowBtnClick(row),
                  onClickDelete: () => props?.deleteBtnClick(row.id),
                  row,
                  isSuperAdmin: props?.isSuperAdmin,
                })
              ) : formattedData?.length > props?.numberOfCharactersPerCell ? (
                <Tooltip title={formattedData} placement={tooltipConstants.TOP}>
                  <StyledTypography
                    isClientInfoTableWorkingHours={
                      props?.isClientInfoTableWorkingHours
                    }
                    variant={TEXTVARIANT.H5}
                  >
                    {formattedData.slice(
                      0,
                      props?.numberOfCharactersPerCell - 3
                    ) + "..."}
                  </StyledTypography>
                </Tooltip>
              ) : (
                <StyledTypography
                  isClientInfoTableWorkingHours={
                    props?.isClientInfoTableWorkingHours
                  }
                  status={row?.status}
                  isSecondCell={isSecondCell}
                  variant={TEXTVARIANT.H5}
                >
                  {formattedData}
                </StyledTypography>
              );
              return isLastColumn ? (
                <LastCellComponent
                  key={col?.field}
                  isSingleColumn={isSingleColumn}
                  isLastCell={isLastColumn}
                >
                  {cellRendered}
                </LastCellComponent>
              ) : (
                <StyledTableCell
                  key={col?.field}
                  minWidthValue={numberOfHeaders}
                  isLastCell={isLastColumn}
                  tableAlignmentLeft={props.tableAlignmentLeft}
                  alignment={alignment}
                >
                  {cellRendered}
                </StyledTableCell>
              );
            })}
          </RowComponent>
        );
      })}
      {props?.rows?.length === 0 && <NoRowsBody />}
    </StyledTableBody>
  );
};

TableBodyContainer.propTypes = {
  modal: PropTypes.bool,
  isClientInfoTableWorkingHours: PropTypes.bool,
  rows: PropTypes.any,
  rowBtnClick: PropTypes.func,
  deleteBtnClick: PropTypes.func,
  tableHeaders: PropTypes.any,
  theme: PropTypes.any,
  isSuperAdmin: PropTypes.bool,
  tableAlignmentLeft: PropTypes.bool,
  setMinHeight: PropTypes.bool,
  halfSizeHeight: PropTypes.bool,
  numberOfCharactersPerCell: PropTypes.number,
  newNotificationsCount: PropTypes.number,
};

TableBodyContainer.defaultProps = {
  tableAlignmentLeft: false,
  setMinHeight: true,
  newNotificationsCount: 0,
  numberOfCharactersPerCell: 50,
};

export default TableBodyContainer;
