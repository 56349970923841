import styled from "styled-components";
import { ReactComponent as QuestionMarkIcon } from "assets/images/svg/question-mark-icon.svg";
import {
  ActionButton,
  CancelActionButton,
  LowerContainer,
  UpperContainer,
  Title,
} from "../LicenseAddEditModal/LicenceAddEditModal.styled";

export const UpperContainerWrapper = styled(UpperContainer)`
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const QuestionMarkIconStyled = styled(QuestionMarkIcon)`
  height: 56px;
  width: 56px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 24px;
      width: 24px;
    }
  }
`;

export const CancelButton = styled(CancelActionButton)`
  border-color: ${(props) => props?.theme?.colors?.text3};
  font-size: 14px;
`;

export const DeleteButton = styled(ActionButton)`
  font-size: 14px;
  background-color: ${(props) => props?.theme?.colors?.errorColor};
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.errorColorHover};
  }
`;

export const LowerContainerWrapper = styled(LowerContainer)`
  flex-direction: row;
  gap: 10px;
  height: 97px;
  padding: 10px;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 73px;
    }
  }
`;

export const TitleStyled = styled(Title)`
  text-align: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
    }
  }
`;
