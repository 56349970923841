import styled from "styled-components";
import { Select } from "@mui/material";
import { ReactComponent as DownArrow } from "assets/images/svg/downArrow.svg";
import { hexToRGB } from "util/colorHelper";

export const CustomSelect = styled(Select)`
  width: ${(props) => props.isLoginPage ? "127px" : "103px"};
  height: ${(props) => props.isLoginPage ? "48px" : "28px"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: ${(props) =>
    props.isLoginPage
      ? `0px 1px 4px 0px ${hexToRGB(props?.theme?.colors?.black, 0.1)}`
      : "none"};

  &.MuiOutlinedInput-root {
    & fieldset {
      border-color: ${(props) => props?.theme?.colors?.white};
      border-width: 1px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 30px;
      width: 86px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 30px;
      width: 94px;
    }
  }
`;

export const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 10px;
      gap: 3px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 10px;
      gap: 3px;
    }
  }
`;

export const FlagIcon = styled.img`
  width: 26.67px;
  height: 20px;
  border-radius: 2px;
  display: block;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 20px;
      width: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 20px;
      width: 20px;
    }
  }
`;

export const DownArrowStyled = styled(DownArrow)`
  color: ${(props) => props?.theme?.colors?.placeholderText};
  fill: ${(props) => props?.theme?.colors?.white};
  stroke: ${(props) => props?.theme?.colors?.placeholderText};
  margin-top: -3px;
  height: 24px;
  width: 24px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 12px;
      width: 12px;
      margin-top: 1px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-top: 1px;
      height: 12px;
      width: 12px;
    }
  }
`;
