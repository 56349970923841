import React from "react";
import { RightContentWrapper } from "./RightInfoContent.styled";
import { ClientControls } from "./ClientControls";
import { AccordionWrapper } from "./AccordionWrapper";
import PropTypes from "prop-types";
import useDevice from "hooks/useDevice";

export const RightInfoContent = (props) => {
  const { isMobile, isTablet } = useDevice();
  return (
    <RightContentWrapper>
      {!isTablet && !isMobile && (
        <ClientControls clientId={props?.clientId} isActive={props?.isActive} />
      )}
      <AccordionWrapper
        description={props?.description}
        clientId={props?.clientId}
        workingDaysHours={props?.workingDaysHours}
        publicAppointmentEnabled={props?.publicAppointmentEnabled}
        appointmentStartTimeInMinutes={props?.appointmentStartTimeInMinutes}
        timeBeforeCancellationAllowedInHours={
          props?.timeBeforeCancellationAllowedInHours
        }
      />
    </RightContentWrapper>
  );
};

RightInfoContent.propTypes = {
  description: PropTypes.string,
  clientId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  workingDaysHours: PropTypes.array,
  publicAppointmentEnabled: PropTypes.number,
  appointmentStartTimeInMinutes: PropTypes.bool,
  timeBeforeCancellationAllowedInHours: PropTypes.number,
};
