import styled from "styled-components";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";

export const GalleryContentMultiCarousel = styled(MultiCarousel)`
  flex: 1 1 auto;

  .react-multi-carousel-track {
    display: flex;
    align-items: center;
    height: 275px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      && {
        height: 134px;
      }
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 134px;
    }
  }
`;

export const GalleryContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  border-radius: 12px;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      box-shadow: none;
    }
  }
`;

export const LeftArrowContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
`;

export const RightArrowContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
`;

export const LeftArrowStyled = styled(LeftArrow)``;

export const RightArrowStyled = styled(RightArrow)``;

export const ArrowButton = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
`;
