import React from "react";
import { ClientInformationStyled } from "./ClientInformation.styled";
import PropTypes from "prop-types";
import { LeftInfoContent } from "./LeftInfoContent/LeftInfoContent";
import { RightInfoContent } from "./RightInfoContent/RightInfoContent";
import { ClientControls } from "./RightInfoContent/ClientControls";
import useDevice from "hooks/useDevice";

export const ClientInformation = (props) => {
  const { isMobile, isTablet } = useDevice();
  return (
    <>
    {(isTablet || isMobile) && (
      <ClientControls
        clientId={props?.client?.id}
        isActive={props?.client?.isActive}
      />
    )}
    <ClientInformationStyled>
      <LeftInfoContent
        client={props?.client}
        logoId={props?.client?.logoId}
      ></LeftInfoContent>
      <RightInfoContent
        description={props?.client?.description}
        isActive={props?.client?.isActive}
        clientId={props?.client?.id}
        workingDaysHours={props?.client?.workingDaysHours}
        publicAppointmentEnabled={props?.client?.publicAppointmentEnabled}
        appointmentStartTimeInMinutes={
          props?.client?.appointmentStartTimeInMinutes
        }
        timeBeforeCancellationAllowedInHours={
          props?.client?.timeBeforeCancellationAllowedInHours
        }
      ></RightInfoContent>
    </ClientInformationStyled>
    </>
  );
};

ClientInformation.propTypes = {
  client: PropTypes.any,
};
export default ClientInformation;
