import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { VARIANT } from "constants/buttonConstants";
import { unsetShownModal } from "features/modal/modalSlice";
import { useDispatch } from "react-redux";
import {
  ActionButton,
  ActionsContainer,
  CancelActionButton,
  LowerContainer,
  Modal,
  Title,
  UpperContainer,
} from "./RemoveAuthorizedUserModal.styled";
import { TitleContainer } from "components/LandingPageContent/LandingPageContent.styled";
import { CloseIcon } from "components/SuperAdmin/ServiceCategoryEditAddModal/ServiceCategoryEditAddModal.styled";
import { useDeauthorizeUserMutation } from "features/clients/clientsApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";

const RemoveAuthorizedUserModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [removeAuthorizedUser] = useDeauthorizeUserMutation();
  const handleClick = async () => {
    const response = await removeAuthorizedUser({
      clientId: props?.clientId,
      userId: props?.user?.id,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("clientPanel.toastMessageDeauthorize"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <UpperContainer>
        <CloseIcon />
        <TitleContainer>
          <Title>{t("clientPanel.deauthorizeUserQuestion")}</Title>
        </TitleContainer>
      </UpperContainer>
      <LowerContainer>
        <ActionsContainer>
          <CancelActionButton
            variant={VARIANT.OUTLINED}
            onClick={() => dispatch(unsetShownModal())}
            value={t("common.cancel")}
          />
          <ActionButton onClick={handleClick} value={t("common.yes")} />
        </ActionsContainer>
      </LowerContainer>
    </Modal>
  );
};

RemoveAuthorizedUserModal.propTypes = {
  user: PropTypes.any,
  clientId: PropTypes.string,
};

export default RemoveAuthorizedUserModal;
