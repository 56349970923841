import styled from "styled-components";
import { ReactComponent as ClockIcon } from "assets/images/svg/clock-icon.svg";
import { ReactComponent as WalletIcon } from "assets/images/svg/wallet-icon.svg";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { ReactComponent as ServiceIcon } from "assets/images/svg/default-service-image.svg";
import { NavLink } from "react-router-dom";

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 593px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: fit-content;
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: fit-content;
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const RowContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 233px);

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 12px;
      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fill, 185px);
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      justify-content: space-evenly;
      gap: 12px;
      grid-template-columns: repeat(auto-fill, 185px);
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
      grid-template-columns: repeat(auto-fill, 165px);
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      grid-template-columns: repeat(auto-fill, 140px);
    }
  }
`;

export const ItemContainer = styled(NavLink)`
  display: flex;
  flex-direction: column;
  height: 285px;
  width: 233px;
  border-radius: 16px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
  position: relative;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 216px;
      width: 185px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 216px;
      width: 185px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 186px;
      width: 165px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      height: 172px;
      width: 140px;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  gap: 10px;
  height: 144px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 112px;
      width: 185px;
      padding: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 112px;
      width: 185px;
      padding: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 98px;
      width: 165px;
      padding: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      height: 94px;
      width: 150px;
    }
  }
`;

export const ServiceIconStyled = styled.img`
  height: 141px;
  width: 233px;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 104px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 104px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 84px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      height: 80px;
    }
  }
`;

export const ServiceIconDefaultStyled = styled(ServiceIcon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 141px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 104px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 104px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 84px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      height: 80px;
    }
  }
`;

export const ServiceNameText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.textBlack};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 14px;
      line-height: 14px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 14px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      font-size: 12px;
      line-height: 12px;
      height: 14px;
    }
  }
`;

export const ServiceDurationAndPriceText = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props?.theme?.colors?.darkBlue};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 14px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      font-size: 12px;
    }
  }
`;

export const DurationAndPriceContainer = styled.div`
  display: flex;
  gap: 15px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      gap: 4px;
    }
  }
`;

export const DurationContainer = styled.div`
  display: flex;
  gap: 5px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 2px;
    }
  }
`;

export const ClockIconStyled = styled(ClockIcon)`
  height: 16px;
  width: 16px;
  align-self: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 14px;
      height: 14px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      width: 12px;
      height: 12px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const WalletIconStyled = styled(WalletIcon)`
  height: 16px;
  width: 16px;
  align-self: center;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 14px;
      height: 14px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ScheduleButton = styled(Button)`
  width: 64px;
  height: 24px;
  font-weight: 700;
  font-size: ${(props) =>
    props.theme.fonts[props.language]?.buttonFontSize || "16px"};
  line-height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 8px;
  background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  color: ${(props) => props?.theme?.colors?.white};
  :hover {
    background-color: ${(props) => props?.theme?.colors?.updateTableButton};
  }
`;
