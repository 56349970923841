import styled from "styled-components";
import { ReactComponent as QuestionMarkIcon } from "assets/images/svg/question-mark-icon.svg";
import { hexToRGB } from "util/colorHelper";
import {
  CancelActionButton,
  LowerContainer,
  Title,
  UpperContainer,
} from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal.styled";

export const Modal = styled.div`
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  position: relative;
  background-color: white;
  border-radius: 24px;
  width: 460px;
  height: fit-content;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

export const UpperContainerWrapper = styled(UpperContainer)`
  height: fit-content;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-around;
`;

export const QuestionMarkIconStyled = styled(QuestionMarkIcon)`
  height: 56px;
  width: 56px;
`;

export const CancelButton = styled(CancelActionButton)`
  border-color: ${(props) => props?.theme?.colors?.text3};
  font-size: 14px;
`;
export const LowerContainerWrapper = styled(LowerContainer)`
  flex-direction: row;
  gap: 10px;
  height: fit-content;
  padding: 10px;
  align-items: center;
`;

export const TitleStyled = styled(Title)`
  text-align: center;
`;
