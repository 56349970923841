import styled from "styled-components";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";

export const StyledTableHead = styled(TableHead)`
  display: flex;
  flex-direction: row;
`;

export const StyledTableRow = styled(TableRow)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 15px;
  flex: ${(props) =>
    props.$tableAlignmentLeft
      ? "none"
      : props.$isLastColumn
      ? "0 0 133px"
      : "1"};
  width: ${(props) =>
    props.$tableAlignmentLeft
      ? "250px"
      : props.$isLastColumn
      ? "133px"
      : `calc((100% - 133px - ${15 * props.$numberOfHeaders}px) / ${
          props.$numberOfHeaders - 1
        })`};
  border: none;
`;

export const TableTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const StyledTableRowWorkingHours = styled(TableRow)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
`;
