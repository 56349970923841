import React, { useEffect, useState } from "react";
import { languageOptions } from "constants/languages";
import {
  CustomSelect,
  DownArrowStyled,
  FlagIcon,
  LanguageOption,
} from "./LanguageSelector.styled";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import {
  useChangeLanguageMutation,
  useGetUserQuery,
} from "features/user/usersApiSlice";
import PropTypes from "prop-types";

const LanguageSelector = (props) => {
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(null);
  const user = useSelector(selectCurrentUser);
  const [userData, setUserData] = useState(null);
  const [changeLanguage] = useChangeLanguageMutation();
  const { data } = userData ? { data: userData } : { data: null };
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const response = await useGetUserQuery(user.id);
        setUserData(response.data);
      };

      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    const userLanguage = data?.user?.languageCultureName;
    const savedLanguage = localStorage.getItem("Language");

    const initialLanguage = userLanguage || savedLanguage || i18n.language;
    const selectedItem = languageOptions.find(
      (item) => item.value === initialLanguage
    );

    if (selectedItem) {
      setSelectedValue(selectedItem);
      i18n.changeLanguage(selectedItem.value);
    }
  }, []);

  const handleChange = async (event) => {
    const selectedLanguage = event.target.value;
    const selectedItem = languageOptions.find(
      (item) => item.value === selectedLanguage
    );

    setSelectedValue(selectedItem);
    localStorage.setItem("Language", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);

    if (!user) return;

    await changeLanguage({
      userId: user.id,
    });
  };

  return (
    <CustomSelect
      isLoginPage={props.isLoginPage}
      value={selectedValue?.value || ""}
      displayEmpty
      onChange={handleChange}
      renderValue={(selected) => {
        const item = languageOptions.find((opt) => opt.value === selected);
        return item ? (
          <LanguageOption>
            <FlagIcon as={item.flag} />
            {t(item.label)}
          </LanguageOption>
        ) : (
          ""
        );
      }}
      IconComponent={DownArrowStyled}
    >
      {languageOptions.map((item) => (
        <MenuItem key={item.id} value={item.value}>
          <LanguageOption>
            <FlagIcon as={item.flag} />
            {t(item.label)}
          </LanguageOption>
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

LanguageSelector.propTypes = {
  isLoginPage: PropTypes.bool,
};

LanguageSelector.defaultProps = {
  isLoginPage: false,
};

export default LanguageSelector;
