import React, { useRef } from "react";
import { dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import PropTypes from "prop-types";
import enUS from "date-fns/locale/en-US";
import srLatn from "date-fns/locale/sr-Latn";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarComponentStyled } from "./CalendarComponent.styled";
import CustomToolbar from "./CustomToolbar";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import { useTranslation } from "react-i18next";
import primary from "themes/primaryTheme/primaryTheme";
import { calendarFormats } from "util/dateHelpers";
import { getRandomColor } from "util/colorHelper";
import { useDispatch } from "react-redux";
import {
  setCalendarInformationAndCancellationModal,
  setCalendarResourcesInformationAndCancellationModal,
} from "features/modal/modalSlice";
import { mapCalendarEvents } from "util/mapCalendarEvents";
import { appointmentStatuses } from "constants/filters/statusConstants";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";
import useDevice from "hooks/useDevice";

const MyCalendar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let lang = authScopeStringGetHelper(LANGUAGE);
  const { isMobile } = useDevice();

  const srLatnCustom = {
    ...srLatn,
    localize: {
      ...srLatn.localize,
      day: (n, options) =>
        srLatn.localize.day(n, options).charAt(0).toUpperCase() +
        srLatn.localize.day(n, options).slice(1),
      month: (n, options) =>
        srLatn.localize.month(n, options).charAt(0).toUpperCase() +
        srLatn.localize.month(n, options).slice(1),
    },
  };

  const locales = {
    "en-US": enUS,
    "sr-Latn": srLatnCustom,
  };

  const startOfWeekWithMonday = (date) =>
    startOfWeek(date, { weekStartsOn: 1, locale: locales[lang] });

  const localizer = dateFnsLocalizer({
    format: (date, formatStr) =>
      format(date, formatStr, { locale: locales[lang] }),
    parse: (str, formatStr) =>
      parse(str, formatStr, new Date(), { locale: locales[lang] }),
    startOfWeek: startOfWeekWithMonday,
    getDay,
    locales,
  });

  const formats = calendarFormats(locales[lang]);
  const serviceColorsRef = useRef(new Map());
  const mappedEvents =
    props?.appointments
      ?.filter(
        (appointment) =>
          appointment.status !== APPOINTMENT_STATUS.CANCEL_RESOURCE_ALLOCATION
      )
      ?.map((appointment) => ({
        title:
          appointment?.status === APPOINTMENT_STATUS.RESOURCE_ALLOCATION
            ? t(appointmentStatuses[appointment?.status].name)
            : appointment?.clientService?.name,
        start: mapCalendarEvents(appointment.fromTimeUtc),
        end: mapCalendarEvents(appointment.toTimeUtc),
        price: appointment?.clientService?.price,
        appointmentStatus: appointment?.status,
        attributes: appointment?.chosenAttributes,
        appointmentId: appointment?.id,
        clientId: appointment?.client?.id,
        userId: appointment?.user?.id,
        serviceId: appointment?.clientService?.id,
        resources:
          appointment?.allocatedResources?.map(
            (resource) => resource.resource
          ) || [],
        recurringAppointmentId: appointment?.recurringAppointmentId,
        repeatInterval: appointment?.repeatInterval,
        durationInWeeks: appointment?.durationInWeeks,
        userFirstName: appointment?.user?.firstName,
        userLastName: appointment?.user?.lastName,
        userPhoneNumber: appointment?.user?.phoneNumber,
        note: appointment?.note,
      })) || [];

  const eventPropGetter = (event) => {
    if (!serviceColorsRef.current.has(event.serviceId)) {
      serviceColorsRef.current.set(event.serviceId, getRandomColor());
    }

    let backgroundColor;

    if (event.appointmentStatus === APPOINTMENT_STATUS.RESOURCE_ALLOCATION) {
      backgroundColor = primary.colors.statusTextOrange;
    } else if (event.appointmentStatus === APPOINTMENT_STATUS.PENDING) {
      backgroundColor = primary.colors.greenColor;
    } else {
      backgroundColor = serviceColorsRef.current.get(event.serviceId);
    }

    return {
      style: {
        backgroundColor,
        color: primary.colors.black,
        borderRadius: "4px",
        border: "none",
        fontSize: isMobile ? "10px" : "initial",
        lineHeight: isMobile ? "10px" : "initial",
      },
    };
  };

  const messages = {
    date: t("calendar.date"),
    time: t("calendar.time"),
    event: t("calendar.event"),
    noEventsInRange: t("calendar.noEventsInRange"),
  };

  const handleSelectEvent = (event) => {
    const action =
      event.appointmentStatus === APPOINTMENT_STATUS.RESOURCE_ALLOCATION
        ? setCalendarResourcesInformationAndCancellationModal({
            resource: event,
          })
        : setCalendarInformationAndCancellationModal({
            appointment: event,
          });

    dispatch(action);
  };

  return (
    <CalendarComponentStyled
      localizer={localizer}
      culture={lang}
      events={mappedEvents}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      defaultView="month"
      eventPropGetter={eventPropGetter}
      components={{
        toolbar: CustomToolbar,
      }}
      dayPropGetter={(date) => {
        const today = new Date();
        if (date.toDateString() === today.toDateString()) {
          return { style: { backgroundColor: "transparent" } };
        }
        return {};
      }}
      formats={formats}
      messages={messages}
      onSelectEvent={handleSelectEvent}
    />
  );
};

MyCalendar.propTypes = {
  appointments: PropTypes.any,
};
export default MyCalendar;
