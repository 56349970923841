export const AUTH_CALLBACK_PAGE = "/api/auth/:provider/callback";

export const PAGES = {
  BASE: {
    route: "/home",
    hideHeader: true,
    title: "pages.home",
    sidebarTitle: "pages.landing",
  },
  HOME: {
    route: "/home/*",
    title: "pages.home",
  },
  ADMIN: {
    route: "/admin",
  },
  SERVICES: {
    route: "/Services",
    title: "pages.services",
  },
  SUPERADMIN_CLIENTS: {
    route: "/admin/clients",
    title: "pages.clients",
  },
  CLIENTS: {
    route: "/home/clients",
    title: "pages.clients",
  },
  CLIENT: {
    route: "/home/clients/:clientId",
    titleToRender: "clientId",
    titleRenderer: (name) => `${name}`,
    title: "pages.client",
  },
  CLIENTS_DETAILS: {
    title: "pages.client",
    titleToRender: "clientId",
    titleRenderer: (name) => `${name}`,
    route: "/admin/clients/:clientId/*",
  },
  CLIENTS_DETAILS_INFO: {
    title: "pages.info",
    route: "/admin/clients/:clientId/info",
    nestedRoute: "info",
  },
  CLIENTS_DETAILS_RESOURCES: {
    title: "pages.resources",
    route: "/admin/clients/:clientId/resources",
    nestedRoute: "resources",
  },
  CLIENTS_DETAILS_SERVICES: {
    title: "clientService.services",
    route: "/admin/clients/:clientId/services",
    nestedRoute: "services",
  },
  CLIENTS_DETAILS_CALENDAR: {
    title: "pages.calendar",
    route: "/admin/clients/:clientId/calendar",
    nestedRoute: "calendar",
  },
  CLIENTS_DETAILS_LICENSES: {
    title: "pages.licenses",
    route: "/admin/clients/:clientId/licenses",
    nestedRoute: "licenses",
  },
  CLIENT_AUTHORIZED_LIST: {
    title: "pages.authorizedList",
    route: "/admin/clients/:clientId/authorized-list",
    nestedRoute: "authorized-list",
  },
  USERS: {
    route: "/admin/users",
    title: "pages.users",
  },
  USER: {
    title: "pages.users",
    titleToRender: "userId",
    titleRenderer: (email) => `${email}`,
    route: "/admin/users/:userId",
  },
  MY_ACCOUNT: {
    title: "pages.myAccount",
    route: "/my-account",
  },
  REGISTER_USER: {
    route: "/register/user",
    title: "pages.register",
    isAuth: true,
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/user/first-step",
        title: "pages.register.nestedRoutes.firstStep",
      },
      SECOND_STEP: {
        route: "/register/user/second-step",
      },
    },
  },
  REGISTER_CLIENT: {
    route: "/register/client",
    title: "pages.register",
    isAuth: true,
    nestedRoutes: {
      FIRST_STEP: {
        route: "/register/client/first-step",
        title: "pages.register.nestedRoutes.firstStep",
      },
      SECOND_STEP: {
        route: "/register/client/second-step",
      },
      THIRD_STEP: {
        route: "/register/client/third-step",
      },
    },
  },
  CLIENT_SERVICE: {
    route: "/admin/clients/:clientId/services/:clientServiceId",
    title: "pages.clientService",
    titleToRender: "clientServiceId",
    titleRenderer: (name) =>
      `${name ? name : "clientService.newClientService"}`,
    nestedRoute: "",
  },
  LOGIN: {
    route: "/login/*",
    title: "pages.login",
    isAuth: true,
  },
  FORGOT_PASSWORD: {
    route: "/forgot-password/*",
    title: "pages.forgotPassword",
  },
  RESET_PASSWORD: {
    route: "/password-change",
    title: "pages.resetPassword",
  },
  ERROR: {
    route: "/error-page",
    title: "pages.error",
  },
  NOT_FOUND: {
    route: "/not-found",
    title: "pages.notFound",
  },
  PROFILE: {
    route: "/profile",
    title: "pages.profile",
  },
  SETTINGS: {
    route: "/settings",
    title: "pages.settings",
  },
  DASHBOARD: {
    route: "/admin/dashboard",
    title: "pages.dashboard",
  },
  MIDDLEPAGE: {
    route: "/middlepage",
    title: "pages.middlepage",
    isAuth: true,
  },
  SUPERADMIN: {
    route: "/superadmin",
    title: "pages.superadmin",
  },
  SERVICE_CATEGORIES: {
    route: "/admin/serviceCategories",
    title: "pages.serviceCategories",
  },
  CLIENT_LICENSES: {
    route: "/admin/clientLicense",
    title: "pages.serviceCategories",
  },
  ABOUT: {
    route: "/about",
    title: "pages.about",
  },
  HELP: {
    route: "/help",
    title: "pages.help",
  },
  CONTACT: {
    route: "/contact",
    title: "pages.contact",
  },
  APPOINTMENTS: {
    route: "/appointments",
    title: "pages.myAppointments",
  },
  SCHEDULE_APPOINTMENT: {
    route:
      "/home/clients/:clientId/serivce/:clientServiceId/schedule-appointment",
    title: "pages.scheduling_appointment",
    titleRenderer: (name) => `${name}`,
  },
  MESSAGES: {
    route: "/messages",
    title: "pages.messages",
  },
};
