import styled from "styled-components";
import { Accordion, Box, Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const RightContentWrapper = styled(Box)`
  flex-grow: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  border-radius: 24px;
  width: 50%;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100%;
    }
  }
`;

export const ClientControlsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  border-radius: 24px;
  background-color: ${(props) => props?.theme?.colors?.white};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      box-shadow: none;
      border-radius: 0px;
      padding: 8px 0px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ userPanel, checked, theme }) =>
    userPanel
      ? theme.colors.textColor2
      : checked
      ? theme.colors.greenCheck
      : theme.colors.errorColor};

  margin-left: 16px;
  width: 126px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: auto;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const Controls = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0px 5px 0px 5px;
`;

export const AccordionWrapperStyled = styled(Box)`
  padding: 16px;
  gap: 24px;
  border-radius: 24px;
  background-color: ${(props) => props?.theme?.colors?.white};
  display: flex;
  flex-direction: column;

  & .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    position: static;
  }
`;

export const AccordionWrapper = styled(Accordion)`
  box-shadow: 2px 2px 8px 0px
    ${(props) =>
      hexToRGB(props?.theme?.colors?.uncompletedRegistrationBackground, 0.4)};
  gap: 0px;
  border-radius: 24px;
  padding: 4px;
  background-color: ${(props) =>
    props?.theme?.colors?.selectCountryPhoneCodeBackground};

  &::before {
    display: none;
  }
  :last-of-type {
    border-radius: 24px;
  }
  :first-of-type {
    border-radius: 24px;
  }

  .MuiAccordionSummary-content {
    font-size: 24px;
    font-weight: 700;
  }
`;
