import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, MenuItem } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import {
  BelowFieldLabel,
  CustomSelectFieldStyled,
} from "./RecurringIntervalField.styled";
import { useTranslation } from "react-i18next";
import { recurringTypes } from "constants/recurringConstants";

const RecurringIntervalField = (props) => {
  const { t } = useTranslation();
  const intervalsInMonths = [1, 3, 6, 12];
  const [interval, setInterval] = useState(1);

  const handleChange = (event) => {
    const value = event.target.value;
    props?.onChange(value);
    setInterval(value);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props?.label)} />
      <CustomSelectFieldStyled defaultValue={1} onChange={handleChange}>
        {intervalsInMonths?.map((months) => (
          <MenuItem key={months} value={months}>
            {months}
          </MenuItem>
        ))}
      </CustomSelectFieldStyled>
      <BelowFieldLabel>
        {t("scheduleAppointment.intervalFieldBelowMessage", {
          repeatTime: t(
            recurringTypes[props?.recurring].name
          ).toLocaleLowerCase(),
          interval,
        })}
      </BelowFieldLabel>
    </Box>
  );
};

RecurringIntervalField.propTypes = {
  recurring: PropTypes.number,
  onChange: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default RecurringIntervalField;
