import { ReactComponent as srFlag } from "assets/images/languages/sr.svg";
import { ReactComponent as enFlag } from "assets/images/languages/en.svg";

export const LanguageCodes = {
  SR: "sr-Latn",
  EN: "en-US",
};

export const languageOptions = [
  { id: 1, value: LanguageCodes.SR, label: "common.sr", flag: srFlag },
  { id: 2, value: LanguageCodes.EN, label: "common.en", flag: enFlag },
];
