import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as OfflineImg } from "../../assets/images/svg/no-wifi.svg";
import Button from "components/Button/Button";

export const OfflineContainer = styled(Box)`
  border-radius: 12px;
  padding: 40px 50px;
  border: 1px solid ${(props) => props?.theme?.colors?.black};
  background: ${(props) => props?.theme?.colors?.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 30px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 30px;
    }
  }
`;

export const OfflineImageContainer = styled(Box)`
  padding: 0px;
`;

export const OfflineImage = styled(OfflineImg)`
  width: 50px;
  height: 50px;
`;

export const Title = styled(Box)`
  white-space: nowrap;
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 10px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 16px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

export const Description = styled(Box)`
  color: ${(props) => props?.theme?.colors?.black};
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  max-width: 350px;
  padding-bottom: 10px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 18px;
      max-width: 250px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 18px;
      max-width: 250px;
    }
  }
`;

export const CloseButton = styled(Button)`
  height: 40px;
  width: 170px;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
  font-size: 14px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 12px;
      height: 35px;
      width: 120px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 12px;
      height: 35px;
      width: 120px;
    }
  }
`;
