import React from "react";
import PropTypes from "prop-types";
import { StyledTypography } from "./TableBodyContainer.styled";
import useDevice from "hooks/useDevice";
import { useTranslation } from "react-i18next";

const ClientLicensesRenderer = (props) => {
  const { isDesktop } = useDevice();
  const { t } = useTranslation();
  const valueToRender = props?.value;
  const date = new Date(valueToRender);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const dateToRender = isDesktop
    ? `${day}. ${month}. ${year}`
    : `${t(props?.colName)} ${month}. ${year}`;
  return (
    <StyledTypography>
      {props?.text ? t(valueToRender) : dateToRender}
    </StyledTypography>
  );
};

ClientLicensesRenderer.propTypes = {
  text: PropTypes.bool,
  value: PropTypes.any,
  colName: PropTypes.string,
};

export default ClientLicensesRenderer;
