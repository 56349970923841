import React from "react";
import PropTypes from "prop-types";
import { AddNewItemContainer } from "./AddNewItemButton.styled";
import { ReactComponent as PlusIcon } from "assets/images/svg/plus-icon.svg";
import { VARIANT } from "constants/buttonConstants";
import { NavLink } from "react-router-dom";

export const AddNewItemButton = (props) => {
  return (
    <NavLink to={props?.goTo}>
      <AddNewItemContainer
        variant={VARIANT.CONTAINED}
        onClick={props.onClick}
        value={props.value}
        startIcon={<PlusIcon />}
      ></AddNewItemContainer>
    </NavLink>
  );
};

AddNewItemButton.propTypes = {
  goTo: PropTypes.any,
  onClick: PropTypes.func,
  value: PropTypes.string,
};

export default AddNewItemButton;
