import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";
import { ReactComponent as DetailsIcon } from "assets/images/svg/details-icon.svg";
import { NavLink } from "react-router-dom";
import useDevice from "hooks/useDevice";
import { ThreeDotsIconStyled } from "./TableBodyContainer.styled";

const ClientDetailsRenderer = (props) => {
  const { isDesktop } = useDevice();

  return (
    <NavLink to={props?.onClickEdit()}>
      {isDesktop ? (
        <ActionButton
          startIcon={<DetailsIcon />}
          value={props?.isSuperAdmin ? "common.details" : "common.selectBtn"}
        />
      ) : (
        <ThreeDotsIconStyled onClick={props.onClickEdit} />
      )}
    </NavLink>
  );
};

ClientDetailsRenderer.propTypes = {
  onClickEdit: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
};

export default ClientDetailsRenderer;
