import React, { useEffect, useState } from "react";
import { useGetClientImageQuery } from "features/clients/clientsApiSlice";
import PropTypes from "prop-types";
import WithLoadingSpinner from "components/WithLoadingSpinner/WithLoadingSpinner";
import { CarouselImage, CarouselImageContainer } from "./ImageItem.styled";
import { useDispatch } from "react-redux";
import { setFullImageModal } from "features/modal/modalSlice";

const ImageItem = (props) => {
  const { data } = useGetClientImageQuery(props?.id);
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data) return;

    if (data instanceof Blob) {
      const url = URL.createObjectURL(data);
      setImageUrl(url);
      return () => URL.revokeObjectURL(url);
    }

    if (typeof data === "string") {
      setImageUrl(data);
    }
  }, [data]);

  return (
    <WithLoadingSpinner isLoading={imageUrl == null}>
      <CarouselImageContainer key={props?.id}>
        <CarouselImage
          src={imageUrl}
          onClick={() => dispatch(setFullImageModal(imageUrl))}
        />
      </CarouselImageContainer>
    </WithLoadingSpinner>
  );
};

ImageItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ImageItem;
