import { Select, TextField } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "util/colorHelper";

export const PhoneFieldContainer = styled.div`
  display: flex;
`;

export const CountryPhoneCodeSelect = styled(Select)`
  ${(props) => props.theme.breakpoints.rotate()} {
    height: 33px;
  }
  & .MuiOutlinedInput-input {
    padding-right: 15px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props?.theme?.colors?.fieldBorderColor};
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: ${(props) => {
      if (!props.disabled) return "1px solid";
    }};
    border-right: ${(props) => {
      if (props.disabled) return "none";
    }};
    border-color: ${(props) => {
      if (!props.disabled) return props?.theme?.colors?.secondaryColor;
    }};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid;
    border-color: ${(props) =>
      props.disabled
        ? `1px solid ${(props) => props?.theme?.colors?.fieldBorderColor}`
        : props?.theme?.colors?.secondaryColor};
  }
  background-color: ${(props) =>
    props?.theme?.colors?.selectCountryPhoneCodeBackground};
  max-height: 40px;
  width: 33%;
  text-align: center;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const PhoneNumberContainer = styled(TextField)`
  & .MuiOutlinedInput-root {
    ${(props) => props.theme.breakpoints.rotate()} {
      height: 33px;
    }
    & fieldset {
      border: 1px solid ${(props) => props?.theme?.colors?.fieldBorderColor};
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background-color: ${(props) =>
        props?.disabled
          ? hexToRGB(props?.theme?.colors?.clientPageFormBorder, 0.5)
          : ""};
    }
    &:hover fieldset {
      border-color: ${(props) =>
        props?.disabled ? "" : props?.theme?.colors?.secondaryColor};
    }
    &.Mui-focused fieldset {
      border-color: ${(props) => props?.theme?.colors?.secondaryColor};
    }
    max-height: 40px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  & .MuiFormHelperText-root {
    font-size: 12px;
    margin-left: 14px;
    font-weight: 400;
    position: absolute;
    top: 100%;

    ${(props) => props.theme.breakpoints.down("md")} {
      top: 88%;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  width: 67%;
  max-height: 40px;
`;

export const menuProps = {
  PaperProps: {
    sx: {
      "& ul": {
        width: "100%",
      },
      "& li": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
    },
  },
};
