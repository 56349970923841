import CustomInputField from "components/InputFields/CustomFields/CustomInputField";
import {
  ActiveCircle,
  LongDash,
  NameContainer,
  StatusButtonContainer,
  StatusContainer,
  StatusText,
} from "components/SuperAdmin/UserPageContent/UserPageContent.styled";
import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as CheckImg } from "assets/images/svg/status-active-check.svg";
import { ReactComponent as XImg } from "assets/images/svg/status-inactive-x.svg";
import {
  ButtonContainer,
  ClientServiceContainer,
  InfoContainer,
  InputFieldContainer,
  LeftSide,
  RightSideContainer,
  SaveButton,
} from "./ClientServiceContent.styled";
import ClientServiceTableSection from "./ClientServiceTableSection";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  isSubmitting,
  resetSubmitFlag,
  selectClientService,
  submit,
  toggleClientServiceStatus,
} from "features/clientService/clientServiceSlice";
import { useFormik } from "formik";
import { ClientServiceValidation } from "validations/clientService/clientServiceValidation";
import { ClientServiceConstants } from "constants/clientServiceConstants";
import { TYPE } from "constants/buttonConstants";
import { PropTypes } from "prop-types";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { unstable_useBlocker, useNavigate } from "react-router-dom";
import PrimaryServiceField from "components/ClientEditComponents/PrimaryServiceField";
import {
  useAddClientServiceMutation,
  useGetImageQuery,
  useUpdateClientServiceMutation,
} from "features/clients/clientsApiSlice";
import ImageUpload from "components/InputFields/CustomFields/ImageUpload";
import { setUnsavedAlertModal } from "features/modal/modalSlice";
import usePrompt from "hooks/usePromptHook";
import useDevice from "hooks/useDevice";

const ClientServiceContent = (props) => {
  const dispatch = useDispatch();
  const { isTablet, isMobile } = useDevice();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [addClientService] = useAddClientServiceMutation();
  const [updateClientService] = useUpdateClientServiceMutation();

  const clientService = useSelector(selectClientService);
  const shouldSaveData = useSelector(isSubmitting);

  const isActive = useMemo(() => {
    return clientService?.isActive;
  }, [clientService]);

  const [uploadedImage, setUploadedImage] = useState(null);
  const { data: fetchedImage } = useGetImageQuery(clientService?.imageId);

  const handleImageSelect = (image) => {
    setUploadedImage(image);
  };

  useEffect(() => {
    if (clientService?.imageId) {
      setUploadedImage({ url: fetchedImage });
    }
  }, [fetchedImage]);

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (uploadedImage && uploadedImage.file) {
      formData.append("Image", uploadedImage.file);
      formData.append("RemoveImage", false);
    } else if (uploadedImage && uploadedImage.url) {
      formData.append("Image", null);
      formData.append("RemoveImage", false);
    } else {
      formData.append("Image", null);
      formData.append("RemoveImage", true);
    }

    formData.append("Id", clientService?.id);
    formData.append("Name", formik?.values?.name);
    formData.append("Description", formik?.values?.description);
    formData.append("DurationInMinutes", formik?.values?.durationInMinutes);
    formData.append("Price", formik?.values?.price);
    formData.append("IsActive", isActive);
    formData.append(
      "ServiceCategoryId",
      formik?.values?.primaryServiceCategoryId
    );
    formData.append("ClientId", clientService?.client?.id);

    if (clientService.attributes.length === 0) {
      formData.append(`ServiceAttributes`, "");
    }

    if (clientService.requiredResourceTypes.length === 0) {
      formData.append(`ServiceRequiredResourceTypes`, "");
    }
    clientService?.attributes?.forEach((attribute, index) => {
      formData.append(`ServiceAttributes[${index}][id]`, attribute?.id);
      formData.append(`ServiceAttributes[${index}][name]`, attribute?.name);
      formData.append(
        `ServiceAttributes[${index}][description]`,
        attribute?.description
      );
      formData.append(
        `ServiceAttributes[${index}][durationInMinutes]`,
        attribute?.durationInMinutes
      );
      formData.append(`ServiceAttributes[${index}][price]`, attribute?.price);
    });

    clientService?.requiredResourceTypes.forEach((requiredResource, index) => {
      formData.append(
        `ServiceRequiredResourceTypes[${index}][id]`,
        requiredResource?.id
      );
      formData.append(
        `ServiceRequiredResourceTypes[${index}][autoAssign]`,
        requiredResource?.autoAssign
      );
      formData.append(
        `ServiceRequiredResourceTypes[${index}][resourceTypeName]`,
        requiredResource?.resourceTypeName
      );
      requiredResource?.possibleResources?.forEach((resource, i) => {
        formData.append(
          `ServiceRequiredResourceTypes[${index}][resourceIds][${i}]`,
          resource.resource.id
        );
      });
    });

    dispatch(resetSubmitFlag());
    if (props?.isEditPage) {
      const response = await updateClientService({
        clientId: clientService?.client?.id,
        clientServiceId: clientService?.id,
        formData,
      });
      if (response?.data?.isSuccess) {
        makeToastMessage(
          t("clientService.clientServiceSuccessfullyEdited"),
          toastNotificationType.SUCCESS
        );
        navigate(-1);
      } else {
        makeToastMessage(
          response?.error?.data?.errors[0].message,
          toastNotificationType.ERROR
        );
      }
    } else {
      const response = await addClientService({
        clientId: clientService?.client?.id,
        formData,
      });
      props?.createdIdReturn(response?.data?.data);
      if (response?.data?.isSuccess) {
        makeToastMessage(
          t("clientService.clientServiceSuccessfullyAdded"),
          toastNotificationType.SUCCESS
        );
        navigate(-1);
      } else {
        makeToastMessage(
          response?.error?.data?.errors[0].message,
          toastNotificationType.ERROR
        );
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: clientService?.name,
      description: clientService?.description,
      durationInMinutes: clientService?.durationInMinutes,
      price: clientService?.price,
      primaryServiceCategoryId: clientService?.serviceCategory?.id,
      primaryServiceCategoryName: clientService?.serviceCategory?.name,
    },
    validationSchema: ClientServiceValidation,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const onClickStatusButton = () => {
    dispatch(toggleClientServiceStatus());
    dispatch(submit());
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (formik.dirty && formik.isValidating) {
      dispatch(submit());
    }
  }, [formik.dirty]);

  usePrompt(shouldSaveData, t("superAdmin.table.clients.unsavedData"));
  unstable_useBlocker((tx) => {
    if (
      shouldSaveData &&
      tx?.currentLocation?.pathname != tx?.nextLocation?.pathname
    ) {
      dispatch(setUnsavedAlertModal({ path: tx }));
      tx?.retry();
    }
  });

  return (
    <ClientServiceContainer>
      <LeftSide>
        <InfoContainer>
          {props.isEditPage && !(isTablet || isMobile) && (
            <NameContainer>
              {t("clientService.service", {
                serviceName: clientService?.name,
              })}
            </NameContainer>
          )}
          <ImageUpload
            onImageSelect={handleImageSelect}
            onDeleteImage={handleDeleteImage}
            uploadedImage={uploadedImage}
          />
          {props.isEditPage && <LongDash />}
          <StatusContainer>
            <StatusText active={isActive}>
              {t("clientService.serviceActive")}
            </StatusText>
            <StatusButtonContainer
              active={isActive}
              onClick={onClickStatusButton}
            >
              <ActiveCircle>{isActive ? <CheckImg /> : <XImg />}</ActiveCircle>
            </StatusButtonContainer>
          </StatusContainer>
          <CustomInputField
            placeholder={"clientService.placeholderName"}
            name={ClientServiceConstants.name}
            label={"clientService.serviceName"}
            formik={formik}
          />
          <CustomInputField
            placeholder={"clientService.placeholderDescription"}
            name={ClientServiceConstants.description}
            label={"clientService.serviceDescription"}
            formik={formik}
          />
          <CustomInputField
            placeholder={"clientService.placeholderDuration"}
            name={ClientServiceConstants.durationInMinutes}
            label={"clientService.serviceDuration"}
            formik={formik}
            type={TYPE.NUMBER}
          />
          <CustomInputField
            placeholder={"clientService.placeholderPrice"}
            name={ClientServiceConstants.price}
            label={"clientService.servicePrice"}
            formik={formik}
            type={TYPE.NUMBER}
          />
          <InputFieldContainer>
            <PrimaryServiceField
              formik={formik}
              name={ClientServiceConstants.primaryServiceCategoryName}
              primaryId={ClientServiceConstants.primaryServiceCategoryId}
            />
          </InputFieldContainer>
          <ButtonContainer>
            <SaveButton
              value={t("common.save")}
              onClick={formik?.handleSubmit}
            />
          </ButtonContainer>
        </InfoContainer>
      </LeftSide>
      <RightSideContainer>
        <ClientServiceTableSection
          requiredResourceTypes={clientService?.requiredResourceTypes}
          attributes={clientService?.attributes}
        />
      </RightSideContainer>
    </ClientServiceContainer>
  );
};

ClientServiceContent.propTypes = {
  isEditPage: PropTypes.bool,
  createdIdReturn: PropTypes.func,
};

export default ClientServiceContent;
