import React from "react";
import PropTypes from "prop-types";
import {
  ButtonWrapper,
  TrashIconStyled,
  EditIconStyled,
} from "components/ClientEditTab/ClientContentTab/TabContent/Lincense/ClientLicenseContent.styled";
import { NavLink } from "react-router-dom";

const UpdateRenderer = (props) => {
  return (
    <ButtonWrapper>
      <NavLink
        to={props?.modal ?? props?.onClick()}
        onClick={props?.modal ? props?.onClick : null}
      >
        <EditIconStyled />
      </NavLink>
      <NavLink
        to={props?.noDeleteModal ? "#" : undefined}
        onClick={(e) => {
          if (props?.noDeleteModal) {
            e.preventDefault();
          }
          props?.onClickDelete?.();
        }}
      >
        <TrashIconStyled />
      </NavLink>
    </ButtonWrapper>
  );
};
UpdateRenderer.propTypes = {
  noDeleteModal: PropTypes.bool,
  modal: PropTypes.bool,
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
};

export default UpdateRenderer;
