import React, { useState } from "react";
import { Controls, TabContainer } from "./TopTabContainer.styled";
import clientsTabConstants from "constants/ClientTabConstants/clientsTabConstants";
import { TopTab } from "../IndividualTab/TopTab";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { compareRoutes, replaceInRoute } from "util/routeHelpers";
import { StyledTypography } from "components/SuperAdmin/Header/SuperAdminHeader.style";
import { ClientSwitch } from "components/ClientEditComponents/ClientSwitch";
import { useDispatch, useSelector } from "react-redux";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";
import { isDataSaved } from "features/user/userSlice";

export const TopTabContainer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isTablet, isMobile } = useDevice();
  const handleTabClick = (key) => {
    props?.handleTabClick(key);
    return `${key}`;
  };
  const defaultTab = clientsTabConstants.find((tab) => tab.default)?.key;
  const [isUserPanelChecked, setPanelCheck] = useState(false);
  const dataSaved = useSelector(isDataSaved);

  const handleUserPanelChange = (event) => {
    if (dataSaved) {
      setPanelCheck(event.target.checked);
    }

    const pathParams = {
      clientId: props?.clientId,
    };
    const names = {
      clientId: props?.clientName,
    };

    navigate(replaceInRoute(PAGES.CLIENT.route, pathParams, names, dispatch));
  };

  const renderControls = () =>
    props?.activeTab === defaultTab && (
      <Controls>
        <StyledTypography $userPanel>
          {t("superAdmin.table.clients.userPanel")}
        </StyledTypography>

        <ClientSwitch
          checked={isUserPanelChecked}
          onChange={handleUserPanelChange}
        />
      </Controls>
    );

  return (
    <>
      {(isTablet || isMobile) && renderControls()}
      <TabContainer>
        {clientsTabConstants.map((singleTab) => (
          <TopTab
            goTo={replaceInRoute(
              singleTab.route,
              { clientId: props?.clientId },
              dispatch
            )}
            key={singleTab.key}
            title={t(singleTab.title)}
            id={singleTab.key}
            isActive={compareRoutes(location.pathname, singleTab.route)}
            onClick={() => handleTabClick(singleTab.key)}
          />
        ))}
        {!isTablet && !isMobile && renderControls()}
      </TabContainer>
    </>
  );
};

TopTabContainer.propTypes = {
  handleTabClick: PropTypes.any,
  activeTab: PropTypes.any,
  clientId: PropTypes.any,
  clientName: PropTypes.string,
};
