import RemoveAuthorizedUserRender from "components/ClientEditTab/ClientContentTab/TabContent/RemoveAuthorizedUserRender";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "profile.labels.firstName",
    field: "firstName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 1,
    headerName: "profile.labels.lastName",
    field: "lastName",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 2,
    headerName: "common.labelPhoneNumber",
    field: "phoneNumber",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 3,
    headerName: "common.labelLocation",
    field: "city",
    enabledSort: false,
    enabledFilter: true,
  },
  {
    columnId: 4,
    headerName: "",
    field: "remove",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (props) => <RemoveAuthorizedUserRender {...props} />,
  },
];
