import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import AuthType from "constants/authType";
import HomePage from "pages/HomePage/HomePage";
import RequireAuth from "components/RequireAuth/RequireAuth";
import AuthCallback from "pages/AuthCallbackPage/AuthCallbackPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import MiddlePage from "pages/MiddlePage/MiddlePage";
import RegisterPage from "pages/RegisterPage/RegisterPage";
import SuperAdminServiceCategories from "pages/SuperAdmin/SuperAdminServiceCategoriesPage";
import SuperAdminClientLicense from "pages/SuperAdmin/SuperAdminClientLicensesPage";
import RequireNoAuth from "components/RequireNoAuth/RequireNoAuth";
import SuperAdminPageClients from "pages/SuperAdmin/SuperAdminPageClients";
import UsersPage from "pages/SuperAdmin/SuperAdminPageUsers";
import MessagesPage from "pages/Messages/MessagesPage";
import AppointemntsPage from "pages/Appointments/AppointmentsPage";
import AboutPage from "pages/About/AboutPage";
import HelpPage from "pages/Help/HelpPage";
import ContactPage from "pages/Contact/ContactPage";
import ClientEditTab from "components/ClientEditTab/ClientEditTab";
import { ClientsPage } from "pages/ClientsPage/ClientsPage";
import ClientPanelForUsersPage from "pages/Client/ClientPanelForUsersPage";
import ClientServicePage from "pages/ClientServicePage/ClientServicePage";
import ForgotPassword from "components/ForgotPassword/ForgotPassword";
import ResetPassword from "components/ForgotPassword/ResetPassword";
import SchedulingAppointmentPage from "pages/SchedulingAppointmentPage/SchedulingAppointmentPage";
import RequireSuperAdmin from "components/RequireAuthorization/RequireAdmin/RequireAdmin";
import RequireClientAdmin from "components/RequireAuthorization/RequireClient/RequireClient";
import UserPage from "pages/SuperAdmin/SuperAdminPageUser";
import UserAccountPage from "pages/User/UserAccountPage";
import AuthorizedUsersPage from "pages/AuthorizedUsersPage/AuthorizedUsersPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path={PAGES.BASE.route}
      element={<HomePage />}
      errorElement={<Error />}
    />
    <Route path={PAGES.CLIENTS.route} element={<ClientsPage />} />
    <Route path={PAGES.CLIENT.route} element={<ClientPanelForUsersPage />} />
    <Route
      exact
      path={PAGES.ABOUT.route}
      element={<AboutPage />}
      errorElement={<Error />}
    />
    <Route
      path={PAGES.SCHEDULE_APPOINTMENT.route}
      element={<SchedulingAppointmentPage />}
    />
    <Route
      exact
      path={PAGES.HELP.route}
      element={<HelpPage />}
      errorElement={<Error />}
    />
    <Route
      exact
      path={PAGES.CONTACT.route}
      element={<ContactPage />}
      errorElement={<Error />}
    />
    <Route element={<RequireNoAuth />} errorElement={<Error />}>
      <Route
        path={PAGES.MIDDLEPAGE.route}
        element={<MiddlePage />}
        errorElement={<Error />}
      />
      <Route
        path={PAGES.LOGIN.route}
        element={<LoginPage />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.REGISTER_USER.route}
        element={<RegisterPage type={AuthType.USER} />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.REGISTER_CLIENT.route}
        element={<RegisterPage type={AuthType.CLIENT} />}
        errorElement={<Error />}
      />
      <Route
        path={PAGES.RESET_PASSWORD.route}
        element={<ResetPassword />}
        errorElement={<Error />}
      />
      <Route
        exact
        path={PAGES.FORGOT_PASSWORD.route}
        element={<ForgotPassword />}
        errorElement={<Error />}
      />
    </Route>
    <Route path="api/auth/:provider/callback" element={<AuthCallback />} />
    <Route element={<RequireAuth />} errorElement={<Error />}>
      <Route element={<RequireClientAdmin />} errorElement={<Error />}>
        <Route
          path={PAGES.CLIENT_LICENSES.route}
          element={<SuperAdminClientLicense />}
        />
        <Route
          path={PAGES.CLIENT_AUTHORIZED_LIST.route}
          element={<AuthorizedUsersPage />}
        />
        <Route
          path={PAGES.CLIENT_SERVICE.route}
          element={<ClientServicePage />}
        />
        <Route path={PAGES.CLIENTS_DETAILS.route} element={<ClientEditTab />} />
      </Route>
      <Route element={<RequireSuperAdmin />} errorElement={<Error />}>
        <Route path={PAGES.DASHBOARD.route} element={<DashboardPage />} />
        <Route
          path={PAGES.SUPERADMIN_CLIENTS.route}
          element={<SuperAdminPageClients />}
        />
        <Route path={PAGES.USER.route} element={<UserPage />} />
        <Route path={PAGES.USERS.route} element={<UsersPage />} />
        <Route
          path={PAGES.SERVICE_CATEGORIES.route}
          element={<SuperAdminServiceCategories />}
        />
      </Route>
      <Route path={PAGES.MY_ACCOUNT.route} element={<UserAccountPage />} />
      <Route path={PAGES.MESSAGES.route} element={<MessagesPage />} />
      <Route path={PAGES.APPOINTMENTS.route} element={<AppointemntsPage />} />
    </Route>
    <Route path="*" element={<Navigate to={PAGES.BASE.route} />} />
  </Routes>
);

export default AppRoutes;
