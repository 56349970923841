import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  DownArrowIconStyled,
  FilterButtonContainer,
  FilterButtonContainerWrapper,
  FilterIconStyled,
} from "./FilterButton.styled";
import { useTranslation } from "react-i18next";

export const FilterButton = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <FilterButtonContainerWrapper ref={ref}>
      <FilterButtonContainer
        onClick={props.onClick}
        value={t(props.value)}
        startIcon={<FilterIconStyled />}
        endIcon={<DownArrowIconStyled />}
        disableRipple
        marginTop={props.marginTop}
        isCalendarComponent={props.isCalendarComponent}
      />
    </FilterButtonContainerWrapper>
  );
});

FilterButton.displayName = "FilterButton";

FilterButton.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  marginTop: PropTypes.bool,
  isCalendarComponent: PropTypes.bool,
};

FilterButton.defaultProps = {
  value: "common.filter",
  marginTop: false,
};

export default FilterButton;
