import styled from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const Modal = styled.div`
  width: 460px;
  max-height: 448px;
  box-sizing: border-box;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      max-width: 345px;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: 364px;
  overflow: auto;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 16px 12px 8px 12px;
    }
  }
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  align-items: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px;
  width: 100%;
  height: 82px;
  padding: 25px 30px;
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 24px;
      height: 73px;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ActionButton = styled(Button)`
  height: 33px;
  width: fit-content;

  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 33px;
      width: 102px;
    }
  }
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 33px;
      width: 121px;
    }
  }
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  margin-top: 10px;
`;
