import EmailField from "components/InputFields/EmailField";
import PasswordField from "components/InputFields/PasswordField";
import FirstNameField from "components/RegistrationContent/InputFields/FirstNameField";
import LastNameField from "components/RegistrationContent/InputFields/LastNameField";
import {
  InputFieldContainer,
  InputFieldsContainer,
  OrDividerGoogleBtnContainer,
  UpperContainer,
} from "components/RightAuthContent/RightAuthContent.styled";
import { FormikParams } from "constants/formikParams";
import React, { useState } from "react";
import PropTypes from "prop-types";
import LowerRegContent from "components/RegistrationContent/LowerRegContent/LowerRegContent";
import { useFormik } from "formik";
import { ClientStepTwoValidation } from "validations/registerClientValidation";
import OrDivider from "components/OrDivider/OrDivider";
import GoogleButton from "components/RightAuthContent/Buttons/GoogleButton/GoogleButton";
import {
  selectClientAttributesStepTwo,
  setAttributesStepTwo,
} from "features/register/registerClientSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useAddPendingUserMutation,
  useLazyCheckClientEmailQuery,
} from "features/register/registerApiSlice";
import { useRequestEmailVerificationCodeMutation } from "features/register/userRegisterApiSlice";
import { EmailAlreadyInUse } from "constants/registrationBEerrors";
import { clientColors } from "themes/secondaryTheme/secondaryThemeColors";
import { useTranslation } from "react-i18next";
import useDevice from "hooks/useDevice";

const ClientStepTwo = (props) => {
  const [requestEmailVerificationCode] =
    useRequestEmailVerificationCodeMutation();
  const [addPendingUser] = useAddPendingUserMutation();
  const [emailNotUniqueError, setEmailNotUniqueError] = useState(null);
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] =
    useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerCheckClientEmail] = useLazyCheckClientEmailQuery();
  const clientDataStepTwo = useSelector(selectClientAttributesStepTwo);
  const { isMobile } = useDevice();

  const reverseMappedData = {
    email: clientDataStepTwo?.email,
    firstName: clientDataStepTwo?.firstName,
    lastName: clientDataStepTwo?.lastName,
    password: null,
    confirmedPassword: null,
  };

  const handleSubmit = async () => {
    const data = {
      email: formik.values.email,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };
    dispatch(setAttributesStepTwo(data));
    const response = await addPendingUser({
      data,
    });
    if (response.error) {
      response.error.data.errors.map((error) => {
        switch (error.code) {
          case EmailAlreadyInUse:
            setEmailNotUniqueError(error.message);
            break;
          default:
            break;
        }
      });
    } else {
      const response = await requestEmailVerificationCode({
        ...data,
      });
      if (response.error) {
        alert(response.error.data.errors);
      }
      props.onClickNextPageBtn();
    }
  };

  const handleGoogle = () => {};

  const handleBlurEmail = async (e) => {
    formik.handleBlur(e);
    const result = await triggerCheckClientEmail(formik.values.email);

    if (result.data.data.isEmailUnique === false) {
      setEmailNotUniqueError(t("register.emailAlreadyTaken"));
      return;
    } else {
      setEmailNotUniqueError(null);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...(reverseMappedData ?? null),
    },
    validationSchema: ClientStepTwoValidation(isMobile),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const clearEmailErrorMessage = () => {
    if (emailNotUniqueError != null) {
      setEmailNotUniqueError(null);
    }
  };

  const clearPasswordConfirmErrorMessage = () => {
    if (passwordsDoNotMatchError != null) {
      setPasswordsDoNotMatchError(null);
    }
  };

  const handleBack = () => {
    dispatch(setAttributesStepTwo(formik.values));
    props.onClickBackBtn();
  };

  return (
    <>
      <UpperContainer>
        <InputFieldsContainer>
          <InputFieldContainer twoInputs>
            <FirstNameField formik={formik} required />
            <LastNameField formik={formik} required />
          </InputFieldContainer>
          <InputFieldContainer>
            <EmailField
              formik={formik}
              required
              helperText={emailNotUniqueError}
              clearErrorMessage={clearEmailErrorMessage}
              onBlur={handleBlurEmail}
            />
          </InputFieldContainer>
          <InputFieldContainer twoInputs>
            <PasswordField formik={formik} required />
            <PasswordField
              label="common.labelPasswordConfirmation"
              name={FormikParams.confirmedPassword}
              placeholder="common.passwordConfirmationInput"
              formik={formik}
              required
              helperText={passwordsDoNotMatchError}
              clearErrorMessage={clearPasswordConfirmErrorMessage}
            />
          </InputFieldContainer>
        </InputFieldsContainer>
        <OrDividerGoogleBtnContainer>
          <OrDivider />
          <GoogleButton onClick={handleGoogle} />
        </OrDividerGoogleBtnContainer>
      </UpperContainer>
      <LowerRegContent
        disabled={emailNotUniqueError ? true : false}
        onClickNextBtn={formik.handleSubmit}
        onClickBackBtn={handleBack}
        currentStep={props.currentStep}
        colorPalet={clientColors}
      />
    </>
  );
};

ClientStepTwo.propTypes = {
  onClickNextPageBtn: PropTypes.func,
  onClickBackBtn: PropTypes.func,
  currentStep: PropTypes.number,
};

export default ClientStepTwo;
