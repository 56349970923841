import React from "react";
import UpdateRenderer from "components/Table/UpdateRenderer";
import Status from "components/Status/Status";
import WithAdminPermissions from "components/WithPermissions/WithAdminPermission/WithAdminPermissions";
import ClientLicensesRenderer from "components/Table/ClientLicensesRenderer";
import { licenseTypes } from "constants/licenceType";

export default [
  {
    columnId: 0,
    headerName: "superAdmin.table.licenses.lastChanged",
    field: "updatedAtUtc",
    enabledSort: false,
    enabledFilter: false,
    showHeaderBeforeValue: true,
    cellRenderer: (value, row) => {
      const valueToRender = row?.updatedAtUtc ? row.updatedAtUtc : value;
      return (
        <ClientLicensesRenderer
          value={valueToRender?.name}
          colName={"superAdmin.table.licenses.lastChanged"}
        />
      );
    },
  },
  {
    columnId: 1,
    headerName: "superAdmin.table.licenses.issuedAtUtc",
    field: "startDateUtc",
    enabledSort: false,
    enabledFilter: false,
    showHeaderBeforeValue: true,
    cellRenderer: (value, row) => {
      const valueToRender = row?.updatedAtUtc ? row.updatedAtUtc : value;
      return (
        <ClientLicensesRenderer
          value={valueToRender?.name}
          colName={"superAdmin.table.licenses.issuedAtUtc"}
        />
      );
    },
  },
  {
    columnId: 3,
    headerName: "superAdmin.table.licenses.expiresAtUtc",
    field: "endDateUtc",
    enabledSort: false,
    enabledFilter: false,
    showHeaderBeforeValue: true,
    cellRenderer: (value, row) => {
      const valueToRender = row?.updatedAtUtc ? row.updatedAtUtc : value;
      return (
        <ClientLicensesRenderer
          value={valueToRender?.name}
          colName={"superAdmin.table.licenses.expiresAtUtc"}
        />
      );
    },
  },
  {
    columnId: 2,
    headerName: "superAdmin.table.licenses.type",
    field: "type",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (value) => {
      const license = licenseTypes.find((item) => item.id === value?.row?.type);
      return (
        <ClientLicensesRenderer
          text
          value={license ? license.name : value?.row?.type}
        />
      );
    },
  },
  {
    columnId: 4,
    headerName: "superAdmin.table.licenses.status",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => {
      const currentDate = new Date();
      const startDate = new Date(params?.row?.startDateUtc);
      const endDate = new Date(params?.row?.endDateUtc);
      const isValid = currentDate > startDate && currentDate < endDate;

      return (
        <Status
          isActive={isValid}
          activeTextKey="superAdmin.table.licenses.statusValid"
          inactiveTextKey="superAdmin.table.licenses.statusInvalid"
        />
      );
    },
  },
  {
    columnId: 5,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <WithAdminPermissions>
        <UpdateRenderer
          modal={params?.modal}
          params={params}
          onClick={params.onClickEdit}
          onClickDelete={params.onClickDelete}
          value={"superAdmin.table.editButton"}
        />
      </WithAdminPermissions>
    ),
  },
];
