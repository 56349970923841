import styled from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as xIcon } from "assets/images/svg/x-icon.svg";

export const Modal = styled.div`
  width: 460px;
  max-height: fit-content;
  box-sizing: border-box;
  overflow: hidden;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 345px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 345px;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CloseIcon = styled(xIcon)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: fit-content;
  overflow: auto;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 16px 12px 8px 12px;
      gap: 10px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 16px 12px 8px 12px;
      gap: 10px;
    }
  }
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 18px;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  align-items: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px;
  width: 100%;
  height: 82px;
  padding: 25px 30px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 73px;
      padding: 24px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 60px;
      padding: 16px;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 102px;
    }
  }
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 121px;
    }
  }
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 10px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
    }
  }
`;

export const InputFieldContainer = styled.div`
  gap: ${(props) => (props?.gap ? "3px" : "16px")};
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
    }
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      > div {
        width: 100%;
        gap: 0px;
      }
      gap: 2px;
      
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 8px;
    }
  }
`;
