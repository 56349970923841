import { Typography } from "@mui/material";
import AddNewItemButton from "components/SuperAdmin/Buttons/AddNewButton/AddNewItemButton";
import styled from "styled-components";

export const ClientServiceTableHeaderSyled = styled.div`
  gap: 10px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
`;

export const ClientServiceTableHeaderTypography = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  vertical-align: middle;
`;
export const ClientServiceTableHeaderButtonContainer = styled(Typography)`
  display: flex;
  justify-content: right;
  width: 50px !important;
`;

export const ClientServiceTableHeaderButtonStyled = styled(AddNewItemButton)`
  min-width: 0px;
`;
