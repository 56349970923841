import { Tooltip } from "@mui/material";
import { ChipsContainer } from "components/ClientServiceContent/ClientServiceTableSection.styled";
import CustomLabelWithFormik from "components/InputFields/CustomFields/CustomLabelWIthFormik";
import { ChipStyled } from "components/InputFields/CustomFields/CustomSelectField.styled";
import UpdateRenderer from "components/Table/UpdateRenderer";
import tooltipConstants from "constants/tooltipConstants";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "clientService.table.resources.groupName",
    field: "resourceTypeName",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "clientService.table.resources.autoAssign",
    field: "autoAssign",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <>
        {params?.name ? (
          <CustomLabelWithFormik label={"common.yes"} />
        ) : (
          <CustomLabelWithFormik label={"common.no"} />
        )}
      </>
    ),
  },
  {
    columnId: 2,
    headerName: "clientService.table.resources.resources",
    field: "resourceNames",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => {
      if (params.name.length > 3) {
        const tooltipText = params?.name?.slice(2)?.map((name) => (
          <>
            {name}
            <br />
          </>
        ));
        return (
          <Tooltip title={tooltipText} placement={tooltipConstants.RIGHT} arrow>
            <ChipsContainer>
              {params?.name?.slice(0, 2)?.map((name) => (
                <ChipStyled key={name} label={name} />
              ))}
              <ChipStyled key={"..."} label={"..."} />
            </ChipsContainer>
          </Tooltip>
        );
      } else {
        return (
          <>
            {params?.name?.map((name) => (
              <ChipStyled key={name} label={name} />
            ))}
          </>
        );
      }
    },
  },
  {
    columnId: 3,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        noDeleteModal={true}
        modal={params?.modal}
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
