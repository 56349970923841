import React from "react";
import PropTypes from "prop-types";
import { GridContainer } from "./ListingClientsComponent.styled";
import { PAGES } from "constants/pages";
import { replaceInRoute } from "util/routeHelpers";
import ClientCard from "components/LandingPageContent/ClientCard";

const ListingClientsComponent = (props) => {
  const onClientClick = (client) => {
    const pathParams = {
      clientId: client?.id,
    };
    const resolvedRoute = replaceInRoute(PAGES.CLIENT.route, pathParams);
    return resolvedRoute;
  };

  return (
    <GridContainer>
      {props?.items?.map((client) => (
        <ClientCard
          goTo={onClientClick(client)}
          key={client?.id}
          client={client}
        />
      ))}
    </GridContainer>
  );
};

ListingClientsComponent.propTypes = {
  items: PropTypes.array,
  clientId: PropTypes.string,
};

export default ListingClientsComponent;
