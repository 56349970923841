import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { FooterContainer, StyledTypography, MiniLogo } from "./Footer.styled";
import { useLocation } from "react-router-dom";
import { getCurrentRoute } from "util/routeHelpers";
const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = getCurrentRoute(location.pathname);

  if (currentRoute?.hideFooter) {
    return null;
  }

  return (
    <FooterContainer>
      <StyledTypography>
        <Trans
          i18nKey={"footer.left"}
          components={[<span key={0} style={{ fontWeight: 700 }} />]}
        />
      </StyledTypography>
      <MiniLogo></MiniLogo>
      <StyledTypography $bold>{t("footer.companyName")}</StyledTypography>
      <StyledTypography>{t("footer.rights")}</StyledTypography>
    </FooterContainer>
  );
};

export default Footer;
