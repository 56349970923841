import React from "react";
import PropTypes from "prop-types";
import {
  StarImageStyled,
  SubscribeButtonStyled,
} from "./SubscribeButton.styled";
import { Tooltip } from "@mui/material";
import tooltipConstants from "constants/tooltipConstants";
import { useTranslation } from "react-i18next";
import {
  useSubscribeUserToClientMutation,
  useUnsubscribeUserToClientMutation,
} from "features/clients/clientsApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";

const SubscribeButton = (props) => {
  const { t } = useTranslation();
  const [subscribeToClient] = useSubscribeUserToClientMutation();
  const [unsubscribeToClient] = useUnsubscribeUserToClientMutation();

  const onClick = () => {
    if (props.isSubscribed) {
      unsubscribeToClient({ userId: props?.userId, clientId: props?.clientId });
      makeToastMessage(
        t("clientPanel.subscriptions.removedFromFavourites"),
        toastNotificationType.SUCCESS
      );
    } else {
      subscribeToClient({ userId: props?.userId, clientId: props?.clientId });
      makeToastMessage(
        t("clientPanel.subscriptions.addedToFavourites"),
        toastNotificationType.SUCCESS
      );
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <SubscribeButtonStyled isClientPanel={props.isClientPanel}>
      <Tooltip
        title={
          props.isSubscribed
            ? t("clientPanel.subscriptions.removeFromFavourites")
            : t("clientPanel.subscriptions.addToFavourites")
        }
        placement={tooltipConstants.TOP}
        arrow
      >
        <StarImageStyled
          isSubscribed={props.isSubscribed}
          isClientPanel={props.isClientPanel}
          onClick={handleClick}
        />
      </Tooltip>
    </SubscribeButtonStyled>
  );
};

SubscribeButton.propTypes = {
  isClientPanel: PropTypes.bool,
  isSubscribed: PropTypes.bool,
  onClick: PropTypes.func,
  userId: PropTypes.string,
  clientId: PropTypes.string,
};

export default SubscribeButton;
