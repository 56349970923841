import styled from "styled-components";
import {
  Typography,
  FormControl,
  TableFooter,
  Pagination,
  Select,
} from "@mui/material";

export const FooterContainer = styled(TableFooter)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 20px 0px 20px 0px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      justify-content: center;
    }
  }
`;

export const StyledPagination = styled(Pagination)`
  & .MuiPagination-ul {
    display: flex;
    flex-wrap: nowrap;
  }
  & .MuiPaginationItem-root {
    &.Mui-selected {
      background-color: ${(props) => props?.theme?.colors?.paginationSelected};
      color: ${(props) => props?.theme?.colors?.textWhite};
    }

    &:hover:not(.Mui-selected) {
      background-color: ${(props) => props?.theme?.colors?.paginationHover};
    }
  }
`;

export const SelectInputStyled = styled(Select)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 0px;
      & .MuiSelect-select {
        padding: 8px;
      }
    }
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props?.theme?.colors?.darkBlue};
  margin-right: 8px;
  margin-left: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 12px;
      line-height: 12px;
      margin-right: 0px;
      margin-left: 0px;
    }
  }
`;

export const StyledSelect = styled(FormControl)`
  min-width: 60px;
  margin-left: 8px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-left: 5px;
      margin-right: 5px;
      min-width: 45px;
      & .MuiSelect-icon {
        right: 0px;
      }
    }
  }
`;
