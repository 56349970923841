import styled from "styled-components";
import { ReactComponent as ClientImg } from "assets/images/svg/client-validation-message.svg";
import { ReactComponent as UserImg } from "assets/images/svg/user-validation-message.svg";
import { hexToRGB } from "util/colorHelper";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";

export const Modal = styled.div`
  width: 460px;
  height: 730px;
  box-sizing: border-box;
  overflow: hidden; 
  
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 345px;
      height: auto;
    }
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 460px;
  padding: 25px 30px 30px 30px;
  overflow: auto; 

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 16px 12px 8px 12px;
      width: 345px;
      gap: 16px;
    }
  }
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.7)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: white;
  justify-content: center;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: 89px;
  padding: 25px 30px 25px 30px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 24px;
      height: 73px;
    }
  }
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 18px;
      line-height: 18px;
    }
  }
`;

export const ActionButton = styled(Button)`
  height: 33px;
  min-width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      min-width: 102px;
    }
  }
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  min-width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      min-width: 121px;
    }
  }
`;

const StyledImage = styled.img`
  width: 170px;
  height: 121px;
  display: block;
  margin: 0 auto;
`;

export const StyledClientImg = styled(StyledImage).attrs({ as: ClientImg })``;
export const StyledUserImg = styled(StyledImage).attrs({ as: UserImg })``;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
