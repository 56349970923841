import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "assets/images/svg/x-icon.svg";

export const UploadBox = styled.label`
  border: 2px dashed
    ${(props) => props?.theme?.colors?.imageGalleryUploadBoxBorder};
  text-align: center;
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => props?.theme?.colors?.imageGalleryUploadBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  max-width: 100%;
  height: 150px;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      height: 100px;
    }
  }
`;

export const UploadContent = styled.div`
  span {
    font-size: 3rem;
  }
  p {
    font-weight: 300;
    font-size: 12px;
    color: ${(props) => props?.theme?.colors?.black};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      span {
        font-size: 2rem;
      }
      p {
        margin-top: 0px;
      }
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
`;

export const DeleteButton = styled(DeleteIcon)`
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 50px;
  height: 50px;
  color: ${(props) => props?.theme?.colors?.white};

  &:hover {
    opacity: 1;
  }
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover img {
    filter: brightness(30%) blur(3px);
  }

  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;
