import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useAcceptAppointmentRequestMutation } from "features/appointmentSlice/appointmentApiSlice";
import {
  CancelButton,
  LowerContainerWrapper,
  Modal,
  NoButton,
  QuestionMarkIconStyled,
  TitleStyled,
  UpperContainerWrapper,
  YesButton,
} from "./AcceptAppointmentRequestModal.styled";
import { unsetShownModal } from "features/modal/modalSlice";
import { CloseIcon } from "components/ScheduleEditor/SetWorkingHoursModal/SetWorkingHoursModal.styled";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";

export const AcceptAppointmentRequestModal = (props) => {
  const { t } = useTranslation();
  const [acceptAppointmentRequest] = useAcceptAppointmentRequestMutation();

  const dispatch = useDispatch();

  const handleAccept = async (authorizeUser) => {
    const response = await acceptAppointmentRequest({
      appointmentId: props?.appointment?.id ?? props?.appointment?.appointmentId,
      authorizeUser: authorizeUser,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("appointments.acceptAppointmentRequestResponse"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  return (
    <Modal>
      <UpperContainerWrapper>
        <CloseIcon onClick={() => dispatch(unsetShownModal())} />
        <QuestionMarkIconStyled />
        <TitleStyled>{t("appointments.authorizeUserQuestion")}</TitleStyled>
      </UpperContainerWrapper>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.quit")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <NoButton value={t("common.no")} onClick={() => handleAccept(false)} />
        <YesButton value={t("common.yes")} onClick={() => handleAccept(true)} />
      </LowerContainerWrapper>
    </Modal>
  );
};

AcceptAppointmentRequestModal.propTypes = {
  appointment: PropTypes.any,
};

export default AcceptAppointmentRequestModal;
