export const APPOINTMENT_STATUS = {
  PENDING: 0,
  DECLINED: 1,
  RESERVED: 2,
  CANCELLED: 3,
  DONE: 4,
};

export const APPOINTMENT_TYPE = {
  REGULAR: 10,
  RESOURCE_ALLOCATION: 11,
  WISHLIST: 12,
};
