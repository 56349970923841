export default {
  NAME: "name",
  EMAIL: "email",
  SERVICE_NAME: "serviceName",
  CLIENT_NAME: "clientName",
  USER_FIRST_NAME: "userFirstName",
  USER_LAST_NAME: "userLastName",
  DURATION_FROM: "durationFrom",
  DURATION_TO: "durationTo",
  PRICE_FROM: "priceFrom",
  PRICE_TO: "priceTo",
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
  STATUS: "status",
  CITY: "city",
  PRIMARY_SERVICE: "primaryService",
  STATUS_CANCELED: "statusCanceled",
  STATUS_ACTIVE: "statusActive",
  STATUS_DONE: "statusDone",
  STATUS_PENDING: "statusPending",
  STATUS_DECLINED: "statusDeclined",
  TYPE_REGULAR: "typeRegular",
  TYPE_RESOURCE_ALLOCATION: "typeResourceAllocation",
  TYPE_WHISLIST: "typeWishlist"
};
