import { hexToRGB } from "util/colorHelper";

export const secondaryThemeColors = {
  primaryDark: "#C4DFDF",
  secondaryDark: "#D2E9E9",
  primaryLighter: "#F8F6F4",
  secondaryLighter: "#E3F4F4",
  primaryLightBlue: "#B3C7D7",
  textColor: "#393646",
  backgroundColor: "#F3F6F9",
  purpleBoxBackgournd: "#F7FDFF",
  greenBoxBackground: "#F9FFFF",
  primaryColor: "#35CAC1",
  secondaryColor: "#87CEEB",
  secondaryColorHover: "#beeafc",
  primaryColorHover: "#8ae3da",
  completedRegistration: "#0A2323",
  uncompletedRegistration: "#658383",
  uncompletedRegistrationBackground: "#9BADAD",
  iconColor: "#FDFDFD",
  errorColor: "#FF4D4D",
  errorColorHover: "#FF0D0D",
  selectCountryPhoneCodeBackground: "#F3F6F9",
  fieldBorderColor: "#ccc",
  darkBlue: "#173F61",
  hoverDarkBlue: "#173F81",
  black: "#000000",
  white: "#FFFFFF",
  statusActiveBackground: "#E6F4EA",
  statusInactiveBackground: "#FDECEC",
  statusActiveText: "#4CAF50",
  statusInactiveText: "#F44336",
  statusActiveButton: "#6CAA7D",
  statusInactiveButton: "#FF4D4D",
  boxShadow: "#9BADAD",
  placeholderText: "#9C9EAA",
  googleButton: "#f4f4f4",
  googleButtonHover: "#f0f0f0",
  paginationSelected: "#b3c7d7",
  paginationHover: "#f3f6f9",
  clientPageFormBorder: "#E5E6E9",
  leftAuthContentBackground: "#173F61",
  textWhite: "#FFFFFF",
  textBlack: "#171921",
  greenCheck: "#80c082",
  text3: "#9C9EAA",
  disabledRow: "#DEE1E46E",
  textUncompletedStep: "#E5E6E9",
  richTextEditorBackground: "#f9fafc",
  whiteOpacity30: hexToRGB("#FFFFFF", 0.3),
  updateTableButton: "#F98A2A",
  rowBackgroundColor: "#F3F6F9",
  rowBorderColor: "#E5E6E9",
  errorRed: "#D4342E",
  notificationGreen: "#6CAA7D",
  notificationRed: "#FF0000",
  timeSlotBg: "#B3C7D7",
  timeSlotText: "#173F61",
  accordionClosed: "#F3F6F9",
  textColor2: "#545766",
  rowTextColor: "#161616",
  serviceChipColor: "#F5EDFD",
  serviceChipTextColor: "#9966CC",
  filterXcolor: "#003246",
  statusOrange: "#FEF3EA",
  statusTextOrange: "#F98A2A",
  notificationStatusOrange: hexToRGB("#F98A2A", 0.1),
  notificationColor: "#FCC18D",
  filterChipColor: hexToRGB("#FFD700", 0.5),
  disabledColor: "#DBDBDB",
  toastSuccessColor: hexToRGB("#E8F4EB", 1),
  toastErrorColor: hexToRGB("#FFEDED", 1),
  toastWarningColor: hexToRGB("#FEF3EA", 1),
  disabledBackground: "#A0B9C8",
  selectDisabledBorderColor: "#B3B3B5",
  selectDisabledBackGroundColor: "#F2F2F4",
  clientRegistration: {
    Circle: "#87CEEB",
  },
  shadowColor: hexToRGB("#9BADAD40", 0.25),
  starColor: "#F2CC00",
  starHoverColor: "#b8860b",

  userRegistration: {
    Circle: "#40E0D0",
  },
};

export const clientColors = {
  buttonColor: secondaryThemeColors.clientRegistration.Circle,
  buttonColorHover: secondaryThemeColors.clientRegistration.Circle,
  buttonTextColor: secondaryThemeColors.textBlack,
};

export const userColors = {
  buttonColor: secondaryThemeColors.userRegistration.Circle,
  buttonColorHover: secondaryThemeColors.userRegistration.Circle,
  buttonTextColor: secondaryThemeColors.textBlack,
};

export const cardColors = [
  {
    background: "#F98A2A14",
    text: "#F98A2A",
  },
  {
    background: "#F5EDFD",
    text: "#9966CC",
  },
  {
    background: "#FFD7000D",
    text: "#F2CC00",
  },
];

export const statusColors = {
  0: "#F98A2A",
  1: "#FF4D4D",
  2: "#6CAA7D",
  3: "#FF4D4D",
  4: "#9C9EAA",
  5: "#35C2C1",
  6: "#FF4D4D",
};
