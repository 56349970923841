import styled, { css } from "styled-components";
import { Typography } from "@mui/material";

const CustomFieldLabelStyled = styled(Typography)`
  padding-left: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: black;
  ${(props) =>
    props?.required &&
    css`
      &::after {
        content: "*";
      }
    `}
  color: ${(props) =>
    props.disabled
      ? props?.theme?.colors?.text3
      : props?.theme?.colors?.textBlack};

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      white-space: nowrap;
      margin-bottom: 5px;
      overflow: hidden;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin-bottom: 5px;
    }
  }
`;

export default CustomFieldLabelStyled;
