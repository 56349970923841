import styled from "styled-components";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { APPOINTMENT_STATUS } from "constants/appointmentConstants";
import { ReactComponent as ThreeDotsIcon } from "../../assets/images/svg/three-dots-icon.svg";
import { ReactComponent as EditPenImg } from "../../assets/images/svg/edit-pen-icon.svg";
import React from "react";

export const StyledTableBody = styled(TableBody)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: ${(props) =>
    props?.$setMinHeight ? (props?.$halfSizeHeight ? "375px" : "750px") : ""};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 8px;
      height: auto;
      align-items: center;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) =>
    props?.isNewNotification
      ? props?.theme?.colors?.notificationColor
      : props?.status === APPOINTMENT_STATUS.DONE
      ? props?.theme?.colors?.disabledRow
      : props?.theme?.colors?.rowBackgroundColor};
  position: relative;

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
      padding: 12px 8px;
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 100%;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  padding-left: 15px;
  flex: ${(props) => (props.tableAlignmentLeft ? "none" : "1")};
  width: ${(props) =>
    props.tableAlignmentLeft
      ? "250px"
      : `calc((100% - ${15 * props.numberOfHeaders + 133}px) / (${
          props.numberOfHeaders - 1
        }))`};
  justify-content: ${(props) => props.alignment || "flex-start"};
  border: none;
  display: flex;
  align-items: center;
  & .MuiTypography-root {
    display: flex;
    flex-direction: row;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: 100%;
      justify-content: flex-start;
      padding: 0px;
      padding-top: 2px;
    }
  }
`;

export const LastCell = styled(StyledTableCell)`
  flex: ${(props) => (props.isSingleColumn ? "1 1 100%" : "0 1 133px")};
  width: ${(props) => (props.isSingleColumn ? "100%" : "133px")};
  justify-content: ${(props) => (props.isSingleColumn ? "left" : "right")};

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex: 0;
      width: auto;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) =>
    props?.status === APPOINTMENT_STATUS.DONE
      ? props?.theme?.colors?.textColor2
      : props?.theme?.colors?.rowTextColor};

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      flex-wrap: ${(props) =>
        props?.isClientInfoTableWorkingHours ? "wrap" : "no-wrap"};
      gap: ${(props) => (props?.isClientInfoTableWorkingHours ? "8px" : "0px")};
    }
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      flex-wrap: ${(props) =>
        props?.isClientInfoTableWorkingHours ? "wrap" : "no-wrap"};
      gap: ${(props) => (props?.isClientInfoTableWorkingHours ? "8px" : "0px")};
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: 16px;
      line-height: 24px;
      color: ${(props) =>
        props?.isSecondCell
          ? props?.theme?.colors?.textColor2
          : props?.theme?.colors?.rowTextColor};
    }
  }
`;

export const StyledTableRowWorkingHours = styled(TableRow)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  border: 1px solid ${(props) => props?.theme?.colors?.rowBorderColor};
  border-radius: 12px;
  background-color: ${(props) => props?.theme?.colors?.rowBackgroundColor};
`;

export const LastCellRowWorkingHours = styled(LastCell)`
  margin-left: auto;
`;

const withRefAndStyled = (IconComponent, displayName) => {
  const IconWithRef = React.forwardRef((props, ref) => {
    return <IconComponent ref={ref} {...props} />;
  });
  IconWithRef.displayName = displayName;

  return styled(IconWithRef)`
    width: 24px;
    height: 24px;
    cursor: pointer;
  `;
};

export const ThreeDotsIconStyled = withRefAndStyled(ThreeDotsIcon, "ThreeDotsIcon");
export const EditPenImgStyled = withRefAndStyled(EditPenImg, "EditPenImg");
