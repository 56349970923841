import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import brushEffectGreen from "../../assets/images/svg/brush-effect-green.svg";
import brushEffectPuple from "../../assets/images/svg/brush-effect-purple.svg";
import { ReactComponent as ChevronImg } from "../../assets/images/svg/chevron-right-solid.svg";
import { hexToRGB } from "util/colorHelper";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as LogoImg } from "../../assets/images/svg/header-logo.svg";

export const StyledSmallCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 480px;
  height: 381px;
  border-radius: 16px;
  background-color: ${({ purpleCard, theme }) =>
    purpleCard
      ? `${theme.colors.purpleBoxBackgournd}`
      : `${theme.colors.greenBoxBackground}`};
  border: 1px solid;
  border-color: ${({ purpleCard, theme }) =>
    purpleCard
      ? `${theme.colors.secondaryColor}`
      : `${theme.colors.primaryColor}`};
  transition: transform 0.3s ease-in-out;
  margin: 10px;

  &:hover {
    transform: translateY(-5px);
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      padding: 16px;
      gap: 20px;
      width: 325px;
      height: fit-content;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 331px;
      width: auto;
      height: 229px;
      gap: 16px;
      justify-content: flex-start; 
      margin: 0px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  gap: 30px;
  padding: 0px 80px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      padding: 0px 30px;
    }
  }
  
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 24px;
      width: 100%;
      height: 100%;
      padding: 0px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: calc(100% - 960px);

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: row;
      width: 90%;
      height: 70%;
      padding: 16px;
      gap: 20px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 16px;
      align-items: center;
      flex-direction: column;
      width: auto;
      height: auto;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 20%;
    }
  }
`;

export const StyledBigCardMiddle = styled(Card)`
  max-height: 759px;
  max-width: 1200px;
  width: 100%;
  //height: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: row;
      width: auto;
      height: auto;
      padding: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      width: 343px;
      height: auto;
      padding: 16px;
    }
  }
`;

export const StyledBigCard = styled(Card)`
  max-height: 759px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding: 0px 5px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 1px 1px 8px
    ${(props) => hexToRGB(props?.theme?.colors?.black, 0.3)};

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      flex-direction: row;
      width: 100%;
      height: auto;
      padding: 0px 16px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      max-width: 343px;
      height: auto;
      max-height: 612px;
      padding: 16px;
      gap: 16px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  text-align: ${({ textStyle }) => (textStyle ? "left" : "center")};
  font-size: ${({ textStyle }) => (textStyle ? "20px" : "32px")};
  font-weight: ${({ textStyle }) => (textStyle ? "400" : "600")};
  font-family: ${({ textStyle }) =>
    textStyle ? "Urbanist !important" : "Nunito !important"};
  margin-left: ${({ textStyle }) => (textStyle ? "30px" : "20px")};
  margin-right: ${({ textStyle }) => (textStyle ? "30px" : "20px")};
  font-weight: ${({ titleStyle }) => (titleStyle ? "bold" : "light")};
  line-height: 38.4px;
  &.MuiTypography-root {
    margin-bottom: 0px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-size: ${({ textStyle }) => (textStyle ? "16px" : "24px")};
      font-weight: ${({ textStyle }) => (textStyle ? "400px" : "600px")};
      line-height: 24px;
      margin-left: ${({ textStyle }) => (textStyle ? "20px" : "15px")};
      margin-right: ${({ textStyle }) => (textStyle ? "20px" : "15px")};
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: ${({ textStyle }) => (textStyle ? "16px" : "20px")};
      font-weight: ${({ textStyle }) => (textStyle ? "400px" : "600px")};
      line-height: 20px;
      margin: 0px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: ${({ textStyle }) => (textStyle ? "16px" : "20px")};
      line-height: 24px;
      margin-left: ${({ textStyle }) => (textStyle ? "14px" : "20px")};
      margin-right: ${({ textStyle }) => (textStyle ? "14px" : "10px")};
      padding-top: ${({ textStyle }) => (textStyle ? "0px" : "10px")};
    }
  }
`;

export const StyledButton = styled(Button)`
  max-width: 416px;
  height: 56px;
  justify-content: center;
  background-color: ${({ purpleButton, theme }) =>
    purpleButton ? theme.colors.secondaryColor : theme.colors.primaryColor};
  color: ${(props) => props?.theme?.colors?.completedRegistration};
  border-radius: 12px;
  text-transform: none;
  font-size: 24px;
  font-weight: 600px;
  line-height: 32.74px;
  transition: background-color 0.3s ease-in-out;
  outline: none;

  &:hover {
    background-color: ${({ purpleButton, theme }) =>
      purpleButton
        ? theme.colors.secondaryColorHover
        : theme.colors.primaryColorHover};
  }
  &:outline {
    outline: none;
  }

  & > svg {
    margin-left: 8px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      width: auto;
      height: 40px;
      font-size: 18px;
      line-height: 20px;
      margin: 0px 10px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 40px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      margin-bottom: 16px;
      height: 100%;
      min-height: 45px;
      font-size: 18px;
      line-height: 24.55px;
    }
  }
`;

export const StyledBrushEffect = styled.span`
  background-image: url(${({ color }) =>
    color ? brushEffectPuple : brushEffectGreen});
  background-image: url(${({ color }) =>
    color ? brushEffectPuple : brushEffectGreen});
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 0;
  padding: 15px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 9px;
    }
  }
`;

export const CheckIcon = styled(ChevronImg)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  width: 20px;
  height: 20px;
  display: inline-block;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Logo = styled(LogoImg)`
  width: 151px;
  height: 40px;
  display: inline-block;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 90px;
      height: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 90px;
      height: 24px;
    }
  }
`;

export const LogoContainer = styled.div`
  margin-top: 40px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin-top: 0px;
    }
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      margin-top: 0px;
    }
  }
`;

export const AlreadyGotAccountContainer = styled.div`

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-top: 0px;
    }
  }
`;

export const AlreadyGotAccount = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Urbanist" !important;
  margin-bottom: 40px;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      margin-bottom: 0px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0px;
    }
  }
`;

export const LogLinkStyled = styled(Link)`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  font-family: "Urbanist" !important;
  color: ${(props) => props.color};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
      font-weight: 400px;
    }
  }
`;
