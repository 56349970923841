import React from "react";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { unsetShownModal } from "features/modal/modalSlice";
import {
  CancelButton,
  LowerContainerWrapper,
  Modal,
  TitleStyled,
  UpperContainerWrapper,
} from "./VerifyPhoneNumberModal.styled";
import CustomTextField from "components/InputFields/CustomFields/CustomTextField";
import { useVerifyPhoneNumberMutation } from "features/user/usersApiSlice";
import { useFormik } from "formik";
import { useSendPhoneVerificationCodeMutation } from "features/user/usersApiSlice";
import { makeToastMessage } from "util/toastMessage";
import toastNotificationType from "constants/toastNotificationType";
import { ActionButton } from "components/SuperAdmin/LicenseAddEditModal/LicenceAddEditModal.styled";
import { VerificationCodeValidation } from "validations/verificationCodeValidation";

export const VerifyPhoneNumberModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verifyPhoneNumberAction] = useVerifyPhoneNumberMutation();
  const [sendCode] = useSendPhoneVerificationCodeMutation();

  const handleSubmit = async () => {
    const response = await verifyPhoneNumberAction({
      userId: props?.user?.id,
      verificationCode: formik?.values?.verificationCode,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("profile.phoneNumberSuccessfullyVerified"),
        toastNotificationType.SUCCESS
      );
    }
    dispatch(unsetShownModal());
  };

  const formik = useFormik({
    validationSchema: VerificationCodeValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: { verificationCode: null },
  });

  const sendVerificationCode = async () => {
    const response = await sendCode({
      userId: props?.user?.id,
    });
    if (response?.error) {
      makeToastMessage(
        response.error.data.errors[0].message,
        toastNotificationType.ERROR
      );
    } else {
      makeToastMessage(
        t("profile.verificationCodeSendSuccessfully"),
        toastNotificationType.SUCCESS
      );
    }
  };

  return (
    <Modal>
      <UpperContainerWrapper>
        <TitleStyled>
          {t("profile.verifyPhoneNumberMessage", {
            phoneNumber: props?.user?.phoneNumber,
          })}
        </TitleStyled>
        <ActionButton value={"Send code"} onClick={sendVerificationCode} />
        <CustomTextField
          centered
          placeholder={"profile.labels.verificationCode"}
          formik={formik}
          name={"verificationCode"}
        />
      </UpperContainerWrapper>
      <LowerContainerWrapper>
        <CancelButton
          value={t("common.quit")}
          onClick={() => dispatch(unsetShownModal())}
        />
        <ActionButton value={t("common.ok")} onClick={formik?.handleSubmit} />
      </LowerContainerWrapper>
    </Modal>
  );
};

VerifyPhoneNumberModal.propTypes = {
  user: PropTypes.any,
};

export default VerifyPhoneNumberModal;
