import { useGetClientGalleryQuery } from "features/clients/clientsApiSlice";
import React, { useRef } from "react";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";
import ImageItem from "./ImageItem";
import {
  ArrowButton,
  GalleryContentContainer,
  GalleryContentMultiCarousel,
  LeftArrowContainer,
  LeftArrowStyled,
  RightArrowContainer,
  RightArrowStyled,
} from "./GalleryContentCarousel.styled";

const GalleryContentCarousel = (props) => {
  const carouselRef = useRef();

  const {
    data: imageIds,
    isLoading: idsLoading,
    error: idsError,
  } = useGetClientGalleryQuery(props?.client?.id);

  if (idsLoading) return;
  if (idsError || !imageIds?.data?.imageResponses?.length) return;
  return (
    <GalleryContentContainer>
      <LeftArrowContainer>
        <ArrowButton>
          <LeftArrowStyled onClick={() => carouselRef.current?.previous()} />
        </ArrowButton>
      </LeftArrowContainer>
      <GalleryContentMultiCarousel
        ref={carouselRef}
        autoPlay={true}
        autoPlaySpeed={4000}
        arrows={false}
        infinite
        responsive={{
          desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
          tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
          mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
        }}
      >
        {imageIds?.data?.imageResponses?.map((image) => (
          <ImageItem key={image?.id} id={image?.id} />
        ))}
      </GalleryContentMultiCarousel>
      <RightArrowContainer>
        <ArrowButton>
          <RightArrowStyled onClick={() => carouselRef.current?.next()} />
        </ArrowButton>
      </RightArrowContainer>
    </GalleryContentContainer>
  );
};

GalleryContentCarousel.propTypes = {
  client: PropTypes.any,
};

export default GalleryContentCarousel;
