import styled, { css } from "styled-components";
import Button from "components/Button/Button";
import { Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";
import { ReactComponent as xIcon } from "assets/images/svg/x-icon.svg";

export const InputFieldContainer = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props?.twoInpts ? "wrap" : "no-wrap")};
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  gap: ${(props) => (props?.gap ? "3px" : "16px")};
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 275px;
          }
        `
      : css`
          & > div {
            width: 566px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: column;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 100%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      > div {
        width: 100%;
        gap: 0px;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      > div {
        width: 100%;
      }
      flex-flow: nowrap;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                width: 50%;
              }
            `
          : css`
              & > div {
                width: 100%;
              }
            `}
    }
  }
`;

export const Modal = styled.div`
  width: 460px;
  max-height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CloseIcon = styled(xIcon)`
  & path {
    stroke: ${(props) => props?.theme?.colors?.black};
    fill: ${(props) => props?.theme?.colors?.black};
  }
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-bottom: none;
  position: relative;
  background-color: white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 20px;
  width: 100%;
  height: fit-content;
  overflow: auto;
`;

export const Title = styled(Typography)`
  color: ${(props) => props.theme?.colors?.black};
  font-size: 24px;
  font-weight: 700;
`;

export const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  border-top: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  position: relative;
  background-color: ${(props) => props.theme?.colors?.white};
  justify-content: center;
  align-items: flex-end;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px;
  width: 100%;
  height: 82px;
  padding: 25px 30px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const ActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.darkBlue};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.hoverDarkBlue};
  }
  color: ${(props) => props.theme?.colors?.white};
`;

export const CancelActionButton = styled(Button)`
  height: 33px;
  width: 112px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.theme?.colors?.white};
  border: 1px solid ${(props) => hexToRGB(props?.theme?.colors?.black, 0.2)};
  &:hover {
    background-color: ${(props) => props.theme?.colors?.fieldBorderColor};
    border-color: ${(props) => props.theme?.colors?.black};
  }
  color: ${(props) => props.theme?.colors?.black};
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props?.gap ? "0px" : "20px")};
`;

export const LabelContainer = styled.div`
  display: flex;
  padding-bottom: 0px;
  justify-content: left;
  align-items: left;
`;
