import React from "react";
import PropTypes from "prop-types";
import {
  TimeContainer,
  GridContainer,
  ItemContainer,
  RowContainer,
  DayLabelContainer,
  LabelNonWorkingDay,
} from "./ListingWorkingHourComponent.styled";
import { daysOfWeek, workingDays } from "constants/daysOfWeek";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "util/helpers/dateHelpers";

const ListingWorkingHourComponent = (props) => {
  const { t } = useTranslation();
  return (
    <GridContainer>
      {Object.values(daysOfWeek).map((dayOfWeek) => {
        const dayWorkingHours = props?.items
          ?.filter((item) => item.dayOfWeek == (dayOfWeek + 1) % 7)
          .sort(
            (item1, item2) =>
              new Date(item1.fromTimeUtc) - new Date(item2.fromTimeUtc)
          );

        return (
          <RowContainer key={dayOfWeek}>
            <ItemContainer>
              <DayLabelContainer>{t(workingDays[dayOfWeek])}</DayLabelContainer>
            </ItemContainer>
            {dayWorkingHours?.length !== 0 ? (
              dayWorkingHours?.map((item, itemIndex) => {
                const fromTime = convertUTCToLocalTime(item.fromTimeUtc);
                const toTime = convertUTCToLocalTime(item.toTimeUtc);
                return (
                  <ItemContainer key={itemIndex}>
                    <TimeContainer>{fromTime + "-" + toTime}</TimeContainer>
                  </ItemContainer>
                );
              })
            ) : (
              <ItemContainer>
                <LabelNonWorkingDay>
                  {t("days.nonWorkingDay")}
                </LabelNonWorkingDay>
              </ItemContainer>
            )}
          </RowContainer>
        );
      })}
    </GridContainer>
  );
};

ListingWorkingHourComponent.propTypes = {
  items: PropTypes.array,
};

export default ListingWorkingHourComponent;
