import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, MenuItem } from "@mui/material";
import CustomFieldLabel from "components/InputFields/CustomFields/CustomFieldLabel";
import {
  BelowFieldLabel,
  CustomSelectFieldStyled,
} from "./RecurringField.styled";
import { Recurring, recurringTypes } from "constants/recurringConstants";

const RecurringField = (props) => {
  const [value, setValue] = useState({ id: 0, name: "" });
  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = event.target.value;
    setValue({
      id: value,
      name: t(recurringTypes.find((type) => type.id === value)?.name),
    });
    props?.onChange(value);
  };

  return (
    <Box>
      <CustomFieldLabel label={t(props?.label)} />
      <CustomSelectFieldStyled
        name={props?.name}
        value={props?.recurring || value?.id}
        onChange={handleChange}
      >
        {recurringTypes?.map((type) => (
          <MenuItem key={type?.id} value={type?.id}>
            {t(type.name)}
          </MenuItem>
        ))}
      </CustomSelectFieldStyled>
      <BelowFieldLabel>
        {t(
          value?.id == Recurring.NOREPEAT
            ? "scheduleAppointment.belowRecurringFieldLabelNoRepeat"
            : ""
        )}
      </BelowFieldLabel>
    </Box>
  );
};

RecurringField.propTypes = {
  recurring: PropTypes.number,
  onChange: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default RecurringField;
