import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import fallbackIcon from "assets/images/svg/default-service-image.svg";
import { useGetImageQuery } from "features/clients/clientsApiSlice";
import {
  AdminMark,
  CardImage,
  ClientArrowStyled,
  ClientCardContainer,
  ClientCardWrapper,
  ClientName,
  ClientServiceCategoryName,
  ImageContainer,
  TextContainer,
} from "./ClientCard.styled";
import SubscribeButton from "../Button/SubscribeButton/SubscribeButton";
import WithLoadingSpinner from "components/WithLoadingSpinner/WithLoadingSpinner";

const ClientCard = (props) => {
  const { t } = useTranslation();
  const { data: fetchedImage, isLoading } = useGetImageQuery(
    props?.client?.logoId
  );

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    let objectUrl = null;
    if (!isLoading) {
      if (fetchedImage instanceof Blob || fetchedImage instanceof File) {
        objectUrl = URL.createObjectURL(fetchedImage);
        setImageUrl(objectUrl);
      } else if (typeof fetchedImage === "string") {
        setImageUrl(fetchedImage);
      } else {
        setImageUrl(fallbackIcon);
      }
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [fetchedImage, isLoading]);

  const isSubscribed = props?.client?.subscribedUsers?.some(
    (subscription) =>
      subscription?.user?.id === props?.userId &&
      subscription?.endDateUtc === null
  );

  return (
    <ClientCardWrapper>
      <ClientCardContainer to={props?.goTo}>
        <ImageContainer>
          <WithLoadingSpinner isLoading={imageUrl == null}>
            <CardImage src={imageUrl} alt={props?.client?.fullName}></CardImage>
          </WithLoadingSpinner>
        </ImageContainer>
        <TextContainer>
          <ClientName>{props?.client?.fullName}</ClientName>
          <ClientServiceCategoryName>
            {props?.client?.primaryServiceCategory?.name}
          </ClientServiceCategoryName>
          <ClientArrowStyled />
        </TextContainer>
        {props?.isClientAdmin && <AdminMark>{t("common.admin")}</AdminMark>}
      </ClientCardContainer>
      {props?.isFavourite && (
        <SubscribeButton
          isSubscribed={isSubscribed}
          userId={props?.userId}
          clientId={props?.client?.id}
          onClick={(event) => event.stopPropagation()}
        />
      )}
    </ClientCardWrapper>
  );
};

ClientCard.propTypes = {
  goTo: PropTypes.any,
  index: PropTypes.number,
  onClick: PropTypes.func,
  isFavourite: PropTypes.bool,
  isClientAdmin: PropTypes.bool,
  userId: PropTypes.string,
  client: PropTypes.object,
};

export default ClientCard;
