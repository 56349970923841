import { useEffect, useRef } from "react";
import { useHubConnection } from "hooks/useHubConnection";
import { useDispatch } from "react-redux";
import { addNotification } from "features/notificationsSlice/notificationsSlice";
import { makeToastMessage } from "util/toastMessage";
import { useTranslation } from "react-i18next";
import { usersApiSlice } from "features/user/usersApiSlice";

export const useNotifications = () => {
  const { t } = useTranslation();
  const { connection } = useHubConnection();
  const dispatch = useDispatch();
  const notificationsBuffer = useRef([]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!connection) return;

    const handleNotification = (message) => {
      dispatch(usersApiSlice.util.invalidateTags(["notifications"]));

      notificationsBuffer.current.push(message);
      dispatch(addNotification(message));

      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (notificationsBuffer.current.length > 0) {
          makeToastMessage(
            t("notification.newNotifications", {
              count: notificationsBuffer.current.length,
            }),
            "notification"
          );
          notificationsBuffer.current = [];
        }
      }, 1000);
    };

    connection.on("ReceiveNotification", handleNotification);

    return () => {
      connection.off("ReceiveNotification", handleNotification);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [connection, dispatch, t]);
};
