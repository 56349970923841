import { Typography } from "@mui/material";
import styled, { css } from "styled-components";
import Button from "components/Button/Button";
import { ReactComponent as X_Icon } from "assets/images/svg/x-icon.svg";
import { hexToRGB } from "util/colorHelper";

const fieldBorderColor = (props) => props.theme?.colors?.fieldBorderColor;
const blackColor = (props) => props.theme?.colors?.black;
const whiteColor = (props) => props.theme?.colors?.white;
const darkBlueColor = (props) => props.theme?.colors?.darkBlue;

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  z-index: 999;
`;

export const FilterContainerStyled = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  position: relative;
  gap: 10px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 0px;
    }
  }
`;

export const FilterPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${whiteColor};
  width: fit-content;
  height: fit-content;
  position: absolute;
  padding: 32px 24px;
  border-radius: 8px;
  border: 1px solid ${(props) => hexToRGB(blackColor(props), 0.2)};
  top: ${(props) => props?.top};
  left: ${(props) => props?.left};
  z-index: 1002;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      gap: 16px;
      padding: 32px 24px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                max-width: 190px;
                min-width: 70px;
              }
            `
          : css`
              & > div {
                max-width: 310px;
                min-width: 140px;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 16px;
      padding: 32px 24px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                max-width: 190px;
                min-width: 70px;
              }
            `
          : css`
              & > div {
                max-width: 310px;
                min-width: 140px;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding: 32px 24px;
      ${(props) =>
        props?.twoInputs
          ? css`
              & > div {
                max-width: 140px;
                min-width: 70px;
              }
            `
          : css`
              & > div {
                max-width: 296px;
                min-width: 156px;
              }
            `}
    }
  }

  ${(props) => props.theme.breakpoints.down("xs")} {
    && {
      padding: 24px 18px;
      gap: 8px;
      ${(props) =>
        props?.twoInputs
        ? css`
              & > div {
                max-width: 130px;
                min-width: 65px;
              }
            `
          : css`
              & > div {
                max-width: 268px;
                min-width: 134px;
              }
            `}
    }
  }
`;

export const UpperFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  ${(props) =>
    props?.twoInputs
      ? css`
          & > div {
            width: 275px;
          }
        `
      : css`
          & > div {
            width: 366px;
          }
        `}
  > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
`;

export const LowerFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const FilterTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${darkBlueColor};
`;

export const X_IconStyled = styled(X_Icon)`
  cursor: pointer;
  & path {
    stroke: ${(props) => props?.theme?.colors?.filterXcolor};
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      height: 20px;
      width: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      height: 20px;
      width: 20px;
    }
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  max-height: 33px;
  max-width: 109px;
  background-color: ${fieldBorderColor};
  border-color: ${blackColor};
  &:hover {
    background-color: ${fieldBorderColor};
    border-color: ${blackColor};
  }
  color: ${blackColor};
  font-size: 14px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      max-height: 33px;
      max-width: 90px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      max-height: 33px;
      max-width: 90px;
    }
  }
`;

export const SaveFiltersButton = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  max-height: 33px;
  max-width: 109px;
  background-color: ${darkBlueColor};
  border-color: ${blackColor};
  &:hover {
    background-color: ${darkBlueColor};
    border-color: ${blackColor};
  }
  color: ${whiteColor};
  font-size: 14px;
  font-weight: 600;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      max-height: 33px;
      max-width: 90px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      max-height: 33px;
      max-width: 90px;
    }
  }
`;
