import styled from "styled-components";
import { Accordion, Button, Typography } from "@mui/material";
import { hexToRGB } from "util/colorHelper";

export const AccordionStyled = styled(Accordion)`
  border-radius: 24px;
  padding-left: 16px;
  background-color: ${(props) =>
    props.expanded
      ? props?.theme?.colors?.white
      : props?.theme?.colors?.accordionClosed};

  padding-left: 16px;
  opacity: ${(props) => (props.expanded ? 1 : 0.7)};
  transition: max-height 0.3s ease, opacity 0.3s ease;

  transition: max-height 3s ease-in-out;

  &:last-of-type {
    position: static;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  &:first-of-type {
    position: static;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &::before {
    position: static;
  }
`;

export const TypographyStyled = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colors?.black};
`;

export const UpperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      flex-direction: column;
      align-items: normal;
      padding-bottom: 16px;
      gap: 16px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: row;
      align-items: center;
      padding-bottom: 12px;
      gap: 12px;
    }
  }
  ${(props) => props.theme.breakpoints.down("ms")} {
    && {
      flex-direction: column;
      align-items: normal;
      padding-bottom: 8px;
      gap: 8px;
    }
  }
`;

export const AllowBookingWrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      flex-direction: column;
      align-items: normal;
      padding-bottom: 16px;
      gap: 16px;
    }
  }
  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      flex-direction: row;
      align-items: center;
      padding-bottom: 12px;
      gap: 12px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      flex-direction: column;
      align-items: normal;
      padding-bottom: 8px;
      gap: 8px;
    }
  }
`;

export const AllowBookingControls = styled.div`
  display: flex;
  align-items: center;
`;

export const AllowBookingButton = styled.button`
  background-color: ${(props) =>
    props.allowed
      ? props?.theme?.colors?.notificationGreen
      : props?.theme?.colors?.notificationRed};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
`;

export const TimeSelect = styled.select`
  width: 100px;
  height: 30px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => hexToRGB(theme?.colors?.black, 0.3)};
  padding: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) =>
    props.allowed
      ? props?.isAllowed
        ? props?.theme?.colors?.notificationGreen
        : props?.theme?.colors?.notificationRed
      : props?.theme?.colors?.black};
`;

export const AuthorizedUsersButton = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  transition: background-color 0.3s ease-in-out;
  border-radius: 12px;
  padding: 10px 15px;

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.darkBlue};
    color: ${(props) => props?.theme?.colors?.white};
  }

  height: 36px;
  width: fit-content;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${(props) => props?.theme?.colors?.timeSlotText};
  text-transform: none;
`;
