import Button from "components/Button/Button";
import styled from "styled-components";

export const AddNewItemContainer = styled(Button)`
  border-radius: 12px;
  padding: 6px 32px 6px 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  min-height: 33px;
  color: white;
  background-color: ${(props) =>
    props?.theme?.colors?.leftAuthContentBackground};
  &:hover {
    background-color: ${(props) =>
      props?.theme?.colors?.leftAuthContentBackground};
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      font-weight: 600;
      width: 118px;
      height: 33px;
    }
  }
`;
