import styled from "styled-components";
import { MenuItem, Select } from "@mui/material";

export const CustomCalendarViewSelectStyled = styled(Select)`
  border-radius: 8px;
  background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }

  & .MuiSelect-icon {
    width: 16px;
    height: 16px;
    top: auto;
  }

  &.MuiOutlinedInput-root {
    height: 33px;
    width: 80px;
  }

  & .MuiInputBase-input {
    padding: 8.5px 32px 5px 14px;
  }

  &.MuiButtonBase-root {
    background-color: red;
  }

  & .MuiMenuItem-root {
    &.Mui-selected {
      background-color: red !important;
    }
  }

  & .MuiPaper-root {
    border-radius: 12px;
    max-width: 70px;
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    width: 100%;
    font-size: 12px;
    & .MuiInputBase-input {
      padding: 9px;
      white-space: nowrap;
      overflow: visible;
      text-overflow: unset;
    }
    &.MuiOutlinedInput-root {
      height: 24px;
    }
    & .MuiSelect-icon {
      width: 20px;
      height: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    font-size: 12px;
    & .MuiInputBase-input {
      padding: 9px;
      white-space: nowrap;
      overflow: visible;
      text-overflow: unset;
    }
    &.MuiOutlinedInput-root {
      height: 33px;
    }
    & .MuiSelect-icon {
      width: 20px;
      height: 20px;
    }
  }
`;

export const menuProps = {
  PaperProps: {
    style: {
      width: "70px",
      maxWidth: "70px",
      minWidth: "70px",
      borderRadius: "12px",
    },
  },
};

export const MenuItemStyled = styled(MenuItem)`
  margin: 8px 3px;
  border: 2px solid ${(props) => props?.theme?.colors?.timeSlotBg};
  border-radius: 8px;
  padding: 0px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 24px;

  &.Mui-selected {
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  }
`;

export default CustomCalendarViewSelectStyled;
