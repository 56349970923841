import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/SuperAdmin/Buttons/ActionButton/ActionButton";

const RemoveAuthorizedUserRender = (props) => {
  return (
    <ActionButton
      disabled={props?.disabled}
      isCancel={true}
      value={"common.delete"}
      onClick={props?.onClickEdit}
    />
  );
};
RemoveAuthorizedUserRender.propTypes = {
  onClickEdit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RemoveAuthorizedUserRender;
