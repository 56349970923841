import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as LeftArrow } from "assets/images/svg/left-arrow-icon.svg";
import { ReactComponent as RightArrow } from "assets/images/svg/right-arrow-icon.svg";

export const LeftArrowStyled = styled(LeftArrow)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 16px;
      height: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 16px;
      height: 16px;
    }
  }
`;

export const RightArrowStyled = styled(RightArrow)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down("ms")} {
    && {
      width: 20px;
      height: 20px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      width: 16px;
      height: 16px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      width: 16px;
      height: 16px;
    }
  }
`;

export const ToolbarContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;

export const NavButton = styled(Button)`
  background: transparent;
  border: none;
  min-width: auto;
  padding: 0 8px;
  color: ${(props) => props?.theme?.colors?.black};

  &:hover {
    color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
    background: none;
  }

  ${(props) => props.theme.breakpoints.down("lg")} {
    && {
      padding: 0px 4px;
    }
  }
`;

export const DateLabel = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  font-weight: 400px;
  line-height: 30px;
  color: ${(props) => props?.theme?.colors?.black};

  ${(props) => props.theme.breakpoints.down("ms")} {
    && {
      font-size: 16px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.rotate()} {
    && {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      font-size: 14px;
    }
  }
`;

export const ViewButton = styled(Button)`
  background-color: ${(props) => props?.theme?.colors?.white};
  border: 1px solid ${(props) => props?.theme?.colors?.timeSlotBg};
  border-radius: 8px;
  height: 24px;
  min-width: 70px;
  font-size: 0.9rem;
  text-transform: none;
  color: ${(props) => props?.theme?.colors?.black};

  &.active {
    background-color: ${(props) => props?.theme?.colors?.timeSlotBg};
  }

  &:hover {
    background-color: ${(props) => props?.theme?.colors?.hoverDarkBlue};
    color: ${(props) => props?.theme?.colors?.white};
  }
`;

export const ViewButtonGroup = styled(Box)`
  display: flex;
  gap: 8px;
`;
