export default {
  serviceCategories: {
    getAll: "service-categories",
    getPaginated:
      "/service-categories?pageNumber={pageNumber}&pageSize={pageSize}",
    create: "service-categories",
    update: "service-categories/{id}",
    getClientsForServiceCategory: (pageNumber, pageSize, filters = {}) => {
      const { name, city, primaryService } = filters;

      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(name?.id && { "clientFilters.name": name?.id }),
        ...(city?.id && { "clientFilters.location": city?.id }),
        ...(primaryService?.id && {
          "clientFilters.serviceCategory": primaryService?.id,
        }),
      });

      const url = `service-categories/clients?${params.toString()}`;
      return url;
    },
    getImage: "/images/{id}",
  },
  clientLicenses: {
    getAll: "client-licenses",
    getPaginated:
      "client-licenses/{id}?pageNumber={pageNumber}&pageSize={pageSize}",
    updateLicence: "client-licenses/{id}",
    deleteLicense: "client-licenses/{id}",
    addLicence: "client-licenses",
  },
  clientResources: {
    getAll: (clientId) => `clients/${clientId}/resources`,
    getPaginated: (clientId, pageNumber, pageSize) => {
      const params = new URLSearchParams({
        pageNumber,
        pageSize,
      });
      const url = `clients/{clientId}/resources?${params.toString()}`.replace(
        "{clientId}",
        clientId
      );
      return url;
    },
    addResource: (clientId) => `clients/${clientId}/resources`,
    updateResource: (clientId, resourceId) =>
      `clients/${clientId}/resources/${resourceId}`,
    deleteResource: (clientId, resourceId) =>
      `clients/${clientId}/resources/${resourceId}`,
  },
  appointments: {
    getAppointmentById: (appointmentId) => `appointments/${appointmentId}`,
    declineAppointmentRequest: (appointmentId) =>
      `appointments/${appointmentId}/declineAppointmentRequest`,

    acceptAppointmentRequest: (appointmentId, authorizeUser) => {
      const params = new URLSearchParams({
        authorizeUser: authorizeUser,
      });

      const url = `appointments/${appointmentId}/acceptAppointmentRequest?${params.toString()}`;

      return url;
    },

    clientServices: {
      getPaginatedForClient: (clientId, pageNumber, pageSize) => {
        const params = new URLSearchParams({
          pageNumber,
          pageSize,
        });

        const url = `client-services/client/{id}?${params.toString()}`.replace(
          "{id}",
          clientId
        );
        return url;
      },
      getService: "/client-services/{id}",
    },
    getClientEmployeeAppointments: (userid, pageNumber, pageSize, filters) => {
      const {
        serviceName,
        userFirstName,
        userLastName,
        fromDate,
        toDate,
        statuses,
        types,
      } = filters;

      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(serviceName?.id && {
          "AppointmentFilters.ClientServiceName": serviceName?.id,
        }),
        ...(userFirstName?.id && {
          "AppointmentFilters.UserFirstName": userFirstName?.id,
        }),
        ...(userLastName?.id && {
          "AppointmentFilters.UserLastName": userLastName?.id,
        }),
        ...(fromDate?.id && { "AppointmentFilters.StartDate": fromDate?.id }),
        ...(toDate?.id && { "AppointmentFilters.EndDate": toDate?.id }),
      });

      statuses?.forEach((status) => {
        params.append("AppointmentFilters.Statuses", status);
      });

      types?.forEach((types) => {
        params.append("AppointmentFilters.Types", types);
      });

      const url =
        `/appointments/clientEmployee/{userid}?${params.toString()}`.replace(
          "{userid}",
          userid
        );

      return url;
    },
    cancelAppointment: (appointmentId, userId) =>
      `appointments/${appointmentId}/user/${userId}`,
    cancelAppointmentByClient: (appointmentId, clientId) =>
      `appointments/${appointmentId}/client/${clientId}`,
    cancelRecurringAppointments: (recurringAppointmentId, userId) =>
      `appointments/user/${userId}/recurring/${recurringAppointmentId}/cancel`,
    getAvailableTimeSlots: (data) => {
      const {
        startDate,
        endDate,
        clientId,
        clientServiceId,
        clientServiceAttributesIds,
        userSelectedResources,
        userId,
      } = data;

      const params = new URLSearchParams();
      clientServiceAttributesIds.forEach((id) => {
        params.append("clientServiceAttributesIds", id);
      });

      if (userId !== undefined && userId !== null) {
        params.append("userId", userId);
      }

      Object.entries(userSelectedResources).forEach(([key, value]) => {
        params.append(key, value);
      });

      const url =
        `/appointments/{startDate}/{endDate}/{clientId}/{clientServiceId}?${params.toString()}`
          .replace("{startDate}", startDate)
          .replace("{endDate}", endDate)
          .replace("{clientId}", clientId)
          .replace("{clientServiceId}", clientServiceId);

      return url;
    },
    makeWishForAppointment: (userId) => `/users/${userId}/appointment-wishlist`,
    addAppointment: (userId) => `/appointments/user/${userId}`,
    makeRecurringAppointment: (userId) =>
      `/appointments/user/${userId}/recurring`,
    addAppointmentAsClientAdmin: (clientId) =>
      `/appointments/client/${clientId}`,
    getPaginatedForClient: (clientId, pageNumber, pageSize, filters) => {
      const {
        statuses,
        serviceName,
        userFirstName,
        userLastName,
        fromDate,
        toDate,
        types,
      } = filters;

      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(serviceName?.id && {
          "AppointmentFilters.ClientServiceName": serviceName?.id,
        }),
        ...(userFirstName?.id && {
          "AppointmentFilters.UserFirstName": userFirstName?.id,
        }),
        ...(userLastName?.id && {
          "AppointmentFilters.UserLastName": userLastName?.id,
        }),
        ...(fromDate?.id && { "AppointmentFilters.StartDate": fromDate?.id }),
        ...(toDate?.id && { "AppointmentFilters.EndDate": toDate?.id }),
      });

      statuses?.forEach((status) => {
        params.append("AppointmentFilters.Statuses", status);
      });

      types?.forEach((types) => {
        params.append("AppointmentFilters.Types", types);
      });

      const url =
        `/appointments/client/{clientId}?${params.toString()}`.replace(
          "{clientId}",
          clientId
        );

      return url;
    },
    getPaginatedForUser: (userId, pageNumber, pageSize, filters) => {
      const { serviceName, clientName, fromDate, toDate, statuses, types } =
        filters;

      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(serviceName?.id && {
          "AppointmentFilters.ClientServiceName": serviceName?.id,
        }),
        ...(clientName?.id && {
          "AppointmentFilters.ClientName": clientName?.id,
        }),
        ...(fromDate && { "AppointmentFilters.StartDate": fromDate }),
        ...(toDate && { "AppointmentFilters.EndDate": toDate }),
      });

      statuses?.forEach((status) => {
        params.append("AppointmentFilters.Statuses", status);
      });

      types?.forEach((types) => {
        params.append("AppointmentFilters.Types", types);
      });

      const url = `/appointments/user/{userId}?${params.toString()}`.replace(
        "{userId}",
        userId
      );

      return url;
    },
    getPaginatedAppointmentRequestsForUser: (
      userId,
      pageNumber,
      pageSize,
      filters
    ) => {
      const { serviceName, clientName, fromDate, toDate, statuses, types } =
        filters;

      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(serviceName?.id && {
          "AppointmentFilters.ClientServiceName": serviceName?.id,
        }),
        ...(clientName?.id && {
          "AppointmentFilters.ClientName": clientName?.id,
        }),
        ...(fromDate && { "AppointmentFilters.StartDate": fromDate }),
        ...(toDate && { "AppointmentFilters.EndDate": toDate }),
      });

      statuses?.forEach((status) => {
        params.append("AppointmentFilters.Statuses", status);
      });

      types?.forEach((types) => {
        params.append("AppointmentFilters.Types", types);
      });

      const url =
        `/appointments/user/{userId}/requests?${params.toString()}`.replace(
          "{userId}",
          userId
        );

      return url;
    },
  },

  images: {
    getImage: (id) => `/images/${id}`,
    getClientLogo: (id) => `/images/${id}`,
  },
  clientServices: {
    getPaginatedForClient: (clientId, pageNumber, pageSize) => {
      const params = new URLSearchParams({
        pageNumber,
        pageSize,
      });

      const url = `client-services/client/{id}?${params.toString()}`.replace(
        "{id}",
        clientId
      );
      return url;
    },
    getService: "/client-services/{id}",
  },
  clients: {
    getPaginated: (pageNumber, pageSize, filters = {}) => {
      const { name, status, city, primaryService } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(name?.id && { "clientFilters.name": name?.id }),
        ...(status?.id && { "clientFilters.status": status?.id }),
        ...(city?.id && { "clientFilters.location": city?.id }),
        ...(primaryService?.id && {
          "clientFilters.serviceCategory": primaryService?.id,
        }),
      });

      const url = `/clients?${params.toString()}`;
      return url;
    },
    getById: (id) => `/clients/${id}`,
    getClientLogo: (id) => `/images/${id}`,
    addClientLogo: "/clients/add-image-client",
    updateClient: (id) => `/clients/${id}`,
    deleteClientImage: (id) => `/images/${id}`,
    getClientImage: "/images/{id}",
    getClientGallery: "/clients/{id}/gallery",
    addClientImage: "/clients/add-image-client",
    activateClient: "clients/{id}/activate",
    deactivateClient: "clients/{id}/deactivate",
    getAuthorizedUsers: (clientId, pageNumber, pageSize, filters) => {
      const { firstName, lastName } = filters;
      const params = new URLSearchParams({
        pageNumber,
        pageSize,
        ...(firstName?.id && { "Filters.firstName": firstName?.id }),
        ...(lastName?.id && { "Filters.LastName": lastName?.id }),
      });
      const url = `/clients/${clientId}/authorizedUsers?${params.toString()}`;
      return url;
    },
    deauthorizeUser: (clientId, userId) => {
      const params = new URLSearchParams({
        userId,
      });
      const url = `clients/${clientId}/deauthorizeUser?${params.toString()}`;
      return url;
    },
  },
  clientService: {
    clientServices: (clientId, pageNumber, pageSize, filters) => {
      const {
        name,
        durationFrom,
        durationTo,
        priceFrom,
        priceTo,
        primaryService,
      } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(name?.id && { Name: name?.id }),
        ...(durationFrom?.id && { DurationFrom: durationFrom?.id }),
        ...(durationTo?.id && { DurationTo: durationTo?.id }),
        ...(priceFrom?.id && { PriceFrom: priceFrom?.id }),
        ...(priceTo?.id && { PriceTo: priceTo?.id }),
        ...(primaryService?.id && { ServiceCategoryId: primaryService?.id }),
      });

      const url = `/clients/{clientId}/services?${params.toString()}`.replace(
        "{clientId}",
        clientId
      );
      return url;
    },
    activeClientServices: (clientId, pageNumber, pageSize, filters) => {
      const {
        name,
        durationFrom,
        durationTo,
        priceFrom,
        priceTo,
        primaryService,
      } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(name?.id && { Name: name?.id }),
        ...(durationFrom?.id && { DurationFrom: durationFrom?.id }),
        ...(durationTo?.id && { DurationTo: durationTo?.id }),
        ...(priceFrom?.id && { PriceFrom: priceFrom?.id }),
        ...(priceTo?.id && { PriceTo: priceTo?.id }),
        ...(primaryService?.id && { ServiceCategoryId: primaryService?.id }),
      });

      const url =
        `/clients/{clientId}/activeServices?${params.toString()}`.replace(
          "{clientId}",
          clientId
        );
      return url;
    },
    clientService: "/clients/{clientId}/services/{clientServiceId}",
    addClientService: "/clients/{clientId}/services",
    updateClientService: "/clients/{clientId}/services/{clientServiceId}",
    deleteClientService: "/clients/{clientId}/services/{clientServiceId}",
  },
  forgotPassword: {
    forgotPassword: "login/forgot-password",
    resetPasswrod: "login/reset-password?id={id}&token={token}",
  },
  authentication: {
    login: "login",
    refreshToken: "login/refresh",
    logout: "logout",
  },
  notifications: {
    getNotifications: "/notifications",
  },
  users: {
    sendPhoneVerificationCode: (userId) =>
      `users/${userId}/send-phone-number-verification-code`,
    verifyPhoneNumber: (userId, verificationCode) => {
      const params = new URLSearchParams({
        verificationCode,
      });
      const url = `users/${userId}/verify-phone-number?${params.toString()}`;
      return url;
    },
    getUserNotifications: (recipientId) => `users/${recipientId}/notifications`,
    readNotification: ({ userId, notificationId }) => {
      const params = new URLSearchParams({
        notificationId,
      });

      const url = `users/${userId}/notifications?${params.toString()}`;
      return url;
    },

    getUserWishList: (userId) => `users/${userId}/appointment-wishlist`,
    getUser: "users/{id}",
    getAll: "users",
    getPaginated: (pageNumber, pageSize, filters = {}) => {
      const { firstName, lastName, email, city, status } = filters;

      const params = new URLSearchParams({
        pageNumber: pageNumber,
        pageSize: pageSize,
        ...(firstName && { "userFilters.firstName": firstName?.name }),
        ...(lastName && { "userFilters.lastName": lastName?.name }),
        ...(email?.id && { "userFilters.email": email?.id }),
        ...(city && {
          "userFilters.city": city?.id,
        }),
        ...((status?.id === true || status?.id === false) && {
          "userFilters.status": status?.id,
        }),
      });

      const url = `/users?${params.toString()}`;
      return url;
    },
    changeStatus: "users/change-status/{id}",
    subscribeToClient: (userId, clientId) => {
      const params = new URLSearchParams({
        clientId,
      });

      const url =
        `users/{userId}/subscribe-to-client?${params.toString()}`.replace(
          "{userId}",
          userId
        );
      return url;
    },
    unsubscribeToClient: (userId, clientId) => {
      const params = new URLSearchParams({
        clientId,
      });

      const url =
        `users/{userId}/unsubscribe-from-client?${params.toString()}`.replace(
          "{userId}",
          userId
        );
      return url;
    },
    getFavouriteClients: "users/{userId}/favourite-clients",
    update: (id) => `/users/${id}`,
    changeLanguage: (id) => `/users/change-language/${id}`,
  },
  registration: {
    checkEmail: "/users/check-email/{email}",
    addPendingApplicationUser: "/users/add-pending-application-user",
    requestEmailVerificationCode: "/users/request-email-verification-code",
    verifyEmail: "/users",
    checkRegistrationName: "clients/check-registration-name/{regName}",
    register: "clients",
  },
};
