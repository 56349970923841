import { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "features/auth/authSlice";
import { useAuth } from "./useAuth";
import apiEndpoints from "features/apiEndpoints";

export const useHubConnection = () => {
  const [connection, setConnection] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const token = useSelector(selectCurrentToken);
  const { isLoggedIn } = useAuth();
  const baseURL = process.env.REACT_APP_BASE_API_URL.replace('/api', '');
  const url = baseURL + apiEndpoints.notifications.getNotifications;

  useEffect(() => {
    if (isLoggedIn && token) {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token.accessToken,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect()
        .build();

      newConnection.onclose(() => {
        setIsConnected(false);
      });

      newConnection.onreconnecting(() => {
        setIsConnected(false);
      });

      newConnection.onreconnected(() => {
        setIsConnected(true);
      });

      newConnection
        .start()
        .then(() => {
          setConnection(newConnection);
          setIsConnected(true);
        })
        .catch((err) => {
          console.error("SignalR connection error:", err);
        });

      return () => {    
        if (newConnection?.state === signalR.HubConnectionState.Connected) {
          newConnection.stop();
        }
      };
    }
  }, [isLoggedIn, token]);

  return { connection, isConnected };
};
