import UpdateRenderer from "components/Table/UpdateRenderer";
import React from "react";

export default [
  {
    columnId: 0,
    headerName: "clientService.table.attributes.name",
    field: "name",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 1,
    headerName: "clientService.table.attributes.description",
    field: "description",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 2,
    headerName: "clientService.table.attributes.additionalTime",
    field: "durationInMinutes",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 3,
    headerName: "clientService.table.attributes.additionalPrice",
    field: "price",
    enabledSort: false,
    enabledFilter: false,
  },
  {
    columnId: 4,
    headerName: "",
    field: "edit",
    enabledSort: false,
    enabledFilter: false,
    cellRenderer: (params) => (
      <UpdateRenderer
        noDeleteModal={true}
        modal={params?.modal}
        params={params}
        onClick={params.onClickEdit}
        onClickDelete={params.onClickDelete}
        value={"superAdmin.table.editButton"}
      />
    ),
  },
];
