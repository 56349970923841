import { Box } from "@mui/material";
import styled from "styled-components";

export const TabContainer = styled(Box)`
  display: flex;
  gap: 10px;
  border-bottom: 2px solid ${(props) => props?.theme?.colors?.updateTableButton};
  overflow-x: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    && {
      gap: 6px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      gap: 4px;
    }
  }
`;

export const Controls = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 16px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    && {
      padding-right: 0px;
    }
  }
`;
